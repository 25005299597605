import { getPayList } from '@/api/pay';
import { memorize } from '@jz/utils';
import { warningMessage } from '@/site/shared/dialog/index.js';
import { decodeHtml } from '@/components/utils.js';
import { getNativePayUrl as formGetNativePayUrl, checkPayStatus as formCheckPayStatus } from '@api/form';
import { getProductNativePayUrl, checkProductPayStatus, genKnowProductOrder } from '@api/product';
import { addGetParamToUrl, delHrefParam } from '@/components/modules/onlineForm/utils';
import SelectPayTypePopup from './SelectPayTypePopup.vue';

const getPayListToMemorize = memorize(() => {
    return new Promise((resolve, reject) => {
        getPayList()
            .then((data) => {
                if (data.success) {
                    resolve(data);
                } else {
                    warningMessage('系统错误，请稍候重试', true);
                    reject(data.msg || '系统错误，请稍候重试');
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });
});

export class Pay {
    static getPayList() {
        return getPayListToMemorize();
    }

    /**
     * 唤起支付
     * @param { Object } options - 支付数据
     * @example
     */
    static goOnlinePay(options) {
        options.isPc = !window._store.getters.isMobi;
        new Pay(options);
    }

    constructor(options) {
        this.businessTypes = options.businessTypes;
        this.isPc = options.isPc;
        this.options = options;
        this.paySpecifiedData = this.getSpecifiedData(this.businessTypes);
        this.payTimer = null;
        this.init();
    }

    init() {
        if (this.businessTypes === -1) {
            warningMessage('系统错误，请稍候重试', true);
            console.error('handlePcOnlinePay: 请传入正确的业务类型，参考 Pay.businessTypes');
            return;
        }
        if (this.isPc) {
            this.handlePcOnlinePay();
        } else {
            this.handleMobiOnlinePay();
        }
    }

    getSpecifiedData(businessTypes) {
        switch (businessTypes) {
            case Pay.businessTypes.FORM:
                return Pay.onlineFormSpecifiedData;
            case Pay.businessTypes.PRODUCT:
                return Pay.productSpecifiedData;
            default:
                throw new Error('getSpecifiedData: 请传入正确的业务类型，参考 Pay.businessTypes');
        }
    }

    openSelectPayType(payTypeList) {
        return new Promise((resolve) => {
            const htmlStr = `
                <div id="payDialog"></div>
            `;

            const removeDialog = () => {
                $('#payDialog').remove();
                $('html').css('overflow', '');
            };

            $(htmlStr).appendTo('body');
            $('html').css('overflow', 'hidden');

            new Vue({
                el: '#payDialog',
                render: (h) =>
                    h(SelectPayTypePopup, {
                        props: {
                            payTypeList,
                        },
                        on: {
                            close: () => {
                                resolve('');
                                removeDialog();
                            },
                            submit: (type) => {
                                resolve(type);
                                removeDialog();
                            },
                        },
                    }),
            });
        });
    }

    async handlePcOnlinePay() {
        const { payList } = await Pay.getPayList();
        if (payList.length === 0) {
            warningMessage(LS.paymentNotOpen || '支付功能未开启，请联系管理员', true);
            return;
        }
        const supportMultiple = payList.length > 1;
        const onlySupportAliPay = payList.length === 1 && payList[0].key === Pay.showPayMode.ALIPAY;
        const onlySupportWxPay = payList.length === 1 && payList[0].key === Pay.showPayMode.WECHAT;
        const onlySupportPaypal = payList.length === 1 && payList[0].key === Pay.showPayMode.PAYPAL;
        let payMode = -1;
        if (supportMultiple) {
            const type = await this.openSelectPayType(payList);
            switch (type) {
                case Pay.showPayMode.WECHAT:
                    payMode = Pay.payModeTypes.WECHAT;
                    break;
                case Pay.showPayMode.ALIPAY:
                    payMode = Pay.payModeTypes.ALIPAY;
                    break;
                case Pay.showPayMode.PAYPAL:
                    payMode = Pay.payModeTypes.PAYPAL;
                    break;
                default:
                    break;
            }
        } else {
            if (onlySupportWxPay) {
                payMode = Pay.payModeTypes.WECHAT;
            } else if (onlySupportAliPay) {
                payMode = Pay.payModeTypes.ALIPAY;
            } else if (onlySupportPaypal) {
                payMode = Pay.payModeTypes.PAYPAL;
            }
        }
        if (payMode === -1) {
            console.error('handlePcOnlinePay: 请传入正确的支付方式，参考 Pay.payModeTypes');
            return;
        }
        const { getNativePayUrl, getPcAliPayOrPaypalData, handleGetNativePayUrlParams, getPcWechatData } =
            this.paySpecifiedData;
        const getNativePayUrlParams = {
            payMode,
            ...handleGetNativePayUrlParams(this.options),
        };
        getNativePayUrl(getNativePayUrlParams)
            .then((data) => {
                if (data.success) {
                    if (payMode === Pay.payModeTypes.WECHAT) {
                        this.handleWechatPayInPc(getPcWechatData(this.options, data));
                    } else if (payMode === Pay.payModeTypes.ALIPAY) {
                        this.handleAliPayInPc(data.payForm, getPcAliPayOrPaypalData(this.options, data));
                    } else if (payMode === Pay.payModeTypes.PAYPAL) {
                        this.handlePalpalPayInPc(data.payForm, getPcAliPayOrPaypalData(this.options, data));
                    }
                } else {
                    warningMessage(data.errMsg || '网络错误', true);
                }
            })
            .catch((err) => {
                console.error(err);
                warningMessage('网络错误', true);
            });
    }

    handlePalpalPayInPc(payForm, pcPayPalData) {
        const bssId = pcPayPalData.bssId;
        const { sucUrl } = this.getPayReturnUrl(bssId);
        const encodeHtmlSrcUrl = Fai.encodeHtml(decodeURIComponent(sucUrl));
        // eslint-disable-next-line @jz/no-use-http-prefix
        const replacedReturnURlForm = payForm.replace('http://${returnUrl}', encodeHtmlSrcUrl);
        Fai.top.$(decodeHtml(replacedReturnURlForm)).appendTo('body');
    }

    getPayReturnUrl(bssId, otherParams = {}) {
        const random = parseInt(Math.random() * 10000);
        const specifiedParams = {
            ...this.paySpecifiedData.pcCallbackUrlParams(this.options),
            ...otherParams,
        };
        const href = delHrefParam(['success', 'moduleId', 'random', 'bssId'].concat(Object.keys(specifiedParams)));
        window.localStorage.setItem(Pay.PAY_RESULT_RANDOM_LOCAL_STORAGE_KEY, random);
        const moduleId = this.options.moduleId || -1;
        // success 0：失败，1：成功
        const returnUrl = encodeURIComponent(
            addGetParamToUrl(href, {
                ...specifiedParams,
                success: 0,
                moduleId,
                random,
                bssId,
            })
        );
        const sucUrl = encodeURIComponent(
            addGetParamToUrl(href, {
                ...specifiedParams,
                success: 1,
                moduleId,
                random,
                bssId,
            })
        );
        return {
            returnUrl,
            sucUrl,
        };
    }

    handleAliPayInPc(payForm, pcAliPayData) {
        const bssType = this.businessTypes;
        const bssId = pcAliPayData.bssId;
        const { sucUrl } = this.getPayReturnUrl(bssId);
        $.cookie(`payReturnUrl_${bssType}_${bssId}`, decodeURIComponent(sucUrl).replace(window.location.origin, ''), {
            path: '/',
            expires: 1,
        });
        Fai.top.$(decodeHtml(payForm)).appendTo('body');
    }

    handleWechatPayInPc(data) {
        this.showQrcode(data.url);
        this.payTimer = setInterval(this.checkPayStatus(data), 1000);
    }

    // 打开支付二维码
    showQrcode(url) {
        const imgUrl = `/qrCode.jsp?cmd=wxPayQrCode&&url=${url}`;
        const boxWidth = 860;
        const boxHeight = 550;
        const qrCodeHtml = `
            <div id="formPayCodeBox" class="site_form_pay_wrap">
                <div class="form_pay_qrcode" style="width: ${boxWidth}px; height: ${boxHeight}px;">
                    <div class="form_pay_qrcode_top">
                        <div id="siteFormClosePay" class="site_form_close"></div>
                    </div>
                    <div class="wxpay_content">
                        <div class="wxpay_QrCode_box">
                            <p class="wxpay_QrCode_title">微信支付</p>
                            <div class="wxpay_QrCode_imgBox">
                                <img class="wxpay_QrCode_img" src="${imgUrl}">
                            </div>
                            <div class="wxpay_QrCode_tipBox">
                                <div class="wxpay_QrCode_tipImg"></div>
                                <div class="wxpayQrCodeText">
                                    请使用微信扫一扫<br>扫描二维码支付
                                </div>
                            </div>
                        </div>
                        <div class="wx_guard_img"></div>
                    </div>
                </div>
            </div>
        `;
        Fai.top.$(qrCodeHtml).appendTo('body');
        Fai.top.$('#siteFormClosePay').click(() => {
            Fai.top.$('#formPayCodeBox').remove();
            clearInterval(this.payTimer);
        });
    }

    // 检查订单是否成功
    checkPayStatus(data) {
        return () => {
            const { checkPayStatus, handleCheckPayStatusParams } = this.paySpecifiedData;
            checkPayStatus(handleCheckPayStatusParams(data))
                .then((data) => {
                    if (data.success) {
                        clearInterval(this.payTimer);
                        Fai.top.$('#formPayCodeBox').remove();
                        this.options.paySuccessCallbackInPc?.(Pay.payModeTypes.WECHAT);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    Fai.top.ing('网络错误');
                });
        };
    }

    async handleMobiOnlinePay() {
        const bssId = await this.paySpecifiedData.getBssIdInMobi(this.options);
        if (bssId === -1 || typeof bssId === 'undefined') {
            return;
        }
        const bssType = this.businessTypes;
        const { returnUrl, sucUrl } = this.getPayReturnUrl(bssId);
        // 用于 支付宝、paypal 跳回来
        $.cookie(`payReturnUrl_${bssType}_${bssId}`, decodeURIComponent(sucUrl).replace(window.location.origin, ''), {
            path: '/',
            expires: 1,
        });
        let mallPaymentUrl = `/mallPayment.jsp?bssId=${bssId}&bssType=${bssType}&returnUrl=${returnUrl}&sucUrl=${sucUrl}`;
        const lanCode = window._store.state.curLanCode;
        if (lanCode && lanCode !== 'cn') {
            mallPaymentUrl = `/${lanCode}${mallPaymentUrl}`;
        }
        window.location.href = mallPaymentUrl;
    }
}

Pay.businessTypes = {
    FORM: 5,
    PRODUCT: 6,
};

// 实际支付时候的支付方式，需要区分pc或者h5
Pay.payModeTypes = {
    PAYPAL: 7,
    WECHAT: 17,
    ALIPAY: 19,
};

// 显示时候的支付方式，不区分pc或者h5
Pay.showPayMode = {
    PAYPAL: 7,
    WECHAT: 10,
    ALIPAY: 12,
};

Pay.PAY_RESULT_RANDOM_LOCAL_STORAGE_KEY = 'payResultRandom';

Pay.onlineFormSpecifiedData = {
    getNativePayUrl: formGetNativePayUrl,
    handleGetNativePayUrlParams(options) {
        return {
            formId: options.formId,
            siteFormSubmitId: options.id,
        };
    },
    checkPayStatus: formCheckPayStatus,
    handleCheckPayStatusParams(data) {
        return {
            siteFormSubmitId: data.id,
        };
    },
    pcCallbackUrlParams(options) {
        return {
            formId: options.formId,
        };
    },
    getPcAliPayOrPaypalData(options) {
        return {
            bssId: options.id,
        };
    },
    getPcWechatData(options, nativePayUrlData) {
        return {
            id: options.id,
            url: nativePayUrlData.url,
        };
    },
    getBssIdInMobi(options) {
        return Promise.resolve(options.id);
    },
};

Pay.productSpecifiedData = {
    getNativePayUrl: getProductNativePayUrl,
    handleGetNativePayUrlParams(options) {
        return {
            productId: options.productId,
        };
    },
    checkPayStatus: checkProductPayStatus,
    handleCheckPayStatusParams(data) {
        return {
            id: data.id,
        };
    },
    pcCallbackUrlParams() {
        return {};
    },
    getPcAliPayOrPaypalData(_, nativePayUrlData) {
        return {
            bssId: nativePayUrlData.knowProductOrderId,
        };
    },
    getPcWechatData(_, nativePayUrlData) {
        return {
            id: nativePayUrlData.knowProductOrderId,
            url: nativePayUrlData.codeUrl,
        };
    },
    getBssIdInMobi(options) {
        return new Promise((resolve) => {
            genKnowProductOrder({
                productId: options.productId,
            })
                .then((result) => {
                    const { success, knowProductOrderId, msg } = result;
                    if (success) {
                        resolve(knowProductOrderId);
                    } else {
                        resolve(-1);
                        warningMessage(msg || '网络错误', true);
                    }
                })
                .catch((err) => {
                    resolve(-1);
                    console.error(err);
                    warningMessage('网络错误', true);
                });
        });
    },
};
