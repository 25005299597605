import { CarouselPhotos } from '@shared/visitor/carouselPhotos/index.js';
/* --  图片特效  -- */
(function ($, FUNC) {
    var _moduleList = []; // 存储所有注册动画的模块
    // 注册
    FUNC.subscribe = function (option) {
        subscribe(option);
    };

    // 撤销
    FUNC.revoke = function (option) {
        revoke(option);
    };

    // 发布
    FUNC.publish = function () {
        publish();
    };

    FUNC.play = function (config) {
        play(config);
    };

    FUNC.removeDuplicateIds = function (option) {
        removeDuplicateIds(_moduleList, option.ids);
    };

    // 订阅
    function subscribe(option) {
        var module = Fai.top.$('#module' + option.id),
            only = typeof option.only == 'boolean' ? option.only : true; // 默认一个模块只能订阅一次

        if (module.length < 1) {
            return;
        }

        // 默认唯一，清除旧的订阅
        if (only) {
            revoke({ id: option.id });
        }
        _moduleList.push(option);
    }

    function removeDuplicateIds(arr, ids = []) {
        ids.forEach((id) => {
            var i = arr.findIndex((item) => item.id === id);
            if (i !== -1) {
                arr.splice(i, 1);
            }
        });
    }

    // 撤销
    function revoke(option) {
        var len = _moduleList.length - 1,
            revokeId = option.id,
            config;

        while (len > -1) {
            config = _moduleList[len];

            if (revokeId == config.moduleId) {
                _moduleList.splice(len, 1);
            }

            len -= 1;
        }
    }

    // 发布
    function publish() {
        var len = _moduleList.length - 1,
            config;

        while (len > -1) {
            config = _moduleList[len];

            run(config);

            len -= 1;
        }
    }

    // 执行
    function run(option) {
        let photoAnimate = option.photoAnimate,
            type = photoAnimate.t,
            effect = photoAnimate.st,
            style = option.style,
            border = photoAnimate.b,
            id = option.id;

        Vue.nextTick(() => {
            play({ id, type, effect, style, border });
        });
    }

    function getPhotoLargeDom(style) {
        // 图文展示-1、轮播多图-2、列表多图-3、
        // 图册目录-15、产品展示-16、文章列表-17
        let $animateDom = '';

        switch (style) {
            case 1:
                $animateDom = '.rich_img_wrap img';
                break;
            case 2:
                $animateDom = '.photo_display_list .photo_item';
                break;
            case 3:
                $animateDom = '.photo_display_area .photo_item';
                break;
            case 15:
                $animateDom = '.photo_display_area .photo';
                break;
            case 16:
                $animateDom = '.exhibition_product_pic_item';
                break;
            case 17:
                $animateDom = '.news_list_item_line';
                break;
        }
        return $animateDom;
    }

    /**
     * fix 弹性放大时重新获取的图片动画区域
     * @param {*} style
     */
    function fixLargeDomClass(style) {
        let fixAnimateDom = '';

        switch (style) {
            case 7:
                fixAnimateDom = 'img, .module_image--bg';
                break;
        }
        return fixAnimateDom;
    }

    /* 图片放大 */
    function initPhotoLargeEvent(effect, style, $dom) {
        let $animateDom = getPhotoLargeDom(style);

        $dom.off('mouseenter.effect', $animateDom).on('mouseenter.effect', $animateDom, function () {
            // 不能监听图片区域，因为弹性放大（先缩小后放大再缩小放大）时，如果鼠标放在缩小外的区域，被视为移出了区域，然后图片放大又触发鼠标移入事件
            // 又重新触发到动画，导致反复触发
            if (!isPc()) {
                return;
            }
            let animateStyle = '',
                fixAnimateDom = fixLargeDomClass(style),
                $that = fixAnimateDom ? $(this).find(fixAnimateDom) : $(this),
                time = 60, // 60毫秒的间隔 函数节流阀值
                timer;

            if (style == 17) {
                $that = $that.find('.news_list_img');
            }

            if (typeof timer != 'number' && !$that.data('animated')) {
                timer = setTimeout(function () {
                    if (effect == 0) {
                        // 普通放大
                    } else {
                        // 弹性放大
                        animateStyle = '0.6s ease 0s 1 normal both running elasticLarge';
                        if ($that.data('animated') != 'true') {
                            $that
                                .css('animation', animateStyle)
                                .one(
                                    'webkitAnimationStart mozAnimationStart MSAnimationStart oanimationstart animationstart',
                                    function () {
                                        $that.data('animated', 'true'); // 标记动画开始执行
                                    }
                                )
                                .one(
                                    'webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend',
                                    function () {
                                        $that.removeData('animated').css('animation', 'none');
                                    }
                                );
                        }
                    }

                    timer = null;
                }, time);
            }
        });
    }

    /* 图片边框特效 */
    function initPhotoBorderEffect($dom, style, effect, border = {}) {
        const animateClass = getEffectDom(style, $dom);

        var _thisTop,
            _thisRight,
            _thisBottom,
            _thisLeft,
            _thisTopBottom,
            _thisRightLeft,
            _width,
            _height,
            defaults = { speed: 300 },
            options = $.extend({}, defaults, options);

        borderEffect(effect, options);

        function BoderEffectDom(_this) {
            var obj = new Object();
            obj.refreshDom = function () {
                _thisTop = _this.find('.jz_border_effect_top').stop().show();
                _thisRight = _this.find('.jz_border_effect_right').stop().show();
                _thisBottom = _this.find('.jz_border_effect_bottom').stop().show();
                _thisLeft = _this.find('.jz_border_effect_left').stop().show();
                _thisTopBottom = _this.find('.jz_border_effect_top,.jz_border_effect_bottom').stop().show();
                _thisRightLeft = _this.find('.jz_border_effect_left,.jz_border_effect_right').stop().show();
                _this
                    .find(
                        '.jz_border_effect_top,.jz_border_effect_bottom,.jz_border_effect_left,.jz_border_effect_right'
                    )
                    .stop()
                    .show();
                _width = Math.floor(_this[0].getBoundingClientRect().width);
                _height = Math.floor(_this[0].getBoundingClientRect().height);
            };
            return obj;
        }

        function borderEffect(effect, options) {
            var borderEffectDiv, top, right, bottom, left, divStyle;

            $dom.off('mouseenter.effect', animateClass)
                .on('mouseenter.effect', animateClass, function (event) {
                    var el = $(this),
                        borderEffectFunc,
                        borderStyle = '',
                        borderWidthStyle = '',
                        borderHeightStyle = '',
                        width,
                        height;
                    const borderWidth = 'bw',
                        borderColor = 'bc',
                        borderType = 'y';

                    if (!isPc()) {
                        return;
                    }
                    width = Math.floor($dom.find(animateClass).filter(':visible')[0].getBoundingClientRect().width);
                    height = Math.floor($dom.find(animateClass).filter(':visible')[0].getBoundingClientRect().height);
                    if (style == 17) {
                        width = Math.floor(
                            $dom
                                .find(animateClass)
                                .filter(':visible')
                                .find('.jz_photo_border_effect')[0]
                                .getBoundingClientRect().width
                        );
                        height = Math.floor(
                            $dom
                                .find(animateClass)
                                .filter(':visible')
                                .find('.jz_photo_border_effect')[0]
                                .getBoundingClientRect().height
                        );
                    }

                    if (border[borderType] == 1) {
                        if (border[borderColor]) {
                            borderStyle = 'background-color:' + border[borderColor] + ';';
                        }
                        if (border[borderWidth] >= 0) {
                            borderWidthStyle = borderStyle + 'width:' + border[borderWidth] + 'px;';
                        }
                        if (border[borderWidth] >= 0) {
                            borderHeightStyle = borderStyle + 'height:' + border[borderWidth] + 'px;';
                        }
                    }

                    if (effect == 1) {
                        divStyle = {
                            top: 'left:' + width / 2 + 'px;' + borderHeightStyle,
                            right: 'top:' + height / 2 + 'px;' + borderWidthStyle,
                            bottom: 'right:' + width / 2 + 'px;' + borderHeightStyle,
                            left: 'bottom:' + height / 2 + 'px;' + borderWidthStyle,
                        };
                    } else if (effect == 3) {
                        divStyle = {
                            top: 'width:' + width + 'px;opacity: 1;' + borderHeightStyle,
                            right: 'height:' + height + 'px;opacity: 1;' + borderWidthStyle,
                            bottom: 'width:' + width + 'px;opacity: 1;' + borderHeightStyle,
                            left: 'height:' + height + 'px;opacity: 1;' + borderWidthStyle,
                        };
                    } else {
                        divStyle = {
                            top: borderHeightStyle,
                            right: borderWidthStyle,
                            bottom: borderHeightStyle,
                            left: borderWidthStyle,
                        };
                    }

                    borderEffectDiv = getBorderEffectDiv(effect, divStyle);
                    top = borderEffectDiv.top;
                    right = borderEffectDiv.right;
                    bottom = borderEffectDiv.bottom;
                    left = borderEffectDiv.left;

                    if (style == 17) {
                        el = el.find('.jz_photo_border_effect');
                    }
                    event.preventDefault();
                    if (!el.length) {
                        return;
                    }
                    if (!el.find('.jz_border_effect_top').length) {
                        // 不存在该节点才append
                        el.append(top, right, bottom, left);
                    }
                    borderEffectFunc = new BoderEffectDom(el);
                    borderEffectFunc.refreshDom();
                    switch (effect) {
                        case 0:
                        case 2:
                            borderEffect0_1.in(effect);
                            break;
                        case 1:
                            borderEffect2.in();
                            break;
                        case 3:
                            borderEffect3.in();
                            break;
                    }
                })
                .off('mouseleave.effect', animateClass)
                .on('mouseleave.effect', animateClass, function (event) {
                    var el = $(this),
                        divDom = el.find(
                            '.jz_border_effect_top,.jz_border_effect_bottom,.jz_border_effect_left,.jz_border_effect_right'
                        );

                    event.preventDefault();
                    switch (effect) {
                        case 0:
                        case 2:
                            borderEffect0_1.out(el, divDom);
                            break;
                        case 1:
                            borderEffect2.out(el, divDom);
                            break;
                        case 3:
                            borderEffect3.out(el, divDom);
                            break;
                    }
                });

            var borderEffect0_1 = {
                in(effect) {
                    let margin = 20;
                    _width = effect == 2 ? _width - margin : _width;
                    _height = effect == 2 ? _height - margin : _height;

                    _thisTopBottom.stop().animate(
                        {
                            width: _width,
                        },
                        options.speed
                    );
                    _thisRightLeft.stop().animate({
                        height: _height,
                    });
                },
                out(el, divDom) {
                    _thisTopBottom = el.find('.jz_border_effect_top,.jz_border_effect_bottom').stop();
                    _thisRightLeft = el.find('.jz_border_effect_left,.jz_border_effect_right').stop();
                    _thisTopBottom.stop().animate(
                        {
                            width: 0,
                        },
                        options.speed
                    );
                    _thisRightLeft.stop().animate(
                        {
                            height: 0,
                        },
                        options.speed,
                        function () {
                            divDom.stop().remove();
                        }
                    );
                },
            };

            var borderEffect2 = {
                in() {
                    _thisTopBottom.stop().animate(
                        {
                            width: _width,
                            left: 0,
                        },
                        options.speed
                    );
                    _thisRightLeft.stop().animate(
                        {
                            height: _height,
                            top: 0,
                        },
                        options.speed
                    );
                },
                out(el, divDom) {
                    _thisTopBottom = el.find('.jz_border_effect_top,.jz_border_effect_bottom').stop();
                    _thisRightLeft = el.find('.jz_border_effect_left,.jz_border_effect_right').stop();
                    _thisTopBottom.stop().animate(
                        {
                            width: 0,
                            left: _width / 2,
                        },
                        options.speed
                    );
                    _thisRightLeft.stop().animate(
                        {
                            height: 0,
                            top: _height / 2,
                        },
                        options.speed,
                        function () {
                            divDom.remove();
                        }
                    );
                },
            };

            var borderEffect3 = {
                in() {
                    let margin = 20;
                    _thisTop.stop().animate(
                        {
                            width: _width - margin,
                            top: margin / 2,
                            left: margin / 2,
                        },
                        options.speed
                    );

                    _thisBottom.stop().animate(
                        {
                            width: _width - margin,
                            bottom: margin / 2,
                            left: margin / 2,
                        },
                        options.speed
                    );

                    _thisLeft.stop().animate(
                        {
                            height: _height - margin,
                            top: margin / 2,
                            left: margin / 2,
                        },
                        options.speed
                    );

                    _thisRight.stop().animate(
                        {
                            height: _height - margin,
                            bottom: margin / 2,
                            right: margin / 2,
                        },
                        options.speed
                    );
                },
                out(el, divDom) {
                    _thisTop = el.find('.jz_border_effect_top').stop();
                    _thisRight = el.find('.jz_border_effect_right').stop();
                    _thisBottom = el.find('.jz_border_effect_bottom').stop();
                    _thisLeft = el.find('.jz_border_effect_left').stop();
                    _thisTop.stop().animate(
                        {
                            width: _width,
                            top: 0,
                            left: 0,
                            opacity: 0,
                        },
                        options.speed
                    );

                    _thisBottom.stop().animate(
                        {
                            width: _width,
                            bottom: 0,
                            left: 0,
                            opacity: 0,
                        },
                        options.speed
                    );

                    _thisLeft.stop().animate(
                        {
                            height: _height,
                            top: 0,
                            left: 0,
                            opacity: 0,
                        },
                        options.speed
                    );

                    _thisRight.stop().animate(
                        {
                            height: _height,
                            bottom: 0,
                            right: 0,
                            opacity: 0,
                        },
                        options.speed,
                        function () {
                            divDom.remove();
                        }
                    );
                },
            };
        }

        function getBorderEffectDiv(effect, style = {}) {
            var top, right, bottom, left, topStyle, rightStyle, bottomStyle, leftStyle;

            topStyle = style.top || '';
            rightStyle = style.right || '';
            bottomStyle = style.bottom || '';
            leftStyle = style.left || '';

            top =
                "<div style='" +
                topStyle +
                "' class='jz_not_scaleImage_item jz_border_effect_top jz_border_effect_" +
                effect +
                " jz_theme_bg_color'></div>";
            right =
                "<div style='" +
                rightStyle +
                "' class='jz_not_scaleImage_item jz_border_effect_right jz_border_effect_" +
                effect +
                " jz_theme_bg_color'></div>";
            bottom =
                "<div style='" +
                bottomStyle +
                "' class='jz_not_scaleImage_item jz_border_effect_bottom jz_border_effect_" +
                effect +
                " jz_theme_bg_color'></div>";
            left =
                "<div style='" +
                leftStyle +
                "' class='jz_not_scaleImage_item jz_border_effect_left jz_border_effect_" +
                effect +
                " jz_theme_bg_color'></div>";

            return { top, right, bottom, left };
        }
    }

    function getEffectDom(style) {
        let $this = '';

        switch (style) {
            case 1:
                $this = '.rich_img_wrap';
                break;
            case 2:
            case 3:
            case 7:
            case 15:
            case 16:
                $this = '.jz_photo_border_effect';
                break;
            case 17:
                $this = '.news_list_item_line';
                break;
        }
        return $this;
    }

    function initPhotoLayerEvent($dom, style) {
        const animateClass = getPhotoLaterEffectClass(style, $dom);
        let $layerDom, height;

        $dom.off('mouseenter.effect', animateClass)
            .on('mouseenter.effect', animateClass, function () {
                if (!isPc()) {
                    return;
                }
                var el = $(this),
                    hasDesc = el.attr('has-desc') == 'true';
                // 创建默认特效节点
                const html = getLayer(el);
                html && el.append(html);

                if (hasDesc) {
                    // 如果有图片描述且不隐藏，使用放大镜带图片描述的动画展示
                    const descDom = el.find('.jz_photo_desc_layer_amimate');
                    if (descDom.css('opacity') === '1') {
                        if (descDom.css('height') === '0px') descDom.css('height', '');
                        return;
                    }
                    // 否则，使用默认的动画展示，这里不把高度设为0会影响hover后图片的放大效果
                    else if (descDom.css('height') !== '0px') descDom.css('height', '0');
                }
                height = el.innerHeight();
                if (style == 17) {
                    html && el.find('.jz_photo_desc_layer_amimate').remove();
                    el = el.find('.news_list_img');
                    height = el.innerHeight();
                    html && el.append(html);
                }
                $layerDom = el.find('.jz_photo_layer_amimate');
                $layerDom.stop().animate(
                    {
                        height: height,
                        opacity: 1,
                    },
                    300
                );
            })
            .off('mouseleave.effect', animateClass)
            .on('mouseleave.effect', animateClass, function () {
                var el = $(this);
                $layerDom = el.find('.jz_photo_layer_amimate');
                $layerDom.stop().animate(
                    {
                        height: 0,
                        opacity: 0,
                    },
                    300
                );
            });

        function getLayer(el) {
            if (el.find('.jz_photo_layer_amimate').length) {
                return;
            }
            var layer = '';
            layer += "<div class='jz_photo_layer_amimate jz_not_scaleImage_item' style='height:" + height / 3 + "px;'>";
            layer += '<svg viewBox="0 0 48 48" class="jz_not_scaleImage_item">';
            layer +=
                '<path d="M24,0A24,24,0,1,1,0,24,24,24,0,0,1,24,0Z" fill="rgba(51, 51, 51,0.8)" fill-rule="evenodd"/>';
            layer +=
                '<path d="M33.8,32.39l-3.62-3.62A8.42,8.42,0,0,0,32,23.51a8.5,8.5,0,1,0-8.5,8.5,8.42,8.42,0,0,0,5.26-1.83l3.62,3.62ZM23.51,30a6.5,6.5,0,1,1,6.5-6.5A6.51,6.51,0,0,1,23.51,30Z" fill="rgb(250, 250, 250)" fill-rule="evenodd"/>';
            layer += '</svg>';
            layer += '</div>';
            return layer;
        }
    }

    function getPhotoLaterEffectClass(style) {
        let $this = '';

        switch (style) {
            case 1:
                $this = '.rich_img_wrap';
                break;
            case 2:
                $this = '.photo_display_list .photoItemWrap';
                break;
            case 3:
                $this = '.photo_display_area .photo_item_inner_wrap';
                break;
            case 7:
                $this = '.module_image_content';
                break;
            case 15:
                $this = '.photo_display_area .photo';
                break;
            case 16:
                $this = '.exhibition_show_product .exhibition_product_pic_item';
                break;
            case 17:
                $this = '.news_list_item_line';
                break;
        }
        return $this;
    }

    /* 清除图片特效 */
    function resetEvent($dom, style) {
        $dom.off('mouseenter.effect', getPhotoLaterEffectClass(style, $dom));
        $dom.off('mouseenter.effect', getEffectDom(style, $dom));
        $dom.off('mouseenter.effect', getPhotoLargeDom(style));
    }

    function isPc() {
        return Comm.getDevice() === 'pc';
    }

    function play(config) {
        if ([1, 2, 3, 15, 16, 17].includes(config.style)) {
            return;
        }

        let $dom = $('#module' + config.id),
            type = config.type,
            effect = config.effect,
            style = config.style,
            border = config.border;

        resetEvent($dom, style);
        if (config.type == 0) {
            return;
        }

        switch (type) {
            case 1:
                initPhotoLargeEvent(effect, style, $dom);
                break;
            case 2:
                /* 图片位移 （先放大，hover再偏移） */
                /* 纯用css实现 */
                break;
            case 3:
                initPhotoBorderEffect($dom, style, effect, border);
                break;
            case 4:
                /* 图片放小 */
                /* 纯用css实现 */
                break;
            case 5:
                initPhotoLayerEvent($dom, style, effect);
                break;
        }
    }

    FUNC.triggerPhotoAnimate = function (config) {
        triggerPhotoAnimate(config);
    };
    function triggerPhotoAnimate(config) {
        let $dom = $('#module' + config.id),
            type = config.type,
            effect = config.effect,
            style = config.style,
            dir = config.direction,
            animateClass = '',
            $animateClass = '',
            isCssTrigger;

        pauseCarousePhotoAutoPlay(style, config.id, true);
        switch (type) {
            case 1:
                /* 图片放大 */
                // eslint-disable-next-line no-case-declarations
                let fixAnimateDom = fixLargeDomClass(style);
                animateClass = getPhotoLargeDom(style);
                isCssTrigger = effect == 0;
                $animateClass = $dom.find(animateClass);
                !!fixAnimateDom && ($animateClass = $animateClass.find(fixAnimateDom));

                new Promise((resolve) => {
                    isCssTrigger ? $animateClass.addClass('jz_animate_go') : $animateClass.trigger('mouseenter');
                    setTimeout(() => resolve(), 350);
                }).then(() => {
                    isCssTrigger ? $animateClass.removeClass('jz_animate_go') : '';
                    endPlay();
                });
                break;
            case 2:
                /* 图片位移 （先放大，hover再偏移） */
                /* 纯用css实现 */
                animateClass = '.jz_photo_direction_' + ['', 'left', 'right', 'top', 'bottom'][dir];
                $animateClass = $dom.find(animateClass);
                new Promise((resolve) => {
                    setTimeout(() => resolve(), 350);
                }).then(() => {
                    new Promise((resolve) => {
                        $animateClass.addClass('jz_animate_go');
                        setTimeout(() => resolve(), 350);
                    }).then(() => {
                        $animateClass.removeClass('jz_animate_go');
                        endPlay();
                    });
                });
                break;
            case 3:
                /* 边框 */
                // eslint-disable-next-line no-case-declarations
                let index = 0;
                animateClass = getEffectDom(style);
                if (style == 2 && $dom.find('.carousel_three_pic').length) {
                    index = fixCarouselAnimateBorderClass($dom);
                    $animateClass = $dom.find(animateClass).eq(index);
                } else {
                    $animateClass = $dom.find(animateClass);
                }
                new Promise((resolve) => {
                    $animateClass.trigger('mouseenter');
                    setTimeout(() => resolve(), 600);
                }).then(() => {
                    $animateClass.trigger('mouseleave');
                    endPlay();
                });
                break;
            case 4:
                /* 图片放小 */
                /* 纯用css实现 */
                animateClass = '.jz_photo_small_effect_' + effect;
                $animateClass = $dom.find(animateClass);
                new Promise((resolve) => {
                    setTimeout(() => resolve(), 350);
                }).then(() => {
                    new Promise((resolve) => {
                        $animateClass.addClass('jz_animate_go');
                        $dom.find('.photo_small_layer').addClass('show_photo_small_layer');
                        setTimeout(() => resolve(), 350);
                    }).then(() => {
                        $animateClass.removeClass('jz_animate_go');
                        $dom.find('.show_photo_small_layer').removeClass('show_photo_small_layer');
                        endPlay();
                    });
                });
                break;
            case 5:
                /* 放大镜 */
                animateClass = getPhotoLaterEffectClass(style);
                $animateClass = $dom.find(animateClass);
                new Promise((resolve) => {
                    $animateClass.trigger('mouseenter'); // 没有图片描述：模拟mouseenter触发动画播放
                    $dom.addClass('jz_animate_go'); // 有图片描述：添加jz_animate_go，改变高度，触发transition
                    setTimeout(() => resolve(), 600);
                }).then(() => {
                    $animateClass.trigger('mouseleave');
                    $dom.removeClass('jz_animate_go');
                    endPlay();
                });
                break;
            case 6:
                animateClass = '.jz_photo_switchhover_effect';
                // eslint-disable-next-line no-undef
                $animateDom = $dom.find(animateClass);
                new Promise((res) => {
                    // eslint-disable-next-line no-undef
                    $animateDom.addClass('jz_photo_switchhover_effect-hover');
                    setTimeout(() => {
                        res();
                    }, 400);
                }).then(() => {
                    // eslint-disable-next-line no-undef
                    $animateDom.removeClass('jz_photo_switchhover_effect-hover');
                });
        }

        function endPlay() {
            // 选择特效后，1秒后再继续自动播放
            var time = 1000,
                timer = null;

            if (typeof timer != 'number') {
                timer = setTimeout(() => {
                    pauseCarousePhotoAutoPlay(style, config.id, false);
                    timer = null;
                }, time);
            }
        }
    }

    function fixCarouselAnimateBorderClass($dom) {
        // 轮播多图, 最后一种样式，只显示中间第一个样式
        let $ul = $dom.find('.photo_display_list'),
            $li = $ul.find('li'),
            index = 0;

        if (!$dom.find('.carousel_three_pic').length) {
            return;
        }
        index = Math.ceil(Math.abs($ul.position().left / $li.width()));
        return index;
    }

    function pauseCarousePhotoAutoPlay(style, id, pause = true) {
        // 暂停轮播多图自动播放
        if (style != 2 || !CarouselPhotos[id]) {
            return;
        }
        CarouselPhotos[id].mouseOnPhoto = pause;
    }
})(jQuery, JZ.photoAnimation || (JZ.photoAnimation = {}));
