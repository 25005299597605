export default {
    namespaced: true,
    state: () => ({
        footerStyleStatus: {
            status: false,
            footerHeight: 0,
        },
    }),
    mutations: {
        changeFooterStyleStatus(state, { status, footerHeight }) {
            state.footerStyleStatus['status'] = status;
            state.footerStyleStatus['footerHeight'] = footerHeight;
        },
    },
};
