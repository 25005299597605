var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "module",
      staticClass: "module_text_content",
      class: { J_jumpWxApp: _vm.isJumpWxApp },
    },
    [
      _vm.manageMode
        ? _c("fkeditor-component", {
            ref: "fkeditor",
            attrs: { id: _vm.module.id },
            on: { editStatus: _vm.editStatusChange },
            model: {
              value: _vm.module.content,
              callback: function ($$v) {
                _vm.$set(_vm.module, "content", $$v)
              },
              expression: "module.content",
            },
          })
        : _c("div", { staticClass: "fkeditor-wrap" }, [
            _c("div", {
              staticClass: "fk-editor",
              domProps: { innerHTML: _vm._s(_vm.module.content) },
            }),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }