export default {
    setFirstPaintData(state, payload) {
        state.manageMode = payload.manageMode;
        state.oem = payload.oem;
        state.jzVersion = payload.jzVersion;
        state.colId = payload.colId;
        state.resImageRoot = payload.resImageRoot;
        state.resRoot = payload.resRoot;
        state.isFreeVer = payload.isFreeVer;
        state.uiMode = payload.uiMode;
        state.siteVer = payload.siteVer;
        state.extId = payload.extId;
        state.aid = payload.aid;
        state.fullScreen = payload.fullScreen;
        state.isDemo = payload.isDemo;
        state.openSupport = payload.openSupport;
        state.silentLogin = payload.silentLogin;
        state.openRemoveUrlArgs = payload.openRemoveUrlArgs;
        state.openHtmlUrl = payload.openHtmlUrl;
        state.setReqHttpOnlyCookie = payload.setReqHttpOnlyCookie;
        state.isShowFkAuthTips = payload.isShowFkAuthTips;
        state.fkAuthTitle = payload.fkAuthTitle;
        state.lcid = payload.lcid;
        state.curLanCode = payload.curLanCode;
        state.webpOptOpen = payload.webpOptOpen;
        state.openMsgSearch = payload.openMsgSearch;
        state.pageName = payload.pageName;
        state.isFromQz = payload.isFromQz;
        state.contextMenuDisabled = payload.contextMenuDisabled;
        state.inFullNavRowModuleIds = payload.inFullNavRowModuleIds;
        state.reqArgs = payload.reqArgs;
        state.siteTipList = payload.siteTipList;
        state.fullScreenOpenFixed = payload.fullScreenOpenFixed;
        state.stellarPath = payload.stellarPath;
        state.device = payload.device;
        state.userHostName = payload.userHostName;
        state.isBUser = payload.isBUser;
        state.safeMode = payload.safeMode;
        state.allowScript = payload.allowScript;
        state.fullUrl = payload.fullUrl;
        state.isJuly3FreeUser = payload.isJuly3FreeUser;
        state.siteId = payload.siteId;
        state.wid = payload.wid;
        state.viewUrl = payload.viewUrl;
        state.welcomePage = payload.welcomePage;
        state.rowCol = payload.rowCol;
        state.proSiteQueryStr = payload.proSiteQueryStr;
        state.jzProUrl = payload.jzProUrl;
        state.siteDomain = payload.siteDomain;
        state.homeHost = payload.homeHost;
        state.isOemSiteHost = payload.isOemSiteHost;
        state.isOemDemoSiteHost = payload.isOemDemoSiteHost;
        state.siteAdmHost = payload.siteAdmHost;
        state.isExperienceAcct = payload.isExperienceAcct;
        state.hasCheckLvBrowser = payload.hasCheckLvBrowser;
        state.statistics = payload.statistics;
        state.openAutoPushPC = payload.openAutoPushPC;
        state.limitUv = payload.limitUv;
        state.limitDayUv = payload.limitDayUv;
        state.rowPics = payload.rowPics;
        state.isProduction = payload.isProduction;
        state.siteAutoBaiduPush = payload.siteAutoBaiduPush;
        state.LS = payload.LS;
        state.choiceLanguageForm = payload.choiceLanguageForm;
        state.tipHttpInCode = payload.tipHttpInCode;
        state.responsiveInfo = payload.responsiveInfo;
        state.modules = payload.modules;
        state.sections = payload.sections;
        state.rows = payload.rows;
        state.beianCode = payload.beianCode;
        state.newResponsiveConversion = payload.newResponsiveConversion;
        state.newMobiResponsiveConversion = payload.newMobiResponsiveConversion;
        state.templateNewResponsiveConversion = payload.templateNewResponsiveConversion;
        state.templateNewMobiResponsiveConversion = payload.templateNewMobiResponsiveConversion;
        state.portalHost = payload.portalHost;
        state.hasDomainSupportIpv6 = payload.hasDomainSupportIpv6;
        state.isJzCdnFlowLimit = payload.isJzCdnFlowLimit;
        state.jzCustodyExpireShowAD = payload.jzCustodyExpireShowAD;
        state.isOpenMResp = payload.isOpenMResp;
        state.realNameAuth = payload?.realNameAuth ?? {};
        state.debugMode = payload.debugMode;
        state.transformatinTrackingGlobalModel = payload.transformatinTrackingGlobalModel;
        state.isMaterialLazyLoad = payload.isMaterialLazyLoad;
        state.imageUseThumb = payload.imageUseThumb;
        this.commit('backToTop/setBackToTopData', payload);
        this.commit('wx/setWxData', payload);
        this.commit('salesPromotion/setSalesPromotionData', payload);
        this.commit('grayTest/setGrayTestData', payload);
    },
    changeDevice(state, device) {
        // 开启独立mobi设置的，不允许切换设备
        if (state.isOpenMResp) {
            state.device = 'pc';
            return;
        }
        if (state.manageMode && state.device != device) {
            // 管理态
            // 关掉所有面板
            // 退出所有hover编辑
            Root.exitAllEdit();
        }
        state.device = device;
    },
    changeFullScreen(state, value) {
        state.fullScreen = value;
    },
    addRowCol(state, id) {
        state.rowCol.push(id);
    },
    removeRowCol(state, id) {
        const i = state.rowCol.indexOf(id);
        if (i > -1) {
            state.rowCol.splice(i, 1);
        }
    },
};
