var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "list_photos_basic_style",
      class: _vm.wrapperClasses,
      attrs: { leninrow: _vm.photosInRow },
    },
    [
      _c("div", { staticClass: "jz_scroll_wrap" }, [
        _c(
          "ul",
          { ref: "photoList", staticClass: "photo_display_area jz_scroll" },
          _vm._l(_vm.photoList, function (photoItem, index) {
            return _c(
              "li",
              {
                directives: [
                  {
                    name: "animate",
                    rawName: "v-animate",
                    value: _vm.module,
                    expression: "module",
                  },
                ],
                key: photoItem.pid,
                class: "photo_item_li" + index,
                style: _vm.photoItemStyle(photoItem),
                attrs: { "data-id": photoItem.pid },
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "list_photo_wrapper",
                    class: `${
                      _vm.cursorAuto(photoItem.link.url)
                        ? "link-cursor-auto"
                        : ""
                    }`,
                    attrs: {
                      href: _vm.noLink
                        ? "javascript: void(0);"
                        : photoItem.link.url,
                      target: _vm.noLink
                        ? "_self"
                        : _vm.linkTarget(photoItem.link.ib),
                      rel: _vm.linkNofollow(photoItem.link.nf),
                      onclick: _vm.jumpOnclickStr(photoItem),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.slidesshow(index)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "photo_item_inner_wrap",
                        attrs: { "has-desc": !!photoItem.desc },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "hovermenu",
                                rawName: "v-hovermenu",
                                value: {
                                  hovermenu: _vm.hovermenu,
                                  args: {
                                    photoId: photoItem.pid,
                                    delHidden: _vm.usePhotoGroup,
                                  },
                                },
                                expression:
                                  "{\n                                hovermenu,\n                                args: {\n                                    photoId: photoItem.pid,\n                                    delHidden: usePhotoGroup,\n                                },\n                            }",
                              },
                            ],
                            staticClass: "photo_item_img_box",
                          },
                          [
                            _c("ImageComponent", {
                              directives: [
                                {
                                  name: "lazyload2",
                                  rawName: "v-lazyload2",
                                  value: {
                                    id: _vm.module.id,
                                    src: _vm.imgOriginalSrc(photoItem),
                                    naturalWidth: photoItem.w,
                                    naturalHeight: photoItem.h,
                                  },
                                  expression:
                                    "{\n                                    id: module.id,\n                                    src: imgOriginalSrc(photoItem),\n                                    naturalWidth: photoItem.w,\n                                    naturalHeight: photoItem.h,\n                                }",
                                },
                              ],
                              staticClass: "photo_item",
                              style: _vm.photoStyle,
                              attrs: {
                                src: _vm.imgOriginalSrc(photoItem),
                                webp: _vm.webpOptOpen,
                                "wrap-class": "photo_item_imgwrap",
                                "wrap-style": _vm.photoWrapStyle(),
                                alt: photoItem.desc,
                                "img-effects": _vm.isMobi
                                  ? { type: 0 }
                                  : _vm.isNotSwitchImgEffect
                                  ? _vm.imgOptsComputed
                                  : _vm.getImgComponetOption(
                                      _vm.module,
                                      photoItem.pid
                                    ),
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !!photoItem.desc && _vm.model.cmdd
                          ? _c("photo-description", {
                              attrs: {
                                module: _vm.module,
                                text: photoItem.desc,
                                hide: !_vm.model.cmdd,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _vm.moduleStyle === 1
        ? [
            _c("a", { staticClass: "arrow prev", on: { click: _vm.prev } }),
            _vm._v(" "),
            _c("a", { staticClass: "arrow next", on: { click: _vm.next } }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("image-main-viewer", {
        attrs: {
          "show-mobi-viewer": _vm.showMobiViewer,
          "show-pc-viewer": _vm.showPcViewer,
          "z-index": _vm.zIndex,
          "initial-index": _vm.imageIndex,
          "on-close": _vm.closeViewer,
          "url-list": _vm.previewSrcList,
          "is-mobi": _vm.isMobi,
          "manage-mode": _vm.manageMode,
          "log-event-config": _vm.logEventConfig,
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }