var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.wrapComponent, {
    key: _vm.row.id,
    tag: "component",
    attrs: { row: _vm.row, index: _vm.index, "rows-length": _vm.rowsLength },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }