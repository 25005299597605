var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "module_content_detail module_news_detail_content" },
    [
      _vm.noViewAllow && !_vm.manageMode
        ? _c("div", { staticClass: "module_news_detail_content pd_del_show" }, [
            _c("div", { staticClass: "jz_empty_tips" }, [
              _c("i", { staticClass: "jz_noLogin_tips__img" }),
              _vm._v(" "),
              _c("div", { staticClass: "jz_empty_tips__text msgTips" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.limitViewInfo.tips) +
                    "\n                "
                ),
                _vm.limitViewInfo.loginBtnHref
                  ? _c(
                      "a",
                      {
                        attrs: {
                          id: "login",
                          hidefocus: "true",
                          href: _vm.limitViewInfo.loginBtnHref,
                        },
                      },
                      [_vm._v(_vm._s(_vm.LS.memberLoginLogin))]
                    )
                  : _vm._e(),
              ]),
            ]),
          ])
        : !_vm.module.isEmptyNews && _vm.newsInfo
        ? [
            _vm.hideNews
              ? _c("div", { staticClass: "newsDetail_hide" }, [
                  _c("div", { staticClass: "newsDetail_hide--img" }),
                  _vm._v(" "),
                  _c("p", { staticClass: "newsDetail_hide--text" }, [
                    _vm._v(_vm._s(_vm.LS.jz_newsAudit_tips)),
                  ]),
                ])
              : [
                  _c("div", { staticClass: "news_detail_header" }, [
                    _c(
                      "h1",
                      {
                        staticClass: "news_detail_title",
                        style: _vm.titleStyle,
                      },
                      [_vm._v(_vm._s(_vm.decodeHtml(_vm.newsInfo.title)))]
                    ),
                    _vm._v(" "),
                    _vm.newsInfo.isOpenNewsSubTitle && _vm.newsInfo.subTitle
                      ? _c("h2", {
                          staticClass: "news_detail_subtitle",
                          style: _vm.subTitleStyle,
                          domProps: {
                            innerHTML: _vm._s(_vm.newsInfo.subTitle),
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isHeaderInfoLineOpen
                      ? _c("div", { staticClass: "news_detail_info" }, [
                          _c("div", { staticClass: "news_detail_info_left" }, [
                            _vm.isDateOpen
                              ? _c(
                                  "span",
                                  { staticClass: "news_detail_info_item" },
                                  [_vm._v(_vm._s(_vm.decodeHtml(_vm.date)))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isAuthorOpen
                              ? _c(
                                  "span",
                                  { staticClass: "news_detail_info_item" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.decodeHtml(_vm.newsInfo.author)
                                      )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "news_detail_info_right" }, [
                            _vm.isQrCodeOpen
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "news_detail_info_item news_detail_item_qrcode",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "news_detail_qrcode faisco-icons-S000262",
                                        attrs: { id: "newsDetailQrCode" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "news_detail_qrcode_box",
                                          },
                                          [
                                            _c("img", {
                                              attrs: { src: _vm.hoverQrSrc },
                                            }),
                                          ]
                                        ),
                                        _vm._v(
                                          _vm._s(_vm.qrCodeLcid) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.isViewOpen
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "news_detail_info_item news_view_item_count",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "news_view_count faisco-icons-S000259",
                                      },
                                      [_vm._v(_vm._s(_vm.newsInfo.views))]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "news_detail_content_wrap" },
                    [
                      _c("html-renderer", {
                        ref: "htmlRenderer",
                        staticClass: "news_detail_content",
                        attrs: { html: _vm.newsInfo.richContent },
                        on: { click: _vm.bindUeSlider },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.isExternal && _vm.newsInfo.copyrightNoticeOpen
                    ? _c("div", { staticClass: "copyright_notice_content" }, [
                        _c("span", [
                          _vm._v(_vm._s(_vm.newsInfo.copyrightNoticeContent)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            staticClass: "copyright_notice_content_href",
                            attrs: {
                              target: "_blank",
                              href: _vm.newsInfo.newsLink,
                            },
                          },
                          [_vm._v(_vm._s(_vm.newsInfo.newsLink))]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.download && _vm.download.list && _vm.download.list.length
                    ? _c("div", { staticClass: "news_detail_download_wrap" }, [
                        _c(
                          "div",
                          { staticClass: "news_detail_download_title" },
                          [_vm._v(_vm._s(_vm.download.title))]
                        ),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "news_detail_download_list" },
                          _vm._l(_vm.download.list, function (file, index) {
                            return _c(
                              "li",
                              {
                                key: index,
                                staticClass: "news_detail_download_item",
                                attrs: { id: "attach_" + file.id },
                              },
                              [
                                _c("i", {
                                  staticClass: "news_detail_download_item_icon",
                                  class: file.icon,
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "news_detail_download_item_link",
                                    attrs: {
                                      href:
                                        (_vm.isNeedLogin ||
                                          !_vm.currentNewsAttachAllow) &&
                                        !_vm.manageMode
                                          ? null
                                          : file.url,
                                      target: "_Blank",
                                      title: file.name,
                                    },
                                    on: { click: _vm.handleDownload },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "news_detail_download_item_text",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.decodeHtml(file.name))
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                /.pdf$/.test(file.name) && _vm.showViewIcon
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "news_detail_view_item_link",
                                        attrs: {
                                          href: file.sourcePath,
                                          target: "_Blank",
                                          title: "file.name",
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          { staticClass: "icon_file_preview" },
                                          [
                                            _c("use", {
                                              attrs: {
                                                "xlink:href":
                                                  "#icon_file_preview",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isGroupOpen ||
                  _vm.isSourceOpen ||
                  _vm.isLinkOpen ||
                  _vm.isShareOpen ||
                  _vm.isFooterInfoLineOpen
                    ? _c("div", { staticClass: "news_detail_divice_line" })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isGroupOpen ||
                  _vm.isSourceOpen ||
                  _vm.isLinkOpen ||
                  _vm.isShareOpen
                    ? _c("div", { staticClass: "news_content_footer" }, [
                        _vm.isSourceOpen || _vm.isLinkOpen || _vm.isShareOpen
                          ? _c(
                              "div",
                              { staticClass: "news_info_footer_line" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "news_info_soruece_detail" },
                                  [
                                    _vm.isSourceOpen
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "news_detail_info_item news_detail_info_source",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.decodeHtml(
                                                  _vm.newsInfo.source
                                                )
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isLinkOpen
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "news_detail_info_item",
                                          },
                                          [
                                            _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "news_detail_info_href",
                                                attrs: {
                                                  href: _vm.newsInfo.link,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.decodeHtml(
                                                      _vm.newsInfo.link
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.isShareOpen
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "news_share_info jz_share_info",
                                        on: {
                                          mouseenter: function ($event) {
                                            return _vm.jzShareMouseenter($event)
                                          },
                                          mouseleave: function ($event) {
                                            return _vm.jzShareMouseleave($event)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "jz_share_text news_share_text faisco-icons-S000258",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.newsShareClick.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.module.lcid.share)
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("jz-share-ssr-compon", {
                                          attrs: {
                                            list:
                                              _vm.module.share &&
                                              _vm.module.share.list,
                                            "share-param-list":
                                              _vm.module.shareParamList,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isGroupOpen
                          ? _c(
                              "div",
                              { staticClass: "news_detail_group_list" },
                              _vm._l(_vm.groups, function (item) {
                                return _c(
                                  "a",
                                  {
                                    staticClass: "news_belong_group",
                                    attrs: {
                                      href: item.href,
                                      target: "_blank",
                                    },
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              }),
                              0
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isFooterInfoLineOpen
                    ? _c("div", { staticClass: "news_detail_footer" }, [
                        _vm.isPrevNextOpen
                          ? _c("div", { staticClass: "news_pagenation_wrap" }, [
                              _vm.newsInfo.prevNext.hasPrev
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "news_pagenation news_pagenation_prev",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "pagenation_title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.module.lcid.newsPrevOne)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "pagenation_link",
                                          attrs: {
                                            hidefocus: "true",
                                            href: _vm.newsInfo.prevNext
                                              .prevNewsUrl,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.decodeHtml(
                                                _vm.newsInfo.prevNext
                                                  .prevNewsTitle
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.newsInfo.prevNext.hasNext
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "news_pagenation news_pagenation_next",
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "pagenation_title" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.module.lcid.newsNextOne)
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass: "pagenation_link",
                                          attrs: {
                                            hidefocus: "true",
                                            href: _vm.newsInfo.prevNext
                                              .nextNewsUrl,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.decodeHtml(
                                                _vm.newsInfo.prevNext
                                                  .nextNewsTitle
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
            _vm._v(" "),
            _vm.module.isCommentOpen
              ? _c(
                  "div",
                  { staticClass: "news_comment_wrap" },
                  [
                    !_vm.module.siteGfwNotAllow
                      ? [
                          _c("div", { staticClass: "creat_comment_box" }, [
                            _c("div", {
                              staticClass: "creat_comment_box_layer",
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "creat_comment_form" },
                              [
                                _vm.newsInfo.comment?.warningTips
                                  ? _c(
                                      "module-alert",
                                      { staticClass: "warning_tips" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              _vm.newsInfo.comment.warningTips
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "news_creat_name" }, [
                                  _c(
                                    "span",
                                    { staticClass: "news_creat_title" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.module.lcid.productCommentCreator
                                        ) + "："
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.needCommentName
                                    ? _c("input", {
                                        staticClass: "news_creat_name_input",
                                        attrs: {
                                          id: "newsCreatName",
                                          maxlength: "50",
                                          minlength: "2",
                                          type: "text",
                                        },
                                      })
                                    : _c(
                                        "span",
                                        { staticClass: "news_creat_title" },
                                        [_vm._v(_vm._s(_vm.memberName))]
                                      ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "news_creat_text" }, [
                                  _c(
                                    "span",
                                    { staticClass: "news_creat_title" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.checkColonMsgBoardContent) +
                                          "："
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    staticClass: "news_creat_text_input",
                                    attrs: {
                                      id: "newsCreatContent",
                                      placeholder: _vm.commentPlaceHolder,
                                      maxlength: "1000",
                                      minlength: "2",
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _vm.newsInfo.showCaptcha
                                  ? _c(
                                      "div",
                                      { staticClass: "news_creat_check" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "news_creat_title" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.module.lcid.verificationCode
                                              ) + "："
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          staticClass: "news_creat_check_input",
                                          attrs: {
                                            id: "newsValidateCode",
                                            type: "text",
                                            maxlength: "4",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("img", {
                                          staticClass: "news_validate_code_img",
                                          attrs: {
                                            id: "newsValidateCodeImg",
                                            title: _vm.refleshValidateTips,
                                            src: _vm.comment.validateCode,
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.newsValidateCodeImgClick.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "news_creat_submit" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "news_creat_submit_button jz_theme_bg_color",
                                        class: {
                                          "not-allowed-button":
                                            !_vm.manageMode &&
                                            _vm.realNameAuth
                                              .allowSubmitMessage === false,
                                        },
                                        attrs: { id: "newsSubmitBtn" },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.newsSubmitBtnClick.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.module.lcid.productCommentSubmit
                                          )
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]),
                          _vm._v(" "),
                          _vm.realNameAuth.allowShowMessage
                            ? _c("moduleNewsComment", {
                                attrs: {
                                  cmd: "getWafNotCk_getCommentList",
                                  "news-id": _vm.newsInfo.id,
                                  "module-id": _vm.module.id,
                                },
                                on: {
                                  addComment:
                                    _vm.mobiNewsDetailCommentEnterClick,
                                },
                              })
                            : _vm._e(),
                        ]
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              "font-size": "14px",
                              color: "#333",
                              "line-height": "1.5",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.module.siteGfwNoticeTips) +
                                "\n            "
                            ),
                          ]
                        ),
                  ],
                  2
                )
              : _vm._e(),
          ]
        : [
            _c("div", { staticClass: "jz_empty_tips" }, [
              _c("i", { staticClass: "jz_empty_tips__img" }),
              _vm._v(" "),
              _c("div", { staticClass: "jz_empty_tips__text" }, [
                _vm._v(_vm._s(_vm.LS.newsDetailNotFoundV2) + "~"),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }