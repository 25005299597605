function isOem() {
    return Fai.top.window._store.state.oem;
}
function getPortalHost() {
    var portalHost = Fai.top.window._store.state.portalHost;
    if (!portalHost) {
        //兜底处理 by jser
        if (isOem()) {
            portalHost = 'i.jz.huaweicloudsite.com';
        } else {
            portalHost = 'i.fkw.com';
        }
    }

    return portalHost;
}

// 获取市场-域名管理链接
export const getPortalDomainUrl = function () {
    if (isOem()) {
        return '//' + getPortalHost() + '/portal.jsp#appId=shop&tab=1';
    }

    return '//' + getPortalHost() + '/jump.jsp?t=2';
};

function getHomeHost() {
    var homeHost = Fai.top.window._store.state.manage.homeHost;

    if (!homeHost) {
        homeHost = 'www.fkw.com';
    }

    return homeHost;
}

export const getNewMarketCorpGroupBuySEOUrl = function (faiProductId, src) {
    return (
        '//' +
        getHomeHost() +
        '/marketing/corpGroupBuy.jsp?productId=' +
        faiProductId +
        (src != '' && src != undefined ? '&src=' + src : '')
    );
}; //企业团购链接

const getMarketCorpGroupBuyUrl = function (faiProductId, src) {
    if (isOem()) {
        //原先在portal那边
        return '//' + getPortalHost() + '/portal.jsp#appId=corpGroupBuy&productId=' + faiProductId;
    }

    return getNewMarketCorpGroupBuySEOUrl(faiProductId, src); // return '//' + getHomeHost() + '/marketing/corpGroupBuy.jsp?productId=' + faiProductId;
};

export const getMarketCorpGroupBuySEOUrl = function () {
    return getMarketCorpGroupBuyUrl(291, 17);
}; //域名管理链接

/**
 * 获取购买版本的链接
 * @function name - getPurchaseVersionUrl
 * @kind Root
 * @param version {number}   [版本]
 * @param extQuery {String}  [扩展的查询字符]
 * @return {String}          [购买链接]
 */

const getPurchaseVersionUrl = function (version, extQuery) {
    version = version || -1; // 补足空字符

    if (extQuery === undefined) {
        extQuery = '';
    } // 字符补足

    if (extQuery !== '' && extQuery[0] !== '&') {
        extQuery = '&' + extQuery;
    } // 拼接查询字符

    var queryArr = [
        't=40',
        '&siteVersion=' + version,
        '&buySiteVer=true',
        '&siteSid=' + window._store.state.siteId,
        extQuery,
    ];
    var queryString = '?' + queryArr.join('');
    return '//' + getPortalHost() + '/jump.jsp' + queryString;
}; //by jser 2018-8-13 把portal的相关链接抽取成接口
//短信平台账号链接

const getPortalSMSAcctUrl = function () {
    if (isOem()) {
        return '//' + getPortalHost() + '/portal.jsp#appId=smsAccount&tab=0';
    }

    return '//' + getPortalHost() + '/message/default.jsp#appId=smsAccount';
}; // 短信平台模板链接

const getPortalSMSTemplateUrl = function () {
    if (isOem()) {
        return '//' + getPortalHost() + '/portal.jsp#appId=smsAccount&tab=1';
    }

    return '//' + getPortalHost() + '/message/default.jsp#appId=smsTemplate';
}; // 市场链接

const getMarketUrl = function () {
    if (isOem()) {
        //原先在portal那边
        return '//' + getPortalHost() + '/portal.jsp#appId=corpGroupBuy&tab=5';
    }

    return '//' + getHomeHost() + '/marketing/index.html';
};

const getPortalDnspodTransferUrl = function () {
    if (isOem()) {
        return '//' + getPortalHost() + '/portal.jsp#appId=dnspodTransfer';
    }

    return '//' + getPortalHost() + '/domain/default.jsp#appId=dnspodTransfer';
}; //获取备案链接

const getPortalSiteRecordUrl = function () {
    if (isOem()) {
        return '//' + getPortalHost() + '/portal.jsp#appId=siteRecord';
    }

    return '//' + getPortalHost() + '/domain/default.jsp#appId=siteRecord';
};

const getProtalMPSRecordUrl = function () {
    if (isOem()) {
        return '//' + getPortalHost() + '/portal.jsp#appId=MPSRecord';
    }

    return '//' + getPortalHost() + '/domain/default.jsp#appId=MPSRecord';
};
export {
    getPurchaseVersionUrl,
    getPortalSMSAcctUrl,
    getPortalSMSTemplateUrl,
    getMarketUrl,
    getPortalDnspodTransferUrl,
    getPortalSiteRecordUrl,
    getProtalMPSRecordUrl,
    getMarketCorpGroupBuyUrl,
    getPortalHost,
};
