/**
 * 从 initSite 抽出来
 */

import { logJZFdp } from './shared/log';

// 顶部小黄条广告
export const topTips = {
    vm: null,
    /**
     * 【续费提醒调整优化-期望6月24日前补上】
     *  @link https://www.tapd.cn/42954297/prong/stories/view/1142954297001087876
     *  直销管理态
     *  生成续费提示小黄条
     *      到期前30天内：您的网站版本将于X天后到期，专享一次买断终身使用特权，使用时间越长越省钱！
     *      到期小于1天：您的网站版本将于今天到期，专享一次买断终身使用特权，使用时间越长越省钱！
     *      到期后30天内：您的网站版本已过期X天，专享一次买断终身使用特权，使用时间越长越省钱！
     *  @returns {String} - 续费 html
     */
    _createRenewTips() {
        if (window._store.state.oem === true || window._store.state.manageMode === false) {
            return '';
        }
        // 员工账号不出现
        if (window._store.state.manage.adm === false) {
            return '';
        }
        const { siteVipBeforeExpireDays, vipExpireDays, siteVipBeforeExpireHours } = window._store.state.manage.toolbar;
        const beforeExpire30Days = siteVipBeforeExpireDays >= 0 && siteVipBeforeExpireDays <= 30;
        const expired30Days = vipExpireDays >= 1 && vipExpireDays <= 30;
        const showRenewTips = window._store.state.oem ? false : beforeExpire30Days || expired30Days;
        if (showRenewTips === false) {
            return '';
        } else {
            const renewHref = `//${window._store.state.manage.portalHost}/jump.jsp?t=40&d=200586&_f=10&buySiteVer=true&siteSid=${window._store.state.siteId}&s=10&logDogFrom=3`;
            const renewButtonHtml = `<a id="topTipsRenewBtn" class="renew_top_tips" href="${renewHref}" target="_blank">去续费</a>`;

            const handleClick = () => {
                $('#jzRoot')
                    .off('click.topBar')
                    .on('click.topBar', '#topTipsRenewBtn', () => {
                        logJZFdp('jz_renew_up_click', {
                            jz_free_text0: '设计器-顶部',
                        });
                    });
            };
            const log = () => {
                logJZFdp('jz_renew_up_epo', {
                    jz_free_text0: '设计器-我的信息',
                    jz_version: window._store.state.jzVersion,
                });
            };

            log();
            handleClick();

            let html = '';
            if (expired30Days) {
                html = `您的网站版本已过期${vipExpireDays}天, 专享一次买断终身使用特权，使用时间越长越省钱! ${renewButtonHtml}`;
            } else if (beforeExpire30Days) {
                html = `您的网站版本将于${
                    siteVipBeforeExpireDays === 0
                        ? siteVipBeforeExpireHours + '小时后'
                        : siteVipBeforeExpireDays + '天后'
                }到期, 专享一次买断终身使用特权，使用时间越长越省钱! ${renewButtonHtml}`;
            } else {
                return '';
            }
            return `<span class="renew_tips_wrapper">${html}<span>`;
        }
    },
    init() {
        const renewTips = this._createRenewTips();
        if (renewTips !== '') {
            window._store.state.siteTipList.push(renewTips);
        }

        if (!window._store.state.siteTipList || !window._store.state.siteTipList.length || this.vm !== null) {
            return;
        }
        this.createTopTipsWrap();
        this.vm = new Vue({
            data() {
                return {
                    advList: window._store.state.siteTipList,
                    currentIndex: 0,
                    timer: null,
                    TIME_DELAY: 5000,
                };
            },
            watch: {
                advList() {
                    this.initScroll();
                },
            },
            mounted() {
                this.initScroll();
            },
            beforeDestroy() {
                this.clearTimer();
            },
            methods: {
                autoPlay() {
                    this.currentIndex++;
                    if (this.currentIndex > this.advList.length - 1) {
                        this.currentIndex = 0;
                    }
                },
                initScroll() {
                    if (this.advList.length < 2 || this.timer !== null) return;
                    this.timer = window.setInterval(() => {
                        this.autoPlay();
                    }, this._data.TIME_DELAY);
                },
                clearTimer() {
                    if (this.timer === null) return;
                    window.clearInterval(this.timer);
                },
            },
            template: `
                <div class="siteTips_container">
                    <transition-group tag="ul" class="siteTips_list" name="topTips">
                        <li class="siteTips_adv" v-for="(adv, index) in advList" :key="index" v-html="adv" v-show="currentIndex === index"></li>
                    </transition-group>
                </div>
            `,
        }).$mount('#sitetips .siteTips_container');
    },
    addTopTips(html) {
        window._store.state.siteTipList.push(html);
        if (this.vm !== null) {
            return;
        }
        this.init();
    },
    createTopTipsWrap() {
        const topTipsEl = document.querySelector('#sitetips .siteTips_container');
        if (topTipsEl) return;
        const $siteTips = $(`
            <div id='sitetips' class='sitetipsV2'>
                <div class='siteTips_container'>
                </div>
            </div>
        `);
        if (window._store.state.manageMode) {
            $siteTips.prependTo($('#jzRoot'));
        } else {
            $siteTips.insertBefore($('#jzWebContainer'));
        }
    },
};

// 免费二级域名无法提交评论和留言提示
export const realNameAuthTips = `
    <span style="color:red;">根据 <a style='color:red;cursor:pointer;' href='//mp.weixin.qq.com/s/0ZlQLQILaXwpklpef88eJg' target='_blank'>《互联网跟帖评论服务管理规定》</a>，免费域名不支持评论、留言功能与内容显示，请访问绑定域名体验。</span>
`;
