
import { mapState } from 'vuex';

import { Comm } from '../../utils';
const { mapFlag, flatten } = Comm;
import { addSearchHistoryRecord } from '@/components/modules/shared/siteSearch/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import emptyPanel from './emptyPanel.vue';
import mobiSearchBox from './mobiSearchBox.vue';
import tabs from './tabs.vue';
import loading from './loading.vue';
import unitSearchResultProduct from './productPanel.vue';
import unitSearchResultNews from './newsPanel.vue';
import unitSearchResultPhotoGroup from './photoGroupsPanel.vue';
import unitSearchResultFile from './fileDownloadPanel.vue';
import unitSearchResultCol from './colsPanel.vue';
import { INDEX_ALL, SEARCH_TABS } from './constants.js';
import { searchByPage } from '@api/searchResult';
import { warningMessage } from '@/site/shared/dialog/index.js';
import { logDog } from '@/site/shared/log/index.js';
import PaginationComponent from '@/components/modules/common/pagination/index.vue';
import SiteSearch from '@/components/modules/common/searchBox/index.vue';
import '@/styles/searchResult.scss';

export default {
    name: 'SearchResult',
    style: 30,
    components: {
        tabs,
        emptyPanel,
        mobiSearchBox,
        loading,
        unitSearchResultProduct,
        unitSearchResultNews,
        unitSearchResultPhotoGroup,
        unitSearchResultCol,
        unitSearchResultFile,
        PaginationComponent,
    },
    provide() {
        return {
            module: this.module,
            other: this.other,
            highlight: this.highlight,
        };
    },
    props: ['module'],
    data() {
        return {
            searchUrl: '',
            result: {},
            loading: true,
            keyword: '',
            currentKeyword: '',
            searchModuleId: -1,
            activeTabIndex: INDEX_ALL,
            componentNameArr: [
                'unitSearchResultProduct',
                'unitSearchResultNews',
                'unitSearchResultPhotoGroup',
                'unitSearchResultFile',
                'unitSearchResultCol',
            ],
            components: {},
            searchInfo: {},
            mobiHideClass: '.jz_web_banner, #gridFooter',
            specialChars: ['|', '.', '^', '$', '*', '+', '?'],
            filterTabIndexs: [],
            requestId: 0,
        };
    },
    watch: {
        isOpenPagination(isOpen = false) {
            this.loadListByTab(this.activeTabIndex, isOpen ? 1 : 0);
        },
        pageSize() {
            this.loadListByTab(this.activeTabIndex, 1);
        },
        isMobi(val) {
            if (val) {
                $(this.mobiHideClass).hide();
            } else {
                $(this.mobiHideClass).show();
            }
        },
        'module.prop0': function () {
            this.$nextTick(this.highlight);
        },
    },
    computed: {
        ...mapState(['manageMode', 'LS']),
        ...mapFlag({
            isOpenPagination: 0x2,
            showClassification: 0x4,
        }),
        isMobi() {
            return this.$store.state.device === 'mobi';
        },
        displayResultType() {
            return this.activeTabIndex === INDEX_ALL;
        },
        other() {
            return {
                showResultType: this.displayResultType,
                isOpenPagination: this.isOpenPagination,
                pageSize: this.pageSize,
                keyword: this.currentKeyword,
                showClassification: this.showClassification,
            };
        },
        pageSize() {
            return this.module.prop2 || 20;
        },
        paginationStyle() {
            return this.module.prop4;
        },
        pageCount() {
            const total = this.result.total;
            return total % this.pageSize == 0 ? total / this.pageSize : Math.ceil(total / this.pageSize);
        },
        searchSetting() {
            if (this.searchInfo.searchSetting) {
                return this.searchInfo.searchSetting;
            } else {
                return {
                    prop0: -1,
                    prop2: 0,
                    prop1: '',
                };
            }
        },
        visibleComponents() {
            if (this.isOpenPagination || this.activeTabIndex === INDEX_ALL) {
                return this.components;
            } else {
                const keys = Object.keys(this.components).filter(
                    (k) => this.componentNameArr[this.activeTabIndex] === k
                );
                if (keys.length === 0) {
                    return {};
                }
                return {
                    [keys[0]]: this.components[keys[0]],
                };
            }
        },
        sortType() {
            return this.module.blob0.sortType;
        },
    },
    created() {
        if (this.$isServer) {
            return;
        }

        this.searchUrl =
            Site.addRequestPrefix({ newPath: '/rajax/', oldPath: './rajax/' }) + 'search_h.jsp?cmd=getWafNotCk_search';

        this.keyword = Fai.getUrlParam(location.search, 'q');
        this.searchModuleId = Number(Fai.getUrlParam(location.search, 'id'));

        if (this.validateParams()) {
            this.searchAll();
        } else {
            this.loading = false;
        }
    },
    mounted() {
        if (this.isMobi) {
            $(this.mobiHideClass).hide();
        }
    },
    updated() {
        if (this.keyword === this.currentKeyword) {
            this.highlight();
        }
    },
    methods: {
        toastError(errorMsg, autoHide = true) {
            errorMsg = errorMsg || this.LS.searchResultError;
            if (this.manageMode) {
                Message.warning({
                    message: errorMsg,
                    autoHide,
                });
            } else {
                warningMessage(errorMsg, autoHide);
            }
        },
        validateParams() {
            if (!this.keyword || !this.searchModuleId || isNaN(this.searchModuleId)) {
                return false;
            }

            return true;
        },
        searchWithValidate() {
            if (this.currentKeyword === this.keyword) {
                return;
            }
            logDog(201014, 1);
            this.searchAll();
        },
        searchAll(page = 0) {
            this.currentKeyword = this.keyword;
            this.loadListFactory(
                'all',
                page
            )({
                action: this.searchUrl,
                success: (data) => {
                    if (data.searchInfo) {
                        this.searchInfo = data.searchInfo;
                    }
                    this.setFilterIndexs();
                    this.logDogByCategory();
                },
            });
        },
        logDogByCategory() {
            const visibleCategoryList = SEARCH_TABS.filter(
                (tab) => tab.index !== -1 && this.filterTabIndexs.indexOf(tab.index) === -1
            );
            visibleCategoryList.forEach((category) => {
                logDog(201015, category.logSrcId);
            });
        },
        transformComponents(list = []) {
            return list.reduce((acc, item) => {
                const keyIndex = Number(item.searchFlag);
                const componentName = keyIndex >= 0 ? this.componentNameArr[keyIndex] : void 0;
                if (componentName) {
                    acc[componentName] = Array.isArray(acc[componentName]) ? acc[componentName].concat([item]) : [item];
                }
                return acc;
            }, {});
        },
        changePage(page = 1) {
            this.loadListByTab(this.activeTabIndex, page);
        },
        loadListFactory(type = '', page = 0) {
            return ({ action, success, error }) => {
                if (!type) {
                    throw Error('illeagl type');
                }
                const data = {
                    keyword: this.keyword,
                    searchModuleId: this.searchModuleId,
                    resultModuleId: Number(this.module.id),
                };
                this.isOpenPagination && ((data.page = page ? page : 1), (data.pageSize = this.pageSize));

                this.loading = true;
                const requestId = ++this.requestId;
                searchByPage({
                    cmd: action ? Fai.getUrlParam(action, 'cmd') : `getWafNotCk_search${type}ByPage`,
                    ...data,
                })
                    .then((result) => {
                        try {
                            if (this.requestId > requestId) {
                                return;
                            }
                            if (!result.success) {
                                return this.toastError();
                            }
                            const list = result && result.data && result.data.list;
                            const cacheList = flatten(Object.values(this.components)).filter(
                                (item) => item.searchFlag === this.activeTabIndex || this.activeTabIndex === INDEX_ALL
                            );
                            const transformList =
                                this.paginationStyle === 2 && page > 1 ? cacheList.concat(list) : list;

                            this.components = this.transformComponents(transformList);
                            this.result = result && result.data;
                            this.result.currentPage = page;
                            this.$set(this.module, 'list', transformList);

                            typeof success === 'function' && success.call(this, result.data);
                            this.loading = false;
                        } catch (error) {
                            this.toastError();
                            this.loading = false;
                        }
                    })
                    .catch((err) => {
                        this.toastError();
                        typeof error === 'function' && error.call(this, err);
                    });
            };
        },
        loadListByTab(tabIndex = -1, page = 0) {
            this.activeTabIndex = tabIndex;

            if (tabIndex === -1) {
                return this.searchAll(page);
            }

            const actions = ['Products', 'News', 'PhotoGroups', 'FileName', 'Cols'];
            this.loadListFactory(actions[tabIndex], page)({});
        },
        loadListByTabWithCache(tabIndex = -1, page = 0) {
            if (this.result && this.result.list && !this.isOpenPagination) {
                // from cache
                return;
            }

            return this.loadListByTab(tabIndex, page);
        },
        highlight() {
            const encodeKeyword = this.currentKeyword
                .split('')
                .map((k) => {
                    const inChars = this.specialChars.some((c) => c === k);
                    return inChars ? `\\${k}` : k;
                })
                .join('');

            const pattern = new RegExp(encodeKeyword, 'g');

            $.each($('.result_highlight_node'), (i, el) => {
                const $el = $(el);
                $el.html($el.text().replace(pattern, `<span class="result_highlight">${this.currentKeyword}</span>`));
            });
        },
        setFilterIndexs() {
            // 搜索全部的时候 过滤没有数据的tab
            const totals = ['productsTotal', 'newsTotal', 'photoGroupsTotal', 'filesTotal', 'colsTotal'];
            this.filterTabIndexs = totals
                .filter((total) => {
                    return this.result[total] === 0;
                })
                .map((item) => totals.indexOf(item));
        },
    },
    render(h) {
        const mobiSearchBox = h('mobi-search-box', {
            props: {
                keyword: this.keyword,
            },
            on: {
                input: (val) => {
                    this.keyword = val;
                    addSearchHistoryRecord(val);
                },
                handleEnter: this.searchWithValidate,
            },
        });

        const searchBox = this.isMobi
            ? mobiSearchBox
            : h(SiteSearch, {
                  class: {
                      search_result_box: true,
                  },
                  props: {
                      module: this.searchSetting,
                      value: this.keyword,
                  },
                  on: {
                      input: (val) => (this.keyword = val),
                      onSearch: this.searchWithValidate,
                  },
              });

        const tabs = h('tabs', {
            props: {
                ranges: this.searchSetting.isSetRange ? this.searchSetting.prop5 : [],
                filterTabIndexs: this.filterTabIndexs,
            },
            on: {
                'update-tabs': (tabIndex) => (this.activeTabIndex = tabIndex),
                select: (tabIndex) => this.loadListByTab(tabIndex, 1),
            },
        });

        const components = Object.keys(this.visibleComponents).map((componentName) => {
            return h(componentName, {
                props: {
                    list: this.visibleComponents[componentName],
                    other: this.other,
                },
            });
        });

        const emptyPanel =
            components.length <= 0
                ? h('empty-panel', {
                      props: {
                          keyword: this.currentKeyword,
                      },
                  })
                : null;

        const pagination = this.isOpenPagination
            ? h('pagination-component', {
                  props: {
                      moduleId: this.module.id,
                      pageCount: this.pageCount,
                      currentPage: this.result.currentPage || 1,
                      pageSize: this.pageSize,
                      styleIndex: this.paginationStyle,
                  },
                  on: {
                      currentChange: this.changePage,
                  },
              })
            : null;

        const loading = h('loading', {
            props: {
                show: this.loading,
            },
        });

        const hasCache = this.result && this.result.list;
        const showSearchBox = hasCache || this.isMobi;
        const children = this.loading
            ? [loading, showSearchBox && searchBox, showSearchBox && tabs]
            : [searchBox, tabs, emptyPanel, ...components, pagination];

        return h(
            'div',
            {
                class: {
                    module_content_detail: true,
                    search_result_content: true,
                },
            },
            children
        );
    },
};
