import { Module } from '@shared/visitor/module/index.js';
import { checkScriptLoad, demandLoadJs, scriptOnload } from '@/site/shared/sourceLoad/index.js';

import { logDog, logJZFdp } from '@/site/shared/log/index.js';
import { context } from '@jz/utils';
if (!context._onlineMapMap) {
    context._onlineMapMap = new Map();
    let onlineMapInfo = {};
    onlineMapInfo.onlineMapCallBackStack = [];
    onlineMapInfo.finishCallBack = false;
    context._onlineMapMap.set('onlineMap', onlineMapInfo);
    context.onlineMapCallBack = function () {
        try {
            let onlineMap = context._onlineMapMap.get('onlineMap');
            onlineMap.onlineMapCallBackStack.length &&
                onlineMap.onlineMapCallBackStack.forEach((value) => {
                    typeof value === 'function' && value();
                });
            onlineMap.finishCallBack = true;
        } catch (error) {
            console.log(error);
            Site.logMonitor(8680, true);
        }
    };
}

//在线地图模块
const OnlineMap = class OnlineMap extends Module {
    constructor(...args) {
        super(...args);
        this.baiduJsSrc = '//api.map.baidu.com/api?v=2.0&ak=KfNSLxuGpyk9BBYHnSqZsYoKxn7MUGxX';
        this.googleJsSrc =
            '//maps.googleapis.com/maps/api/js?key=AIzaSyCQ8IYEmOy-pXjctSJAYGQsV997OgeXLzU&language=en-US';

        this.OnlineMap = context._onlineMapMap.get('onlineMap');
        if (window._store.state.manageMode) {
            this.init();
        } else {
            // window.addEventListener("load", () => this.init());
            // 这个时间无所谓的，但是setTimeout一定不能删掉！！！！！！，时间您们随意
            setTimeout(() => {
                this.init();
            }, 300);
        }
    }

    init() {
        const baiduJsSrc = this.baiduJsSrc;
        const googleJsSrc = this.googleJsSrc;

        let isBaiduMap = this.conf.type != 1,
            mapSrc = isBaiduMap ? baiduJsSrc : googleJsSrc,
            isLoadMapScript = checkScriptLoad(mapSrc);
        this.OnlineMap.onlineMapCallBackStack && this.OnlineMap.onlineMapCallBackStack.push(() => this.initMapJs());
        if (isBaiduMap) {
            if (checkScriptLoad(`${this.baiduJsSrc}&callback=onlineMapCallBack`) && this.OnlineMap.finishCallBack) {
                // script 已插入
                this.initMapJs();
            } else {
                !$('#onlineMapApi').length && this.asyncLoadBaiduScript('onlineMapCallBack');
            }
            return;
        }
        if (isLoadMapScript) {
            // script 已插入
            scriptOnload(mapSrc, 10000)
                .then(() => {
                    this.initMapJs();
                })
                .catch((err) => {
                    console.error(err);
                    this.onLoadFailed();
                });
        } else {
            demandLoadJs(mapSrc, () => this.initMapJs());
            logDog(201508, 3);
        }
    }

    onLoadFailed() {
        if (this.conf.type === 1) {
            // 谷歌地图
            const G = window.google;
            if (!G || !G.maps || !G.maps.LatLng) {
                if (window._store.state.manageMode) {
                    Vue.$famessage?.warning('当前谷歌地图在中国区已经被屏蔽', 3, null, true);
                } else {
                    Fai.ing('当前谷歌地图在中国区已经被屏蔽', true);
                }
            }
        }
    }

    asyncLoadBaiduScript(callBack) {
        const script = document.createElement('script'),
            baiduJsSrc = `${this.baiduJsSrc}&callback=${callBack}`;
        script.src = baiduJsSrc;
        script.id = 'onlineMapApi';
        script.charset = 'utf-8';
        document.body.appendChild(script);
    }

    initMapJs() {
        if (this.conf.type != 1) {
            if (!this.OnlineMap.ComplexCustomOverlay) {
                this.OnlineMap.ComplexCustomOverlay = this.addComplexCustomOverlay();
            }
            this.initBaiDuMap();
        } else {
            this.googleMapInitial();
        }
    }

    initBaiDuMap() {
        try {
            var mapContainerId = this.conf.mapContainerId,
                mapPos = this.conf.mapPos,
                map,
                point;
            let noteInfo;
            if (this.conf.module) {
                let blob0 = this.conf.module.blob0 || {};
                let { multiAddress = [] } = blob0;
                if (multiAddress.length) {
                    noteInfo = multiAddress[0].da || '';
                }
            } else {
                noteInfo = this.conf.noteInfo;
            }
            map = this.map = new window.BMap.Map(mapContainerId); // 创建地图实例
            point = new window.BMap.Point(mapPos.x, mapPos.y); // 创建点坐标
            map.centerAndZoom(point, mapPos.z);
            // map.enableScrollWheelZoom(true); //鼠标滚轮缩放
            // 视图向上移动100px，用于将标记居中展示
            moveMapPosition(map, {
                openNavigation: this.conf?.openAddressNav,
            });

            map.enablePinchToZoom();
            map.enableDoubleClickZoom();

            //自定义标注
            var myIcon = new window.BMap.Icon(
                window._store.state.resRoot + '/image/rimage/module/online_map/marker_red_sprite.png?v=201809101152',
                new window.BMap.Size(22, 25),
                {
                    anchor: new window.BMap.Size(10, 25),
                }
            );
            var marker = new window.BMap.Marker(point, { icon: myIcon }); // 创建标注
            map.addOverlay(marker);

            //百度地图缩放工具  ----鱼骨
            var bMapNavigation = new window.BMap.NavigationControl();
            map.addControl(bMapNavigation);

            var mapcontainer = $('#' + mapContainerId);

            let msgContent = Fai.encodeHtml(noteInfo);
            let boxClass = 'msgBox';

            if (this.conf.openAddressNav) {
                msgContent = createMapNavigation({
                    da: noteInfo,
                    a: this.conf.addressDetail,
                });
                boxClass = 'msgBox baiduBox';
            }

            //实例化覆盖物对象----用于替代信息窗体
            var myCompOverlay = (this.myCompOverlay = new this.OnlineMap.ComplexCustomOverlay({
                point,
                content: '<div class="msg">' + msgContent + '</div>',
                boxClass,
                arrowClass: 'arrow',
                zIndex: 1,
                offset: {
                    x: 0,
                    y: -35,
                },
                arrowOffsetX: this.conf.openAddressNav ? -6 : 16,
            }));

            //地图缩放重新计算覆盖物位置
            map.addEventListener('zoomend', function () {
                myCompOverlay.reDraw();
            });

            //地图大小变化
            map.addEventListener('resize', () => {
                // 页面存在滚动条，打开弹窗条件下会触发resize.....
                if (sessionStorage.getItem('editAddress') == null) {
                    var newPoint = new window.BMap.Point(mapPos.x, mapPos.y);
                    map.centerAndZoom(newPoint, mapPos.z);
                }
            });

            //把自定义覆盖物添加到地图
            if (msgContent != '') {
                map.addOverlay(myCompOverlay);
            }

            this.initMapEvent(map, mapcontainer, marker, point, myCompOverlay);
            this.bindNavigationEvent(mapContainerId);
            logJZFdp('jz_baidu_maps', {
                jz_content_terminal: '自适应',
                jz_version: window._store.state.jzVersion,
                jz_free_bool1: !window._store.state.manageMode,
            });
        } catch (err) {
            console.log(err);
            this.loadMapFailure(mapContainerId, err.name);
        }
    }

    googleMapInitial() {
        //获取承载谷歌地图的容器
        var mapContainerId = this.conf.googleMapContainerId,
            container = document.getElementById(mapContainerId),
            mapPos = this.conf.mapPos,
            noteInfo = this.conf.module ? this.conf.module.prop3 : this.conf.noteInfo,
            mapPosX = mapPos.gx,
            mapPosY = mapPos.gy,
            G = window.google,
            googleMap,
            marker,
            g_infoWin,
            myLatLng,
            myOverLatLng,
            mapOpts,
            markerOpts;

        if (!G || !G.maps || !G.maps.LatLng) {
            return;
        }

        myLatLng = new window.google.maps.LatLng(mapPosX, mapPosY);
        myOverLatLng = new window.google.maps.LatLng(mapPosX, mapPosY);
        //创建谷歌地图
        mapOpts = {
            draggable: true,
            zoom: mapPos.z,
            streetViewControl: false,
            center: myLatLng,
            scaleControl: true,
            mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        };

        this.googleMap = googleMap = new window.google.maps.Map(container, mapOpts);

        markerOpts = {
            map: googleMap,
            position: myOverLatLng,
            animation: window.google.maps.Animation.DROP,
            draggable: true,
        };
        //创建标记
        this.marker = marker = new window.google.maps.Marker(markerOpts);
        marker.setMap(googleMap);

        this.g_infoWin = g_infoWin = new window.google.maps.InfoWindow({
            content: Fai.encodeHtml(noteInfo),
            width: 'auto',
            height: 'auto',
        });
        g_infoWin.open(googleMap, marker);

        this.initGoogleMapEvent($(container));

        logJZFdp('jz_google_maps', {
            jz_content_terminal: '自适应', // 内容终端 自适应
            free_bool_1: false, //是否在弹窗内 否
        });
    }

    updataGoogleInfo() {
        var noteInfo = this.conf.module ? this.conf.module.prop3 : this.conf.noteInfo;

        this.g_infoWin.close(this.googleMap, this.marker);
        this.g_infoWin = new window.google.maps.InfoWindow({
            content: Fai.encodeHtml(noteInfo),
            width: 'auto',
            height: 'auto',
        });
        this.g_infoWin.open(this.googleMap, this.marker);
    }

    updataMapType(type) {
        this.conf.type = type;
    }

    centerPoint() {
        const mapPos = this.conf.mapPos;
        const point = new window.BMap.Point(mapPos.x, mapPos.y);
        this.map.centerAndZoom(point, mapPos.z);
    }

    initMapEvent(map, mapcontainer, marker, point, myCompOverlay) {
        if (window._store.state.manageMode) {
            mapcontainer.on('mousedown', function (e) {
                e.stopPropagation();
            });
        }

        map.addEventListener('click', function (e) {
            if (e.overlay) {
                e.overlay.addEventListener('infowindowopen', function () {
                    map.removeOverlay(myCompOverlay);
                });
            }
        });

        marker.addEventListener('click', function () {
            map.clearOverlays();
            map.addOverlay(myCompOverlay);
            map.addOverlay(marker);
            map.panTo(point);
        });
    }

    initGoogleMapEvent(mapcontainer) {
        if (window._store.state.manageMode) {
            mapcontainer.on('mousedown', function (e) {
                // 阻止拖拽模块事件
                e.stopPropagation();
            });
        }
    }

    loadMapFailure(mapContainerId, errNAME) {
        var html =
            "<div class='mapLoadError'>" +
            "<div class='errormsg'>地图加载失败</div>" +
            "<div class='errName'>" +
            errNAME +
            '</div>' +
            '</div>';
        $('#' + mapContainerId).html(html);
    }

    addComplexCustomOverlay() {
        //自定义overlay，用于替代信息框，其实就等于自定义信息框  --------------zhj
        /*--自定义覆盖物核心代码------------------------------------------------------------------------------------*/
        function ComplexCustomOverlay(param) {
            this.point = param.point; //覆盖物坐标点
            this.content = param.content || ''; //覆盖内容
            this.zIndex = param.zIndex || 1; //覆盖物内容层级
            this.boxClass = param.boxClass || ''; //覆盖物容器class名称
            this.boxStyle = cssText(param.boxStyle); //覆盖物容器样式
            this.arrowClass = param.arrowClass || ''; //覆盖物箭头class名称
            this.arrowStyle = cssText(param.arrowStyle); //覆盖物箭头样式
            this.offset = param.offset || { x: 0, y: 0 }; //覆盖物的偏移量
            this.arrowOffsetX = param.arrowOffsetX || 10;
        }

        //把百度地图遮盖物对象赋值给构造函数原型对象
        ComplexCustomOverlay.prototype = new window.BMap.Overlay();

        //初始化创建覆盖物
        ComplexCustomOverlay.prototype.initialize = function (map) {
            this.map = map;
            this.box = document.createElement('div');
            this.box.className = this.boxClass;
            this.box.style.cssText = this.boxStyle;
            this.box.innerHTML = this.content;

            this.close = document.createElement('div');
            this.close.className = 'close';

            this.arrow = document.createElement('div');
            this.arrow.className = this.arrowClass;
            this.arrow.style.cssText = this.arrowStyle;
            this.box.appendChild(this.arrow);
            this.box.appendChild(this.close);

            //这个方法是把定义好的盒子 添加到文本标注所在的容器
            map.getPanes().labelPane.appendChild(this.box);
            this.boxWidth = getStyle(this.box, 'width');
            this.initDefaultEvent(map);
            return this.box;
        };

        ComplexCustomOverlay.prototype.addEventListener = function (event, fun) {
            this.box.addEventListener(event, fun);
        };

        ComplexCustomOverlay.prototype.initDefaultEvent = function (map) {
            var self = this;
            //阻止默认事件
            this.addEventListener('mousedown', function (e) {
                e.stopPropagation();
            });
            this.addEventListener('click', function (e) {
                e.stopPropagation();
            });
            this.addEventListener('touchmove', function (e) {
                e.stopPropagation();
            });

            this.addEventListener('touchstart', function (e) {
                if (e.target.getAttribute('class').indexOf('close') != -1) {
                    map.removeOverlay(self);
                }
            });

            this.addEventListener('mousedown', function (e) {
                if (e.target.getAttribute('class').indexOf('close') != -1) {
                    map.removeOverlay(self);
                }
            });
        };

        //地图拖动调用事件
        ComplexCustomOverlay.prototype.draw = function () {
            mapDraw(this);
        };

        ComplexCustomOverlay.prototype.hide = function () {
            this.box.style.display = 'none';
        };

        ComplexCustomOverlay.prototype.reDraw = function () {
            mapDraw(this);
        };

        //计算覆盖物在地图上的位置
        function mapDraw(_this) {
            var pixel = _this.map.pointToOverlayPixel(_this.point);
            _this.box.style.left =
                pixel.x -
                parseInt(getStyle(_this.box, 'width')) / 2 -
                (parseInt(getStyle(_this.box, 'padding-left')) + parseInt(getStyle(_this.box, 'padding-right'))) / 2 +
                _this.offset.x +
                'px';
            _this.box.style.bottom = -pixel.y + parseInt(getStyle(_this.arrow, 'height')) - _this.offset.y + 'px';
            _this.arrow.style.left = parseInt(getStyle(_this.box, 'width')) / 2 + _this.arrowOffsetX + 'px';
        }

        /*--自定义核心代码结束------------------------------------------------------------------------------------*/

        /*--下面为辅助代码------------------------------------------------------------------------------------*/

        //获取元素样式
        function getStyle(node, attr) {
            if (typeof getComputedStyle != 'undefined') {
                var value = getComputedStyle(node, null).getPropertyValue(attr);
                return attr == 'opacity' ? value * 100 : value; //兼容不透明度，如果是不透明度，则返回整数方便计算
            } else if (typeof node.currentStyle != 'undefined') {
                //如果是IE
                if (attr == 'opacity') {
                    //兼容不透明度
                    return Number(node.currentStyle.getAttribute('filter').match(/(?:opacity[=:])(\d+)/)[1]);
                } else {
                    return node.currentStyle.getAttribute(attr);
                }
            }
        }

        //css样式字符串处理
        function cssText(style) {
            var css = '';
            if (typeof style == 'string') {
                css = style;
            } else if (typeof style == 'object') {
                for (var i in style) {
                    css += i + ':' + style[i] + ';';
                }
            }
            return css;
        }

        return ComplexCustomOverlay;
    }

    updateConf(obj = {}) {
        Object.assign(this.conf, obj);
    }

    bindNavigationEvent(mapContainerId) {
        let startInput = $(`#${mapContainerId} .start`);
        let navSure = $(`#${mapContainerId} .nav_sure`);

        let startAddress = '';

        startInput
            .bind('input', function () {
                startAddress = startInput.val();
                startAddress == '' ? navSure.removeClass('nav_active') : navSure.addClass('nav_active');
            })
            .bind('touchstart', function () {
                // 手动聚焦。地图有个层级div阻止了事件
                startInput.focus();
            });

        navSure.bind('click touchstart', () => {
            if (startAddress != '') {
                let url = `//api.map.baidu.com/direction?origin=${startAddress}&destination=${this.conf.addressDetail}&region=${this.conf.city}&mode=driving&output=html`;
                if (jzUtils.browser.isIos()) {
                    window.location.href = url;
                } else {
                    window.open(url);
                }
            }
        });

        $(`#${mapContainerId}`)
            .find('.msgBox')
            .bind('mousemove click', function (e) {
                e.stopPropagation();
            });
    }

    unbindNavigationEvent(mapContainerId) {
        let startInput = $(`#${mapContainerId} .start`);
        let navSure = $(`#${mapContainerId} .nav_sure`);

        startInput.unbind('input, touchstart');
        navSure.unbind('click, touchstart');
    }
};

function createMapNavigation(address) {
    return `
        <div class="info">${Fai.encodeHtml(address.da)}</div>
        <div class="navigation">
            <input class="start" placeholder="输入起点" maxlength="100">
            <div class="end">
                <input value="${address.a}" disabled>
                <span class="nav_sure">确定</span>
            </div>
        </div>
    `;
}

function moveMapPosition(map, options = {}) {
    let { x, y, panOptions = {}, openNavigation = false } = options;
    if (window._store.state.device == 'pc') {
        // 暂时不用移动
        return;
    } else {
        x = x || 0;
        if (y == null) {
            y = openNavigation ? 100 : 0;
        }
        map.panBy(x, y, panOptions);
    }
}

export { OnlineMap, createMapNavigation, moveMapPosition };
