import { addNewModule } from '@shared/manage/module/index.js';
import { createFnBeforeCheckSaveBar } from '@shared/manage/popupChangedBodyWindow/index.js';
import { recordModuleAndRefreshPage } from '@shared/manage/scrollTop/index.js';

const newsListModuleAdd = function (name) {
    addNewModule(
        {
            style: 17,
            name: name,
        },
        {
            isDragadd: false,
            callback: function (resultInfo) {
                var newsList = resultInfo.info;

                if (newsList && newsList.blob0 && newsList.blob0.av === 1) {
                    newsList.blob0.av = 0;
                }
            },
        }
    );
};

const newsGroupModuleAdd = function (name) {
    addNewModule(
        {
            style: 14,
            name: name,
        },
        {
            isDragadd: false,
        }
    );
};

/**
 * @function name - manageNewsGroup
 * @kind Root
 * @description 管理文章分类
 * @param foo {Object}
 * @param foo.addOneGroupItem {Boolean}  是否自动添加一个分类
 */

const manageNewsGroup = (data) => {
    let { addOneGroupItem = false, moduleId, isFromCategoryNav } = data;
    const url = new URLSearchParams();
    if (addOneGroupItem) {
        url.append('addOneGroupItem', true);
    }
    if (isFromCategoryNav) {
        url.append('isFromCategoryNav', true);
    }

    createFnBeforeCheckSaveBar(
        (result) => {
            if (moduleId) {
                moduleId = result?.addModuleIdHash?.[moduleId] ?? moduleId;

                url.append('moduleId', moduleId);
            }
            Fai.popupWindowVersionTwo.createPopupWindow({
                title: '管理文章分类',
                frameSrcUrl: 'manage_v2/newsGroupEdit.jsp?' + url,

                closeFunc: () => {
                    recordModuleAndRefreshPage(moduleId);
                },
                width: '765',
                height: '671',
                saveBeforePopup: false,
                version: 2,
            });
        },
        {
            hideEditPanel: true,
        }
    )();
};

export { newsListModuleAdd, newsGroupModuleAdd, manageNewsGroup };
