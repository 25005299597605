import { logDog } from '@/site/shared/log/index.js';
import { getRowBelong } from '@/manage/grid/row/rowUtils/index.js';
let colDefault = {
    data: {
        data: [
            {
                type: 0,
                mIds: [],
                rIds: [],
                pattern: {
                    g: 0,
                    bs: 0,
                    ml: 0,
                    mb: 0,
                    mt: 0,
                    mr: 0,
                    mo: -1,
                    bg: {
                        y: 0,
                        d: 0,
                        a: 0,
                        p: 0,
                        r: 0,
                        s: 0,
                        c: '#fff',
                        tc: '#333',
                        f: '',
                        path: '',
                    },
                    mbg: {
                        y: 0,
                        d: 0,
                        a: 0,
                        p: 0,
                        r: 0,
                        s: 0,
                        c: '#fff',
                        tc: '#333',
                        f: '',
                        path: '',
                    },
                    b: {
                        y: 0,
                        w: 0,
                        c: '#000',
                        s: 0,
                        t: true,
                        l: true,
                        b: true,
                        r: true,
                    },
                    mb2: {
                        y: 0,
                        w: 0,
                        c: '#000',
                        s: 0,
                        t: true,
                        l: true,
                        b: true,
                        r: true,
                    },
                    m: {
                        mt: 0,
                        mb: 0,
                        ml: 0,
                        mr: 0,
                    },
                    mm: {
                        mt: 0,
                        mb: 0,
                        ml: 0,
                        mr: 0,
                        t: 0,
                    },
                    mm2: {
                        mt: 0,
                        mb: 0,
                        ml: 0,
                        mr: 0,
                        t: 0,
                    },
                    mbs: 0,
                },
            },
        ],
    },
};
let ajaxCacheCol = null;

const getColDefaultTimeOut = () => {
    const copyCol = $.extend(true, {}, colDefault);
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(copyCol);
        }, 5000);
    });
};

const getCacheCol = () =>
    new Promise((res) => {
        if (ajaxCacheCol) {
            const copyCol = $.extend(true, {}, ajaxCacheCol);
            res(copyCol);
        }
    });

const getColDefault = function (index) {
    const ajaxP = jzRequest.get('/rajax/site_h.jsp?cmd=getWafNotCk_getDefaultCols');
    const timeOutP = getColDefaultTimeOut();
    const cacheP = getCacheCol(); // 请求回来的缓存列数据

    // 请求回来的缓存列数据
    let pArr = ajaxCacheCol ? [ajaxP, timeOutP, cacheP] : [ajaxP, timeOutP];
    const raceP = Promise.race(pArr);
    return raceP.then(({ data }) => {
        const { data: cols } = data?.data ? data : $.extend(true, {}, colDefault).data;
        let copyCol = cols;

        if (jzUtils.isNumber(index)) {
            copyCol[0].index = index + 1;
        }

        ajaxCacheCol = copyCol?.[0];
        return copyCol?.[0];
    });
};

const addColEditingLayout = function (rowId) {
    Vue.nextTick(() => {
        setTimeout(() => {
            addEditingLayout(
                '#row' + rowId,
                function () {},
                function () {
                    window._store.commit('manage/editPanel/editPanelActiveChange', false);

                    logDog(200610, 9);
                }
            );
        }, 100);
    });
};

const addEditingLayout = function (el, enterFunction, outerFunction) {
    var previewBody = $('body'),
        previewBodyHeight = previewBody.height(),
        beforeLayout = $('.row_editing_before_layout'),
        afterLayout = $('.row_editing_after_layout'),
        rowDom = $(el),
        rowHeight = rowDom.outerHeight(),
        rowWidth = rowDom.outerWidth(),
        rowOffset = rowDom.offset() || {},
        heightOffset = Comm.getPageTop(),
        // previewBodyHeight不准确, 因为toolbar fixed 55px高, 所以需要加上这个偏移值
        rowPageRect = {
            height: rowHeight,
            width: rowWidth,
            left: rowOffset.left,
            top: rowOffset.top,
            right: rowOffset.left + rowWidth,
            bottom: rowOffset.top + rowHeight,
        };
    beforeLayout = beforeLayout.length ? beforeLayout : $('<div class="jz_editing_layout row_editing_layout"></div>');
    afterLayout = afterLayout.length ? afterLayout : beforeLayout.clone().removeClass('row_editing_before_layout');
    let _rowPageRectTop = rowPageRect.top;

    let headerHeight = $('.g_header_content').length ? window._store.state.header.headerStyleStatus.headerHeight : 0,
        _excludeHeight = $('#jzToolbar').height(),
        _isFirstRow = rowDom.parents('.jz_web_row_line.active_page').attr('data-first_row');

    if ($('#sitetips').length) {
        _excludeHeight += $('#sitetips').height();
    }

    if (window._store.state.fullScreen && window._store.state.device == 'pc') {
        const _id = /\d+/.exec(el);

        if (_id) {
            // FIXE ME
            let match = getRowBelong(parseInt(_id[0]));

            if (
                window._store.state.responsiveInfo.pattern.rollingScreen.topType === 1 &&
                headerHeight &&
                match.name === 'rowIds'
            ) {
                // 悬浮并且顶部高度不为0 内容区通栏
                if (_isFirstRow || window._store.state.responsiveInfo.pattern.rollingScreen.isFixedTop) {
                    // 首屏为通栏 或者 固定顶部
                    _rowPageRectTop = headerHeight;
                    beforeLayout.css('top', _excludeHeight + 1);
                }
            }
        }
    }

    beforeLayout.css('height', _rowPageRectTop).addClass('row_editing_before_layout');
    afterLayout
        .css({
            top: rowPageRect.bottom,
            height: previewBodyHeight - rowPageRect.bottom + heightOffset,
        })
        .addClass('row_editing_after_layout');
    previewBody.append(beforeLayout);
    previewBody.append(afterLayout);
    typeof enterFunction === 'function' && enterFunction();
    $('.row_editing_layout')
        .off('click.lay')
        .on('click.lay', function () {
            $('.row_editing_layout').remove();

            window._store.commit('row/rowEditingChange', null);

            typeof outerFunction === 'function' && outerFunction();
        });
};

const removeRowEditingLayout = function () {
    $('.row_editing_layout').click();
};

export { getColDefault, addColEditingLayout, removeRowEditingLayout };
