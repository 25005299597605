import { fullNavManage } from '@/manage/system/navManage/index.js';
import { refreshResAnimateModuleClassName, refreshOneResAnimation } from '../../modules/animation/index.js';
const FULL_NAV_MENU_CLASS = 'jz_full_nav_menu.full_nav_close';

const updateFullNavMenuStyle = (fullNavIconType, isPc) => {
    const $fullNavMenu = $(`.${FULL_NAV_MENU_CLASS}`);

    if (isPc) {
        if (fullNavIconType === 2) {
            $fullNavMenu.css({
                'background-color': '#fff',
                'border-radius': '50%',
                'box-shadow': '0 0px 6px rgb(0 0 0 / 10%)',
            });
        } else if (fullNavIconType === 3) {
            $fullNavMenu.css({
                'background-color': ' #4D4D4D',
                'border-radius': '2px',
                'box-shadow': '',
            });
        } else {
            $fullNavMenu.css({
                'background-color': '',
                'border-radius': '',
                'box-shadow': '',
            });
        }
    } else {
        $fullNavMenu.css({
            'background-color': '',
            'border-radius': '',
            'box-shadow': '',
        });
    }
};

const updateFullNavMenuItemStyle = (fullNavIconType, isPc) => {
    const $fullNavMenuItems = $(`.${FULL_NAV_MENU_CLASS} .jz_full_nav_menu_item`);

    if (isPc) {
        if (fullNavIconType === 0) {
            $fullNavMenuItems.css({
                'background-color': '#333',
            });
        } else if (fullNavIconType === 3) {
            $fullNavMenuItems.css({
                'background-color': '#fff',
            });
        } else {
            $fullNavMenuItems.css({
                'background-color': '',
            });
        }

        if (fullNavIconType === 2 || fullNavIconType === 1) {
            $fullNavMenuItems.addClass('jz_theme_bg_color');
        } else {
            $fullNavMenuItems.removeClass('jz_theme_bg_color');
        }
    } else {
        $fullNavMenuItems.removeClass('jz_theme_bg_color');
        $fullNavMenuItems.css({
            'background-color': '#999',
        });
    }
};

const updateFullNavExpendStyle = (expendDiretion) => {
    const $fullNav = $('.jz_full_nav_wrap');
    [0, 1, 2, 3, 4].forEach((curexpendDiretion) => {
        const curexpendDiretionClass = `jz_full_nav_wrap_expend_${curexpendDiretion}`;
        $fullNav.removeClass(curexpendDiretionClass);
    });
    $fullNav.addClass(`jz_full_nav_wrap_expend_${expendDiretion}`);
};

const updateFullNavViewLayout = (isPc) => {
    const $fullNavWrap = $('.jz_full_nav_wrap');
    const isInitFullNavRow = $fullNavWrap.children().length !== 0;

    if (!window._store.state.manageMode) {
        if (isInitFullNavRow) {
            $fullNavWrap.css({
                'background-color': '',
            });
        } else {
            $fullNavWrap.css({
                'background-color': '#fff',
            });
        }
    }

    const toolBarHeight = $('#jzToolbar').outerHeight() || 0;
    const isHideToolBar = !$('.jz_hideSet__hidden').length;
    let fixHeight = Comm.getPageTop();

    if (isPc && window._store.state.manageMode && isHideToolBar) {
        fixHeight = fixHeight - toolBarHeight;
    }

    const fullNavClass = `jz_web_row__content--fullNav`;

    if (isPc) {
        if (window._store.state.manageMode) {
            let top = fixHeight;
            $('.jz_full_nav_wrap ').css({
                top: `${top}px`,
                height: `calc(100vh - ${top}px)`,
            });
            $(`.${fullNavClass}`).css({
                'min-height': `calc(100vh - ${top}px)`,
            });
        } else {
            const top = fixHeight;
            $('.jz_full_nav_wrap ').css({
                top: `${top}px`,
                height: `calc(100vh - ${top}px)`,
            });
            $(`.${fullNavClass}`).css({
                'min-height': `calc(100vh - ${top}px)`,
            });
        }
    } else {
        if (window._store.state.manageMode) {
            const top = 0;
            fixHeight = 0;
            const height = $('.jz_preview_area').height();
            $('.jz_full_nav_wrap ').css({
                top: `${top}px`,
                height: `${height}px`,
            });
            $(`.${fullNavClass}`).css({
                'min-height': `${height - top}px`,
            });
        } else {
            const top = fixHeight;
            $('.jz_full_nav_wrap ').css({
                top: `${top}px`,
                height: `calc(100vh - ${top}px)`,
            });
            $(`.${fullNavClass}`).css({
                'min-height': `calc(100vh - ${top}px)`,
            });
        }
    }

    const $fullNavMenu = $(`.${FULL_NAV_MENU_CLASS}`);

    if (getIsMobi()) {
        let left = null;
        let top = null;
        if (window._store.state.manageMode) {
            left =
                (($('.jz_nav_menu')?.[0]?.getBoundingClientRect()?.left ?? 0) ||
                    ($('.jz_nav_menu')?.[1]?.getBoundingClientRect()?.left ?? 0)) -
                ($('.jz_preview_area')?.[0]?.getBoundingClientRect()?.left ?? 0);
            top =
                (($('.jz_nav_menu')?.[0]?.getBoundingClientRect()?.top ?? 0) ||
                    ($('.jz_nav_menu')?.[1]?.getBoundingClientRect()?.top ?? 0)) -
                ($('.jz_preview_area')?.[0]?.getBoundingClientRect()?.top ?? 0);
        } else {
            left =
                ($('.jz_nav_menu')?.[0]?.getBoundingClientRect()?.left ?? 0) ||
                ($('.jz_nav_menu')?.[1]?.getBoundingClientRect()?.left ?? 0);
            top =
                ($('.jz_nav_menu')?.[0]?.getBoundingClientRect()?.top ?? 0) ||
                ($('.jz_nav_menu')?.[1]?.getBoundingClientRect()?.top ?? 0);
        }

        $fullNavMenu.css({
            left: `${left}px`,
            top: `${top}px`,
        });
    } else {
        let left = null;
        let top = null;
        left =
            ($('#jz_nav .jz_full_nav_menu')?.[0]?.getBoundingClientRect()?.left ?? 0) ||
            ($('#jz_nav .jz_full_nav_menu')?.[1]?.getBoundingClientRect()?.left ?? 0);
        top =
            ($('#jz_nav .jz_full_nav_menu')?.[0]?.getBoundingClientRect()?.top ?? 0) ||
            ($('#jz_nav .jz_full_nav_menu')?.[1]?.getBoundingClientRect()?.top ?? 0);
        $fullNavMenu.css({
            left: `${left}px`,
            top: `${top}px`,
        });
    }

    if (getIsMobi()) {
        $('body').append(`
            <style>
                .jz_screen_mobi .jz_full_nav_wrap_expend_1.jz_full_nav_wrap_active{
                    transform: translate3d(0, calc(100vh + ${fixHeight * 2}px), 0);
                }
            </style>`);
    } else {
        $('body').append(`
            <style>
                .jz_full_nav_wrap_expend_1.jz_full_nav_wrap_active{
                    transform: translate3d(0, calc(100% + ${fixHeight * 2}px), 0);
                }
            </style>`);
    }
};

const updateFullNavViewStyle = (isPc) => {
    updateFullNavViewLayout(isPc);
};

const updateFullNavViewScroll = (isShowScroll) => {
    const $fullNav = $('.jz_full_nav_wrap');

    if (isShowScroll) {
        $fullNav.css({
            overflow: 'auto',
        });
    } else {
        $fullNav.css({
            overflow: 'hidden',
        });
    }
};

const updateFullNavStyle = () => {
    const { fullNavIconType, expendDiretionType, isPc, isShowScroll } = getFullNavData();
    updateFullNavMenuStyle(fullNavIconType, isPc);
    updateFullNavMenuItemStyle(fullNavIconType, isPc);
    updateFullNavExpendStyle(expendDiretionType);
    updateFullNavViewStyle(isPc);
    updateFullNavViewScroll(isShowScroll);
};

const getFullNavAnimationModules = () => {
    const $fullNav = $('.jz_full_nav_wrap .jz_module');
    return $fullNav.filter(function () {
        return !!$(this).attr('data-animations-id');
    });
};

const getFullNavAnimationModuleIds = () => {
    return Array.from(getFullNavAnimationModules()).map((moduleDom) => +$(moduleDom).attr('data-animations-id'));
};

const getIsMobi = () => {
    return $('html').hasClass('jz_screen_mobi');
};

const triggerFullNavBefore = () => {
    getFullNavAnimationModuleIds().forEach((id) => {
        refreshResAnimateModuleClassName(id);
    });
};

const getFullNavData = () => {
    const $fullNav = $('.jz_full_nav_wrap');
    return {
        fullNavIconType: $fullNav.data('fullNavIconType'),
        expendDiretionType: $fullNav.data('expendDiretionType'),
        isPc: !getIsMobi(),
        isShowScroll: $fullNav.data('isShowScroll'),
    };
};

const triggerFullNav = (isOpen) => {
    if (window._store.state.manageMode) {
        window._store.commit('manage/nav/setIsShowFullNav', isOpen);
    }

    const $fullNav = $('.jz_full_nav_wrap');
    const jzFullNavWrapActiveClass = 'jz_full_nav_wrap_active';
    const $fullNavMenu = $(`.${FULL_NAV_MENU_CLASS}`);
    const $body = $('body');
    updateFullNavStyle();
    const $mobiFixHeader = $('.g_mobi_header__fixed .g_mobi_header_content');
    const $jzHideSetBar = $('#jzHideSetBar');

    if (isOpen) {
        if (window._store.state.manageMode) {
            fullNavManage.enterEdit();
            window._store.commit('manage/editPanel/editPanelActiveChange', false);

            // 强制刷新一次面板
            window._store.commit('manage/funcPanel/funcPanelChange', false);
            Vue.nextTick(() => {
                window._store.commit('manage/funcPanel/funcPanelChange', {
                    type: 'module',
                    second: 'MaterialAddPanel',
                    active: true,
                    index: 1,
                });
            });

            if (!localStorage.getItem('hasCloseFullNavExitTips')) {
                window._store.commit('manage/nav/setIsShowFullNavExitTips', true);
            }
        }

        triggerFullNavBefore();
        $body.css({
            overflow: 'hidden',
        });
        $jzHideSetBar.hide();
        $mobiFixHeader.css('z-index', '999');
        $fullNavMenu.addClass('full_nav_active');
        $fullNav.addClass(jzFullNavWrapActiveClass);
    } else {
        if (window._store.state.manageMode) {
            window._store.commit('manage/editPanel/editPanelActiveChange', false);
            window._store.commit('manage/funcPanel/funcPanelChange', false);
            fullNavManage.exitEdit();
            window._store.commit('manage/nav/setIsShowFullNavExitTips', false);
        }

        $fullNavMenu.removeClass('full_nav_active');
        $fullNav.removeClass(jzFullNavWrapActiveClass);
        $mobiFixHeader.css('z-index', '');
        $jzHideSetBar.show();
        $body.css({
            overflow: '',
        });
        triggerDynamicNumberModuleEvent('reset');
    }
};

const initFullNavTransitionendEvent = () => {
    $('.jz_full_nav_wrap')
        .off('transitionend.jz_full_nav_wrap')
        .on('transitionend.jz_full_nav_wrap', function (event) {
            // 判断本身过渡结束
            if (event.target === event.currentTarget) {
                const $fullNav = $(this);
                const isEnterStatus = $fullNav.hasClass('jz_full_nav_wrap_active'); // 判断为入场过渡

                if (isEnterStatus) {
                    const animationModules = Array.from(getFullNavAnimationModules());
                    getFullNavAnimationModuleIds().forEach((id) => {
                        refreshOneResAnimation({
                            id,
                        });
                    });
                    bizShared.InterSectionObserApi.animationUnobserve(animationModules);
                    bizShared.InterSectionObserApi.initAnimationObserver(animationModules);
                    triggerDynamicNumberModuleEvent('start');
                }
            }
        });
};

const initFullNavMenuEvent = () => {
    const $fullNavMenu = $(`.${FULL_NAV_MENU_CLASS}`);
    $fullNavMenu.off('click.jz_full_nav_menu').on('click.jz_full_nav_menu', () => {
        triggerFullNav(false);
    });
};

const initFullNavEvent = () => {
    initFullNavMenuEvent();
    initFullNavTransitionendEvent();
};
/**
 *  初始化全屏导航，调用前清除所有副作用，避免多次调用产生问题
 *
 * @param {*} fullNavIconType
 * @param {*} expendDiretionType
 * @param {*}
 */

const initFullNav = (fullNavIconType, expendDiretionType, isShowScroll) => {
    updateFullNavData(fullNavIconType, expendDiretionType, isShowScroll);
    updateFullNavStyle();
    initFullNavEvent();
};

const updateFullNavData = (fullNavIconType, expendDiretionType, isShowScroll) => {
    const $fullNav = $('.jz_full_nav_wrap');
    $fullNav.data('fullNavIconType', fullNavIconType);
    $fullNav.data('expendDiretionType', expendDiretionType);
    $fullNav.data('isShowScroll', isShowScroll);
};

function getDynamicNumberModuleIds() {
    // dynamic number module style is 35
    return Array.from($('.jz_full_nav_wrap .jz_module_style_35')).map((el) =>
        parseInt(el.getAttribute('id').replace('module', ''), 10)
    );
}
/**
 * 手动触发动态数字模块的动画
 * @param {string} type start | reset
 */

function triggerDynamicNumberModuleEvent(type = '') {
    const dynamicNumberModuleIds = getDynamicNumberModuleIds();
    dynamicNumberModuleIds.forEach((moduleId) => {
        const event = new window.CustomEvent(`ModuleDynamicNumber.${type}Animation`, {
            detail: {
                moduleId,
            },
        });
        window.dispatchEvent(event);
    });
}

export { updateFullNavStyle, triggerFullNav, initFullNav, updateFullNavData };
