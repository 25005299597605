var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classes, style: _vm.colStyle, attrs: { _index: _vm.index } },
    [
      _c(
        "div",
        {
          staticClass: "web_col_content",
          class: {
            web_col_content_empty: _vm.isEmpty,
            web_col_content_rows: _vm.type == 1,
          },
          on: { click: _vm.changePanelEditCol },
        },
        [
          _vm.type == 1
            ? _vm._l(_vm.rows, function (row, index) {
                return _c("jz-web-row", {
                  key: row.id,
                  attrs: {
                    row: row,
                    index: index,
                    "hide-tool-bar": true,
                    "hide-material-line": true,
                    "clone-module-id": _vm.cloneModuleId,
                  },
                })
              })
            : [
                _vm._l(_vm.mIds, function (id, index) {
                  return _c("Project", {
                    key: _vm.projectKey(id),
                    attrs: {
                      id: id,
                      "module-index": index,
                      "clone-module-id": _vm.cloneModuleId,
                      rowid: _vm.rowid,
                    },
                  })
                }),
                _vm._v(" "),
                _vm.manageMode && _vm.isEmpty && !_vm.isMobi && _vm.designAuth
                  ? _c("div", { staticClass: "web_col_content_empty_tips" }, [
                      _c(
                        "div",
                        {
                          staticClass: "web_col_content_empty_tips_btn",
                          on: { click: _vm.addModule },
                        },
                        [
                          _c("i", {
                            staticClass: "web_col_content_empty_tips_icon",
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "web_col_content_empty_tips_text" },
                            [_vm._v("添加模块")]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.canAddMaterial
                        ? _c(
                            "div",
                            {
                              staticClass: "web_col_content_material_add_btn",
                              on: { click: _vm.addMaterial },
                            },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "web_col_content_material_add_icon",
                                  attrs: { "aria-hidden": "true" },
                                },
                                [
                                  _c("use", {
                                    attrs: {
                                      "xlink:href": "#icon_material_add",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "web_col_content_empty_tips_text",
                                },
                                [_vm._v("添加素材")]
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "web_col_content_empty_tips_label" },
                        [_vm._v("可拖动模块进来")]
                      ),
                    ])
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm._v(" "),
      _vm.manageMode
        ? _c("features", { attrs: { inner: "", features: _vm.features } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }