var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "languages languages_dropdown",
      class: { mobi: _vm.isMobi, no_text: !_vm.textVisible },
    },
    [
      !_vm.isMobi || (_vm.isMobi && !_vm.topPosition)
        ? _c(
            "span",
            _vm._g(
              { staticClass: "languages_default_item" },
              _vm.dropdownActiveEvents
            ),
            [
              _vm.iconVisible
                ? _c("i", {
                    staticClass: "s_multi_language_icon",
                    class: "icon_" + _vm.currentLan.lanCode,
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.textVisible
                ? _c(
                    "span",
                    {
                      staticClass:
                        "s_multi_language_text s_multi_language_active_text",
                      style: _vm.createFontStyle(true),
                    },
                    [_vm._v(_vm._s(_vm.currentLan.name))]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.topPosition
                ? _c("svg", { staticClass: "s_multi_language_dropdown_svg" }, [
                    _c("use", { attrs: { "xlink:href": "#icon_more" } }),
                  ])
                : _vm._e(),
            ]
          )
        : _vm.topPosition
        ? _c(
            "svg",
            {
              staticClass: "s_multi_language_mobi_svg",
              style: { color: _vm.mobiIconColor },
              on: { click: _vm.toggleDropdownPanel },
            },
            [_c("use", { attrs: { "xlink:href": "#icon_language" } })]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        _vm._g(
          {
            ref: "languagesDropdownPanel",
            staticClass: "languages_dropdown_panel",
            class: { top: _vm.areaType === 0, bottom: _vm.areaType === 1 },
          },
          _vm.dropdownEvents
        ),
        [
          _c("div", { staticClass: "dropdown_panel_triangle" }),
          _vm._v(" "),
          _vm._l(_vm.languages, function (item) {
            return _c(
              "li",
              {
                key: item.lanCode,
                staticClass: "language_item languages_dropdown_item",
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "language_item_wrapper",
                    style:
                      item.lcid === _vm.currentLan.lcid
                        ? _vm.createFontStyle(true)
                        : _vm.createFontStyle(),
                    attrs: { hidefocus: "true", href: item.url },
                  },
                  [
                    _vm.iconVisible
                      ? _c("i", {
                          staticClass: "s_multi_language_icon",
                          class: "icon_" + item.lanCode,
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.textVisible
                      ? _c(
                          "span",
                          {
                            staticClass:
                              "s_multi_language_text s_multi_language_normal_text",
                            class: {
                              jz_theme_font_color:
                                item.lcid === _vm.currentLan.lcid,
                            },
                            style:
                              item.lcid === _vm.currentLan.lcid
                                ? _vm.createFontStyle(true)
                                : _vm.createFontStyle(),
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(item.name) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }