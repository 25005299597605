var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "search_result_filedownload" }, [
    _vm.other.showResultType
      ? _c("div", { staticClass: "search_result_title jz_theme_font_color" }, [
          _vm._v(_vm._s(_vm.LS.searchResultFile)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "file_download_container",
        class: _vm.fileDownloadStyleClass,
      },
      _vm._l(_vm.renderFileList, function (item) {
        return _c("div", { key: item.id, staticClass: "file_item_wrap" }, [
          _c("div", { staticClass: "file_item" }, [
            !_vm.noPicStyle
              ? _c("svg", { style: _vm.svgStyle }, [
                  _c("use", { attrs: { "xlink:href": item.svg } }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "file_item_message" }, [
              _c(
                "div",
                {
                  staticClass:
                    "file_item_title result_highlight_node jz_hover jz_theme_font_hover_color",
                },
                [
                  _vm._v(
                    "\n                        " +
                      _vm._s(_vm.decodeHtml(item.fileName)) +
                      "\n                    "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "file_item_size" }, [
                _vm._v(_vm._s(item.size)),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "file_item_download",
                attrs: { "data-url": item.path },
              },
              [
                _vm.noPicStyle
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.fileHref(item),
                          target: "_blank",
                          rel: "nofollow",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDownload(item)
                          },
                        },
                      },
                      [_vm._v("下载")]
                    )
                  : _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.fileHref(item),
                          target: "_blank",
                          rel: "nofollow",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.handleDownload(item)
                          },
                        },
                      },
                      [
                        _c(
                          "svg",
                          {
                            attrs: {
                              width: "16px",
                              height: "16px",
                              fill: "#7E858C",
                            },
                          },
                          [
                            _c("use", {
                              attrs: { "xlink:href": "#icon_download" },
                            }),
                          ]
                        ),
                      ]
                    ),
              ]
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }