var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    {
      staticClass: "form_item_wrap",
      class: _vm.itemClasses,
      style: _vm.itemStyle,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "hovermenu",
              rawName: "v-hovermenu",
              value: {
                hovermenu: _vm.hovermenu,
                args: {
                  moduleId: _vm.module.id,
                  formId: _vm.form.id,
                  itemId: _vm.item.id,
                  hideToolBar: _vm.item.hideToolBar,
                },
              },
              expression:
                "{\n            hovermenu,\n            args: {\n                moduleId: module.id,\n                formId: form.id,\n                itemId: item.id,\n                hideToolBar: item.hideToolBar,\n            },\n        }",
            },
          ],
          ref: "formItem",
          staticClass: "form_item",
          on: {
            contextmenu: _vm.contextmenuHandler,
            click: _vm.chosenFormItem,
            dblclick: _vm.dblClickHandler,
          },
        },
        [
          _vm.item.type !== 5
            ? _c("h3", { staticClass: "title", style: _vm.titleStyle }, [
                _c(
                  "span",
                  { staticClass: "title_text", style: _vm.titleTextStyle },
                  [_vm._v(_vm._s(_vm.item.name))]
                ),
                _vm.item.must
                  ? _c("span", { staticClass: "required_icon" }, [_vm._v("*")])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _vm.manageMode && !_vm.isLinkToForm
            ? _c(
                "tooltip-component",
                {
                  ref: "tooltipResizeHandler",
                  attrs: {
                    value: _vm.resizeForTip,
                    manual: "",
                    "visible-arrow": false,
                    placement: "bottom-start",
                    effect: "flower_white",
                  },
                },
                [
                  _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                    _vm._v("宽：" + _vm._s(_vm.widthForTip)),
                    _c("br"),
                    _vm._v("高：" + _vm._s(_vm.heightForTip)),
                  ]),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "resize_handler",
                    on: {
                      mousedown: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.resizeHandlerMouseDown.apply(null, arguments)
                      },
                    },
                  }),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }