var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.curCompon, {
    directives: [
      {
        name: "contextmenu",
        rawName: "v-contextmenu",
        value: _vm.contextmenus,
        expression: "contextmenus",
      },
    ],
    ref: "jzProject",
    tag: "component",
    staticClass: "jz_project",
    class: _vm.wrapClasses,
    style: _vm.wrapMarginStyle,
    attrs: {
      id: _vm.id,
      "module-index": _vm.moduleIndex,
      rowid: _vm.rowid,
      projectid: _vm.moduleId,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }