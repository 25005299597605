import request from '@jz/request';

export function getProductGroupList(data) {
    return request
        .post('/rajax/productGroup_h.jsp?cmd=getWafNotCk_getList&fullName=true', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getCommentInfo(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductComment', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function submitComment(data) {
    return request.post('/ajax/productComment_h.jsp?cmd=addWafCk_submitPC', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getProductListByPage(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductListByPage', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getProductResultByPagination(params) {
    return request
        .get('/rajax/productModule_h.jsp?cmd=getWafNotCk_getProductResult', {
            params,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getPropNameByLib(data) {
    return request
        .post('/rajax/productModule_h.jsp?cmd=getWafNotCk_getScreenProduct', {
            data,
        })
        .then(({ data = {} }) => {
            return data;
        });
}

export function delProduct(data = {}) {
    return request.post('/ajax/product_h.jsp?cmd=delWafCk_del', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function setProductList(data = {}) {
    return request.post('/rajax/productModule_h.jsp?cmd=setWafCk_setProductList', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getProductNativePayUrl(data = {}) {
    return request.post('/ajax/product_h.jsp?cmd=getWafNotCk_getNativePayUrl', { data }).then(({ data = {} }) => {
        return data;
    });
}

export function getKnowProductOrderList4member(params = {}) {
    return request
        .get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductOrderList4member', { params })
        .then(({ data = {} }) => {
            return data;
        });
}

export function checkProductPayStatus(data = {}) {
    return request
        .post('/ajax/product_h.jsp?cmd=getWafNotCk_checkKnowProductOrderPayStatus', { data })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getKnowProductImageText(params = {}) {
    return request
        .get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductImageText', { params })
        .then(({ data = {} }) => {
            return data;
        });
}

export function getKnowProductVideo(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductVideo', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function genKnowProductOrder(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=setWafCk_genKnowProductOrder', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getKnowProductAudio(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductAudio', { params }).then(({ data = {} }) => {
        return data;
    });
}

export function getKnowProductList(params = {}) {
    return request.get('/ajax/product_h.jsp?cmd=getWafNotCk_getKnowProductList', { params }).then(({ data = {} }) => {
        return data;
    });
}
