import { getBannerData as getBannerDataRequest } from '@api/banner';
import { delRow } from '@/manage/grid/row/rowManage/index.js';
import { deepExtendClone } from '@jz/utils';

const editBanner = function () {
    window._store.dispatch('manage/pauseDataWatch');
    window._store.commit('manage/editPanel/editPanelChange', {
        type: 'banner',
        active: true,
    });
};
/**
 * @function name -  getBannerData
 * @description 获取横幅数据
 * @kind Root
 * @param flag {Boolean}  是否开启独立横幅
 */
export const getBannerData = function (flag) {
    let independent = window._store.state.responsiveInfo.pattern.independent;
    let colId = 0;
    let extId = 0;

    if (flag) {
        colId = window._store.state.colId;
        extId = independent.self ? independent.selfId : 0;
    } else {
        colId = 11;
        extId = 0;
    }

    getBannerDataRequest({
        _colId: colId,
        _extId: extId,
    })
        .then((result) => {
            if (result.success) {
                var _Banner = result.banner;
                _temporary.banner = _Banner; //在切换前要先把上一次添加的临时行数据删掉
                const info = window._store.state.responsiveInfo;
                //在切换前要先把上一次添加的临时行数据删掉
                for (const { prid: rowId } of info.banner.bl) {
                    if (rowId >= 50 && rowId < 100) {
                        delRow(rowId);
                    }
                }

                window._store.state.manage.props.independentSetting.pageBanner = flag;
                const newBannerData = deepExtendClone(info.banner, flag ? _Banner.pageBanner : _Banner.templateBanner);
                if (
                    info.pattern.independent.self &&
                    info.pattern.independent.selfBanner &&
                    info.pattern.independent.open
                ) {
                    info.pattern.independent.banner = newBannerData;
                } else {
                    info.banner = newBannerData;
                }
                const { pageBannerRowList = [], pageBannerModuleList = [] } = _Banner;
                pageBannerRowList.forEach((r) => {
                    if (!window._store.state.rows[`row${r.id}`]) {
                        Vue.set(window._store.state.rows, `row${r.id}`, r);
                    }
                });
                pageBannerModuleList.forEach((m) => {
                    if (!window._store.state.modules[`module${m.id}`]) {
                        Vue.set(window._store.state.modules, `module${m.id}`, m);
                    }
                });
            }
        })
        .catch((err) => {
            console.log(err);
        });
};
export { editBanner };
