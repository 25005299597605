import { store } from '@/store/store.js';
import { browser } from '@jz/utils';
/**
 * detectZoomDialog
 */
let detectZoomDiaLog = () => {
    return;
};
export class DetectZoom {
    constructor() {
        this.body = $('body');
        this.type = this.checkType();
        this.detectZoomFunc = {
            firefox: () => {
                return window.devicePixelRatio ? window.devicePixelRatio : undefined;
            },
            chrome: () => {
                if (window.devicePixelRatio) return window.devicePixelRatio;
                return undefined;
            },
        };
        this.timer = null;
        this.init();
    }
    init() {
        this.keydown();
        this.resize();
        this.setRatio();
    }
    checkType() {
        if (window.devicePixelRatio && !browser.isSafari()) {
            return 'devicePixelRatio';
        } else {
            return 'detectZoom';
        }
    }
    diaLog() {
        detectZoomDiaLog();
    }
    setRatio() {
        const isShowDetectZoomTips = $.cookie(`showDetectZoomTips_${store.state.aid}_${store.state.siteId}`);
        const isScale = this.checkZoomScale();
        setTimeout(() => {
            this.body.attr('zoom', !isScale ? '' : 'scale');
            !isShowDetectZoomTips && isScale && this.diaLog();
        }, 50);
    }
    // 因为鼠标滚轮和按键操作都是频繁触发的，应该降低触发处理函数的频率
    debounce(time, func) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            func.call(this);
        }, time);
    }
    checkZoomScale() {
        let zoom = this.detectZoom();
        let sys = this.system();
        if (zoom === undefined) {
            return false;
        }
        return ('win' == sys && 1 != zoom) || ('mac' == sys && zoom % 1 != 0 && zoom % 2 != 0) || false;
    }
    detectZoom() {
        return this.detectZoomFunc[this.getBrowser()] ? this.detectZoomFunc[this.getBrowser()]() : undefined;
    }
    getBrowser() {
        let ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('firefox') >= 0
            ? 'firefox'
            : ua.indexOf('chrome') >= 0
            ? 'chrome'
            : ua.indexOf('opera') >= 0
            ? 'opera'
            : ua.indexOf('safari') >= 0
            ? 'safari'
            : void 0;
    }
    system() {
        let ua = navigator.userAgent.toLowerCase();
        return ua.indexOf('win') >= 0 ? 'win' : ua.indexOf('mac') >= 0 ? 'mac' : false;
    }
    resize() {
        $(window).on('resize.detectZoom', () => {
            this.debounce(200, this.setRatio);
        });
    }
    keydown() {
        $(document).on('keydown.detectZoom', (e) => {
            if (e.ctrlKey) {
                switch (e.which) {
                    case 48: /*0*/
                    case 96: /*0*/
                    case 187: /*+*/
                    case 189:
                        this.debounce(200, this.setRatio);
                        break;
                }
            }
        });
    }
}
