/* eslint-disable no-inner-declarations */
import { Comm } from '../../utils';
const { extend, getComputedTextColor } = Comm;
import {
    clueReuqestVm,
    Clue,
    getMultiDropDownLevel,
    getMultiDropDownInfo,
    getMultiDropDownLevelData,
} from '@jz/biz-shared';
import { Monitor, MonitorDef } from '@/monitor/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

// 提交表单线索统计
const logClueSubmitForm = (clueContentList, form = {}) => {
    let sourceModule = null;
    switch (window._store.state.colId) {
        case 2:
            sourceModule = Clue.clueKey?.INDEX;
            break;
        case 12:
            sourceModule = Clue.clueKey?.PRODUCT_RESULT;
            break;
        default:
            break;
    }
    clueReuqestVm?.changeSourceContentValue(Clue.clueKey?.SUBMIT_FORM, {
        expand_content: {
            infoExtend: clueContentList,
        },
    });
    clueReuqestVm?.changeSourceContentDataId(Clue.clueKey?.SUBMIT_FORM, form?.id ?? 0);
    if (sourceModule) {
        clueReuqestVm?.logClueSourceContent(sourceModule, Clue.clueKey?.SUBMIT_FORM);
    } else {
        Clue?.logClueSubmitFormSourceContent();
    }
};

function submitForm(
    module,
    form,
    contentList,
    openValidateCode,
    validateCode,
    options = {
        unfinishedPriceRule: false,
        isAgree: true,
        formProtocolTitle: '',
    }
) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async function (resolve, reject) {
        const { unfinishedPriceRule, isAgree, formProtocolTitle, actionLoader, beforeSubmit } = options;
        // 业务监控
        const endMonitor = Monitor.startMonitor(MonitorDef.MonitorId.R_FORM_SUBMIT);
        let submitFileList = [];
        const submitContentList = [];
        const phoneValidateCodes = [];
        const clueContentList = [];

        for (const item of contentList) {
            const submitItem = {
                id: item.id,
                type: item.type,
                must: item.must,
                val: item.value,
            };
            const clueItem = {
                type: 1,
                value: item.value,
                key: item.name,
            };
            // 规则隐藏的表单项不校验
            if (item.hideListByRule && item.hideListByRule.length == 0) {
                continue;
            }
            //这里是验证代码
            switch (item.type) {
                case 0: //单行文本
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        var wordLimit = {};
                        try {
                            wordLimit = $.parseJSON(item.wordLimit);
                        } catch (e) {
                            console.log(e);
                        }
                        var minLength = 0,
                            maxLength = 100;
                        if (typeof wordLimit !== 'undefined' && wordLimit != null && parseInt(wordLimit.o) == 1) {
                            minLength = wordLimit.i;
                            maxLength = wordLimit.a;
                        }
                        if (val.length > maxLength) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputMaxLength, Fai.encodeHtml(item.name), maxLength)
                            );
                        }
                        if (item.must && val.length < minLength) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputMinLength, Fai.encodeHtml(item.name), minLength)
                            );
                        }
                        submitItem.val = val || '';
                        clueItem.value = val || '';
                    }
                    break;
                case 1: //多行文本
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        if (val.length > 1000) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputMaxLength, Fai.encodeHtml(item.name), 1000)
                            );
                        }
                        submitItem.val = val || '';
                        clueItem.value = val || '';
                    }
                    break;
                case 5: //文本说明
                    break;
                case 9: //邮箱验证
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        if (val && !Fai.isEmail(val)) {
                            return createValidError(
                                Fai.format(LS.memberSignupUserAddItemCorrect, Fai.encodeHtml(item.name))
                            );
                        }
                        submitItem.val = val || '';
                        clueItem.value = val || '';
                    }
                    break;
                case 10: //身份验证
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }
                        if (val && !Fai.isCardNo(val)) {
                            return createValidError(
                                Fai.format(LS.memberSignupUserAddItemCorrect, Fai.encodeHtml(item.name))
                            );
                        }
                        submitItem.val = val || '';
                        clueItem.value = val || '';
                    }
                    break;
                case 7: //文件上传
                    {
                        const file = item.value || {};

                        if (
                            item.must &&
                            ((Array.isArray(file) && !file.length) || (!Array.isArray(file) && !file.fileId))
                        ) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitCheckHasFileUpload, Fai.encodeHtml(item.name))
                            );
                        }
                        if (Array.isArray(file)) {
                            if (file.length) {
                                submitFileList = submitFileList.concat(file);
                                const fileIdList = [],
                                    fileNameList = [];
                                file.forEach((fileItem) => {
                                    const { fileId, tmpFileName } = fileItem;
                                    fileIdList.push(fileId);
                                    fileNameList.push(tmpFileName);
                                });
                                submitItem.val = $.toJSON(fileIdList);
                                clueItem.value = fileNameList || '';
                            } else {
                                submitItem.val = '';
                                clueItem.value = '';
                            }
                        } else {
                            if (file.id) {
                                submitFileList.push({
                                    fileId: file.fileId,
                                    tmpFileId: file.id,
                                    tmpFileName: file.name,
                                    fileHeight: file.height || 0,
                                    fileWidth: file.width || 0,
                                    fileType: file.type || 0,
                                });
                                submitItem.val = file.fileId;
                                clueItem.value = file.name || '';
                            } else {
                                submitItem.val = '';
                                clueItem.value = '';
                            }
                        }
                    }
                    break;
                case 2: //单选按钮
                    {
                        const val = $.trim(item.value);

                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        submitItem.val = val;
                        clueItem.value = val || '';
                    }
                    break;
                case 3: //多选按钮
                    {
                        const val = item.value || [];

                        if (item.must && val.length == 0) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        submitItem.val = val.join('\n');
                        clueItem.value = val.join('\n') || '';
                    }
                    break;
                case 4: //下拉选项
                    {
                        const val = $.trim(item.value);

                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        submitItem.val = val;
                        clueItem.value = val || '';
                    }
                    break;
                case 11: //省市县区
                    {
                        const val = item.value || {};
                        const province = val.province || {};
                        const city = val.city || {};
                        const county = val.county || {};

                        if (item.must) {
                            //必填时完整性到市
                            if (!province.name || !city.name) {
                                return createValidError(
                                    Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                                );
                            }
                        }

                        submitItem.val = (province.name || '') + (city.name || '') + (county.name || '');
                        clueItem.value = (province.name || '') + (city.name || '') + (county.name || '') || '';
                    }
                    break;
                case 12: //多级下拉
                    {
                        const level = getMultiDropDownLevel(item.input);
                        const dropDownInfo = getMultiDropDownInfo(item.input);
                        const { firstLevel, secondLevel, thirdLevel, fourthLevel } = item.value || {};
                        let result = '';
                        result = firstLevel?.val ?? '';
                        if (item.must) {
                            if (!firstLevel?.val) {
                                return createValidError(
                                    Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                                );
                            }
                        }
                        !!secondLevel?.val && (result += `-${secondLevel?.val}`);
                        if (item.must && level > 1) {
                            const secondLevelInfo = getMultiDropDownLevelData(dropDownInfo, 1, firstLevel?.val);
                            if (secondLevelInfo?.length && !secondLevel?.val) {
                                return createValidError(
                                    Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                                );
                            }
                        }
                        !!thirdLevel?.val && (result += `-${thirdLevel?.val}`);

                        if (item.must && level > 2) {
                            const thirdLevelInfo = getMultiDropDownLevelData(dropDownInfo, 2, secondLevel?.val);
                            if (thirdLevelInfo?.length && !thirdLevel?.val) {
                                return createValidError(
                                    Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                                );
                            }
                        }
                        !!fourthLevel?.val && (result += `-${fourthLevel?.val}`);
                        if (item.mus && level > 3) {
                            const fourthLevelInfo = getMultiDropDownLevelData(dropDownInfo, 3, thirdLevel?.val);
                            if (fourthLevelInfo?.length && !fourthLevel?.val) {
                                return createValidError(
                                    Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                                );
                            }
                        }

                        submitItem.val = result;
                        clueItem.value = result || '';
                    }
                    break;
                case 8: //手机号码
                    {
                        const val = item.value || {};
                        const areaCode = (val.areaCode || {}).ctName || '';
                        const phoneNumber = val.phoneNumber || '';
                        const phoneNumberLength = (item.phoneNumberLength || '7-11').split('-');
                        const minLength = phoneNumberLength[0];
                        const maxLength = phoneNumberLength[1] || minLength;
                        const phoneValidateCode = val.phoneValidateCode;
                        const smsImgVerifyCurr = item.smsVerify && module.allowedFormSms && item.smsImgVerify;

                        if (item.must && !phoneNumber) {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        if (phoneNumber) {
                            if (!Fai.isLimitedRangeNationMobile(phoneNumber, minLength, maxLength)) {
                                return createValidError(Fai.format(LS.site_newMobileNumRegular, phoneNumberLength));
                            }
                        }
                        if (item.must && smsImgVerifyCurr && !phoneValidateCode) {
                            return createValidError(LS.msgBoardInputValidateCode);
                        }

                        submitItem.val = areaCode + phoneNumber;
                        clueItem.value = areaCode + phoneNumber || '';
                        if (phoneValidateCode) {
                            phoneValidateCodes.push({
                                id: item.id,
                                phoneValidateCode,
                            });
                        }
                    }
                    break;
                case 6:
                    {
                        //日期选项
                        const val = item.value || {};
                        const dateSetting = item.dateSetting || {};
                        const dateType = dateSetting.type || 0;
                        const startTime = val.startTime || '';
                        const endTime = val.endTime || '';

                        if (item.must && startTime == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        submitItem.val = startTime;
                        clueItem.value = startTime || '';

                        if (dateType == 1) {
                            if (item.must && endTime == '') {
                                return createValidError(
                                    Fai.format(LS.siteFormSubmitCheckIsEmpty, Fai.encodeHtml(item.name))
                                );
                            }

                            submitItem.val += ` 至 ${endTime}`;
                            clueItem.value += ` 至 ${endTime}` || '';
                        }
                    }
                    break;
                case 13: //单行文本
                    {
                        const val = $.trim(item.value);
                        if (item.must && val == '') {
                            return createValidError(
                                Fai.format(LS.siteFormSubmitInputIsEmpty, Fai.encodeHtml(item.name))
                            );
                        }

                        submitItem.val = val || '';
                        clueItem.value = val || '';
                    }
                    break;
            }

            submitContentList.push(submitItem);
            clueContentList.push(clueItem);
            function createValidError(msg) {
                return createSubmitError({
                    msg,
                    itemId: item.id,
                });
            }
        }

        if (unfinishedPriceRule) {
            return createSubmitError(LS.paymentAmountCalcError || '支付金额计算规则有误，请联系管理员');
        }

        // 开启校验验证码的
        if (openValidateCode && validateCode == '') {
            return createSubmitError(Fai.format(LS.siteFormSubmitInputIsEmpty, LS.verificationCode));
        }

        if (!isAgree) {
            return createSubmitError(`${LS.read_and_agree}${formProtocolTitle}`);
        }

        if (module.isReCommit) {
            return;
        }
        let pageUrl = document.location.href;
        if (pageUrl.length > 500) {
            pageUrl = pageUrl.substring(0, 500);
        }
        let pageName = window._store.state.pageName;
        if (pageName.endsWith(' - ')) {
            pageName = pageName.substring(0, pageName.lastIndexOf(' - '));
        }
        let submitOrigin = {
            pageUrl,
            pageName,
        };
        if (typeof beforeSubmit === 'function') {
            const beforeSuccess = await beforeSubmit();
            if (!beforeSuccess) return;
        }

        const params = {
            formId: form.id,
            submitContentList: $.toJSON(submitContentList),
            vCodeId: `${module.id}.${form.id}`,
            validateCode,
            tmpFileList: $.toJSON(submitFileList),
            submitOrigin: $.toJSON(submitOrigin),
            phoneValidateCodes: $.toJSON(phoneValidateCodes),
        };

        const [action, data] = actionLoader(params);

        module.isReCommit = true;
        action(data)
            .then((result) => {
                module.isReCommit = false;
                if (result.success) {
                    logClueSubmitForm(clueContentList, form);
                    resolve(result);
                    endMonitor();
                } else {
                    if (result.hasFW) {
                        return createSubmitError(result.msg);
                    } else if (result.needCode) {
                        return createSubmitError({
                            msg: result.msg,
                            errCode: 'needCode',
                        });
                    } else if (result.rt == -2) {
                        let uniqueName = '';
                        if (typeof result.id !== 'undefined') {
                            const uniqueItem = contentList.find((item) => item.id == result.id);
                            if (uniqueItem && uniqueItem.name) {
                                uniqueName = `${uniqueItem.name}：`;
                            }
                        }
                        return createSubmitError(`${uniqueName}${result.msg || '已存在该数据'}`);
                    } else if (result.rt == -4) {
                        return createSubmitError(LS.siteFormSubmitCountLimit);
                    } else if (result.rt == -7) {
                        return createSubmitError(LS.siteImgFull);
                    } else if (result.rt == -8) {
                        return createSubmitError(result.msg);
                    } else if (result.rt == -20) {
                        return createSubmitError(result.msg);
                    } else if (result.rt == -401) {
                        return createSubmitError({
                            msg: LS.siteFormSubmitValidateCodeErr,
                            errCode: -401,
                        });
                    } else if (result.rt == -302) {
                        return createSubmitError(LS.siteFormSubmitNotLogin + LS.login + LS.period);
                    } else if (result.rt == -28) {
                        return createSubmitError({
                            msg: LS.hasSubmitThisSiteForm,
                            errCode: -28,
                        });
                    } else {
                        endMonitor(false, JSON.stringify(result));
                        return createSubmitError(result.msg || LS.siteFormSubmitError);
                    }
                }
            })
            .catch((err) => {
                endMonitor(false, err);
                module.isReCommit = false;
                return createSubmitError(Fai.format(LS.siteFormSubmitError));
            });

        function createSubmitError(msg) {
            if (typeof msg === 'string') {
                return reject({ msg });
            }
            return reject(msg);
        }
    });
}

function genCommFontStyleForText(fontStyle, style = {}, { customStyleKey = 2 } = {}) {
    if (fontStyle.y === customStyleKey) {
        style['font-size'] = fontStyle.s ? `${fontStyle.s}px` : '';
        style['font-family'] = fontStyle.f;
        style['font-style'] = fontStyle.i == 1 ? 'italic' : 'normal';
        style['font-weight'] = fontStyle.w == 1 ? 'bold' : 'normal';
        style['text-decoration'] = fontStyle.d == 1 ? 'underline' : 'none';
        extend(style, getComputedTextColor(fontStyle.c));
    }
    return style;
}

function genCommFontStyle(fontStyle, style = {}) {
    if (fontStyle.y === 1) {
        style['display'] = 'none';
    } else if (fontStyle.y === 2) {
        style['text-align'] = ['left', 'center', 'right'][fontStyle.ta];
    }
    return style;
}

function addGetParamToUrl(url, param = {}) {
    let paramStr = '';
    Object.keys(param).forEach((key) => {
        paramStr += `&${key}=${param[key]}`;
    });
    if (!url.includes('?')) {
        paramStr = paramStr.replace('&', '?');
    }
    const hashFlag = url.indexOf('#');
    if (hashFlag > -1) url = url.slice(0, hashFlag);
    return url + paramStr;
}

function delHrefParam(paramKey) {
    var url = window.location.href; //页面url
    var urlParam = window.location.search.substr(1); //页面参数
    var newQuery = '';
    var arr = new Array();

    if (urlParam != '') {
        var urlParamArr = urlParam.split('&'); //将参数按照&符分成数组

        for (var i = 0; i < urlParamArr.length; i++) {
            var paramArr = urlParamArr[i].split('='); //将参数键，值拆开
            //如果键要删除的不一致，则加入到参数中
            if (!paramKey.includes(paramArr[0])) {
                arr.push(urlParamArr[i]);
            }
        }
    }
    if (arr.length > 0) {
        newQuery = '?' + arr.join('&');
    }
    url = window.location.origin + window.location.pathname + newQuery + window.location.hash;
    return url;
}

export { submitForm, genCommFontStyleForText, genCommFontStyle, addGetParamToUrl, delHrefParam };
