import {
    getGroupNavList,
    delPhotoGroup as delPhotoGroupRequest,
    getPhotoGroupAllList as getPhotoGroupAllListRequest,
} from '@/api/photo/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { addNewModule } from '@shared/manage/module/index.js';
import { DataLazyload } from '@shared/manage/dataLazyload/index.js';
import { getAjaxUrl } from '@/site/shared/index.js';
import { logDog } from '@/site/shared/log/index.js';
import { context, memorize, isBrowserEnv } from '@jz/utils';
const photoGroupModuleAdd = function (name) {
    addNewModule(
        {
            style: 15,
            name: name,
            privatePattern: JSON.stringify({
                pc: {
                    ppc: 0,
                },
                mobi: {
                    ppc: 0,
                },
            }),
        },
        {
            isDragadd: false,
        }
    );
};
if (!context._photoGroupMap) {
    context._photoGroupMap = new Map();
}
const photoGroupMap = context._photoGroupMap;
const getPhotoGroupSortList = function func(forceRefresh) {
    let photoGroupSortListInstance = photoGroupMap.get('photoGroupSort');
    if (photoGroupSortListInstance?.promise && !forceRefresh) {
        return photoGroupSortListInstance.promise; //只能初始化一次,否则需要强制刷新
    }
    !photoGroupSortListInstance?.promise && (photoGroupSortListInstance = {});
    if (!photoGroupSortListInstance.data) photoGroupSortListInstance.data = [];
    photoGroupSortListInstance.promise = new Promise(function (resolve, reject) {
        //重新ajax更新分类数据重写当前页面的分类
        getGroupNavList()
            .then((result) => {
                var list = result.data; //添加 未分类

                //添加 未分类
                list.unshift({
                    id: 0,
                    name: '未分类',
                }); //清空数组并把新的值加进去
                //注意这里不是直接重新赋值，因为这会导致引用改变
                //然后其他获取了的地方跟这次获取的数据就会不一样了

                //清空数组并把新的值加进去
                //注意这里不是直接重新赋值，因为这会导致引用改变
                //然后其他获取了的地方跟这次获取的数据就会不一样了
                photoGroupSortListInstance.data.length = 0;
                photoGroupSortListInstance.data.splice(0, 0, ...list);
                resolve(photoGroupSortListInstance.data);
            })
            .catch((err) => {
                Message.warning({
                    message: '网络繁忙，请刷新后重试',
                    autoHide: false,
                });
                reject(err);
            });
    });
    photoGroupMap.set('photoGroupSort', photoGroupSortListInstance);
    return photoGroupSortListInstance;
}; //获取全量图册列表，无缓存(仅有ID与NAME等简单数据，无封面图，图片列表等数据)
//不与图册列表保持更新，仅在每次点击添加图册回调后刷新数据

const photoGroupList = memorize(() => {
    let photoGroupInstance = photoGroupMap.get('photoGroup');
    if (photoGroupInstance) {
        return photoGroupInstance; //只能初始化一次,否则需要强制刷新
    }
    photoGroupInstance = new (class extends DataLazyload {
        constructor() {
            const path = isBrowserEnv()
                ? Site.addRequestPrefix({
                      newPath: '/rajax/photoGroup_h.jsp?cmd=getWafNotCk_getList',
                      oldPath: getAjaxUrl('rajax/photoGroup_h.jsp?cmd=getWafNotCk_getList'),
                  })
                : '';
            super(path);
        } //生成请求数据

        //生成请求数据
        genData(key, loadList) {
            const data = {};
            data['useGroupSort'] = key === 'groupId';

            if (data['useGroupSort']) {
                data['sortIdList'] = JSON.stringify(loadList);
            } else {
                data['groupIdList'] = JSON.stringify(loadList);
            }

            return data;
        } //处理响应结果

        //处理响应结果
        processResult(data, resolve, reject) {
            if (data.success) {
                const list = data.list;
                resolve(list);
            } else {
                this.errMsg(data.msg);
                reject(data);
            }
        }
    })();
    photoGroupMap.set('photoGroup', photoGroupInstance);
    return photoGroupInstance;
})();

/**
 * @function name - delPhotoGroupMenuItem
 * @description 删除某个图册目录模块中选中的某个图册
 * @kind Root
 * @param [moduleId =0]{number} 模块id
 * @param [photoGroupId =0]{number} 图册id
 */
const delPhotoGroupMenuItem = (moduleId = 0, photoGroupId = 0) => {
    const module = window._store.state.modules['module' + moduleId];

    if (typeof module !== 'object') {
        Message.warning('该模块不存在！');
        return;
    }

    if (module.style !== 15) {
        Message.warning('该模块不是图册目录模块！');
        return;
    }

    logDog(200762, 8);
    new window.Vue().$createDialog({
        content: '确定将该图册放入回收站吗？',
        confirmButton: {
            text: '确 定',
        },
        cancelButton: {
            text: '取 消',
        },

        onConfirm() {
            const { prop1 = [] } = module; // sideEffects

            // sideEffects
            delPhotoGroup(photoGroupId).then(() => {
                // 删除操作不进入撤销堆栈
                window._store.dispatch('manage/pauseDataWatch');
                window.Comm.remove(prop1, photoGroupId);
            });
        },
    });
};

/**
 * @function name - delPhotoGroup
 * @kind Root
 * @description 删除某个图册
 * @param [photoGroupId =0]{number} 图册id
 */
const delPhotoGroup = (photoGroupId = 0) => {
    return new Promise((resolve, reject) => {
        Message.warning('正在删除……');
        delPhotoGroupRequest({
            id: photoGroupId,
        })
            .then((data) => {
                if (data.success) {
                    Message.warning('删除成功');
                    resolve();
                } else {
                    Message.warning(data.msg);
                    reject();
                }
            })
            .catch(() => {
                Message.warning('系统繁忙，请稍候重试');
                reject();
            });
    });
};

/**
 * @function name - editPhotoGroup
 * @kind Root
 * @description 编辑某个图册
 * @param [photoGroupId =0]{number} 图册id
 */
const editPhotoGroup = (photoGroupId = 0) => {
    logDog(200762, 7);
    Fai.popupWindow({
        title: '编辑图册',
        frameSrcUrl: 'manage_v2/photoGroupEdit.jsp?id=' + photoGroupId,
        width: 860,
        height: 682,
        saveBeforePopup: false,

        closeFunc() {},

        beforePopup: true,
        version: 2,
    });
};

/**
 * @function name - selectPhotoGroupMenuItem
 * @kind Root
 * @description 选择某个图册目录模块中的图册
 * @param [moduleId =0] {number} 模块id
 */
const selectPhotoGroupMenuItem = (moduleId = 0) => {
    Fai.popupWindow({
        title: '直接添加',
        frameSrcUrl:
            'rmanage/selectPhotoGroup.jsp?id=' +
            moduleId +
            '&colId=' +
            window._store.state.colId +
            '&extId=' +
            window._store.state.extId +
            '&ram=' +
            Math.random(),
        width: '684',
        height: '537',
        saveBeforePopup: false,
        version: 2,
    });
};
/**
 * @function name - addPhotoGroup
 * @kind Root
 * @description 添加图册
 * @returns {Promise}
 */
const addPhotoGroup = (groupId = 0) => {
    return new Promise((resolve, reject) => {
        Fai.popupWindow({
            title: '添加图册',
            frameSrcUrl: `manage_v2/photoGroupEdit.jsp?groupNavId=${groupId}`,
            width: 860,
            height: 682,
            saveBeforePopup: false,

            closeFunc(result) {
                if (!result) {
                    reject();
                    return;
                }

                const item = $.parseJSON(result);
                photoGroupList.load([item.id]);
                resolve(item);
            },

            version: 2,
        });
    }).catch(() => {});
};

/**
 * 管理图册分类
 */
const managePhotoGroupSort = function () {
    Fai.popupWindowVersionTwo.createPopupWindow({
        title: '管理图册分类',
        frameSrcUrl: 'manage_v2/photoGroupNavEdit.jsp',
        closeFunc: () => {
            //强制刷新图册分类数据
            getPhotoGroupSortList(true);
        },
        width: '612',
        height: '580',
        saveBeforePopup: false,
        version: 2,
    });
};
//获取全量图册列表，无缓存(仅有ID与NAME等简单数据，无封面图，图片列表等数据)
//不与图册列表保持更新，仅在每次点击添加图册回调后刷新数据
const getPhotoGroupAllList = function func() {
    return new Promise(function (resolve, reject) {
        getPhotoGroupAllListRequest()
            .then((result) => {
                resolve(result.list);
            })
            .catch((err) => {
                Message.warning({
                    message: '网络繁忙，请刷新后重试',
                    autoHide: false,
                });
                reject(err);
            });
    });
};

export {
    getPhotoGroupAllList,
    photoGroupModuleAdd,
    getPhotoGroupSortList,
    photoGroupList,
    editPhotoGroup,
    delPhotoGroup,
    delPhotoGroupMenuItem,
    selectPhotoGroupMenuItem,
    addPhotoGroup,
    managePhotoGroupSort,
};
