/* eslint-disable no-redeclare */
/* eslint-disable no-use-before-define */
/* eslint-disable no-cond-assign */
/* eslint-disable guard-for-in */
/* eslint-disable no-undef */
/* eslint-disable no-self-compare */
import { getModuleTargetName } from '@/components/modules/shared/constant.js';
Comm = window.Comm || (window.Comm = {});

(function () {
    Comm.getModuleDefaultName = (Style) => getModuleTargetName({ id: Style, targetKey: 'moduleCnName' });

    /*
     * 操作多级对象属性,返回一个vue计算属性,用于操作vue多级对象属性
     * Comm.operMultiLevelObjectAttr("module.flag", getFunc, setFunc)
     * operAttr 需要操作的多级属性 如"module.flag"
     * getFunc 获取数据时执行的函数 在函数中this为操作的对象，第一个参数key为操作的属性（如flag）
     * setFunc 设置数据时执行的函数 在函数中this为操作的对象，第一个参数key为操作的属性（如flag），第二个参数newVal为需要设置的新值
     */
    Comm.operMultiLevelObjectAttr = function (operAttr, getFunc, setFunc) {
        function operObjAttr(func) {
            return function () {
                let key = '';
                let operObj = this;
                let attrs = operAttr.split('.');
                key = attrs.pop();
                for (const attr of attrs) {
                    operObj = operObj[attr];
                }
                return func.call(operObj, key, ...arguments, this);
            };
        }

        return {
            get: operObjAttr(getFunc),
            set: operObjAttr(setFunc),
        };
    };

    //设置flag某位的值
    Comm.setBitMemory = function (flag, bit, val) {
        if (val) {
            flag |= bit;
        } else {
            flag &= ~bit;
        }
        return flag;
    };

    //获取flag某位的值
    Comm.getBitMemory = function (flag, bit) {
        return (flag & bit) == bit;
    };

    /*
     * 绑定flag列表到vue的计算属性中，可读写
     *  computed:{
     *    ...Comm.mapFlag({
     *      useFontIcon: 0x1,
     *      otherFlag: ...
     *    }),
     *    otherAttr:...
     *  }
     * flagKey参数同Comm.bindFlag方法的flagKey参数, 默认使用this.module.flag
     */
    Comm.mapFlag = function (flagDefs, flagKey) {
        const flagComputeds = {};
        for (const [key, bit] of Object.entries(flagDefs)) {
            flagComputeds[key] = Comm.bindFlag(bit, flagKey);
        }
        return flagComputeds;
    };

    /*
     * 绑定flag项到vue的计算属性中，可读写
     * computed:{ useFontIcon: Comm.bindFlag(0x1), otherAttr:... }
     * flagKey不传默认使用this.module.flag作为flag, 传"moduleConf.flag1"为this.moduleConf.flag1
     */
    Comm.bindFlag = function (bit, flagKey = 'module.flag') {
        return Comm.operMultiLevelObjectAttr(
            flagKey,
            function (key) {
                //get
                return Comm.getBitMemory(this[key], bit);
            },
            function (key, newVal) {
                //set
                this[key] = Comm.setBitMemory(this[key], bit, newVal);
            }
        );
    };

    const responseDataKeyArray = [
        'margin.mt',
        'margin.mb',
        'pattern.pt',
        'pattern.pb',
        'pattern.mt',
        'pattern.mb',
        'pattern.m.mt',
        'pattern.m.mb',
        'pattern.mm.mt',
        'pattern.mm.mb',
        'pattern.mm2.mt',
        'pattern.mm2.mb',
        'rowPattern.pt',
        'rowPattern.pb',
        'colPattern.mt',
        'colPattern.mb',
        'colPattern.m.mt',
        'colPattern.m.mb',
        'colPattern.mm.mt',
        'colPattern.mm.mb',
        'colPattern.mm2.mt',
        'colPattern.mm2.mb',
    ];
    /**
     * 获取是否开启新得换算逻辑
     * @param responseDataKey {String}  vue key 值
     * @param isSysRow {Boolean}  是否是系统行
     * @param rowNewResponsiveConversion {Boolean} pc 是否是已经开启新逻辑的行
     * @param rowNewMobiResponsiveConversion {Boolean} mobi 是否是已经开启新逻辑的行
     * @return {Boolean}
     */

    const getIsNewExchanger = (
        responseDataKey,
        isSysRow,
        rowNewResponsiveConversion,
        rowNewMobiResponsiveConversion
    ) => {
        const isMobi = Comm.getDevice() === 'mobi';
        let _newResponsiveConversion = isMobi
            ? window._store.state.newMobiResponsiveConversion || rowNewMobiResponsiveConversion
            : window._store.state.newResponsiveConversion || rowNewResponsiveConversion;
        let _templateNewResponsiveConversion = isMobi
            ? window._store.state.templateNewMobiResponsiveConversion
            : window._store.state.templateNewResponsiveConversion;
        _newResponsiveConversion = _newResponsiveConversion || (isSysRow && _templateNewResponsiveConversion);
        return _newResponsiveConversion && responseDataKeyArray.includes(responseDataKey);
    };

    /**
     *
     * @function name - responseDataExchanger
     * @kind Comm
     * @description 响应式数据交换器
     *  用于将响应式数据以固定数据形式挂载到计算属性中,在固定数据与响应式数据间实现双向自动转换绑定
     * computed:{ widthPixel: Comm.responseDataExchanger(function(){return document.getElementById(`module${ this.module.id }`).clientWidth;}, "width"), otherAttr:... }
     *
     * @param getRelativeVal {Function}  获取相对计算的值
     * 比如模块宽度100px，响应式数据为0.1，如果这时要值为10px，这里应该返回模块宽度100，返回的是当前相对的固定值 100*0.1=10，这里面的this或第一个属性是实例的this
     * @param responseDataKey {String}  绑定的响应式数据的key  响应式数据的key，比如实际响应宽度数据的key为width，这里要设置转换width的固定数据，则传入"width"，支持多级写法如"module.width.val"
     * @returns {Object} Vue计算属性 { set:val=>..., get:()=>val }
     *
     */

    Comm.responseDataExchanger = function (getRelativeVal, responseDataKey) {
        return Comm.operMultiLevelObjectAttr(
            responseDataKey,
            function (key, vm) {
                //get
                const { isSysRow, rowNewResponsiveConversion, rowNewMobiResponsiveConversion } = vm;
                const isNewExchanger = getIsNewExchanger(
                    responseDataKey,
                    isSysRow,
                    rowNewResponsiveConversion,
                    rowNewMobiResponsiveConversion
                );
                const isMobi = Comm.getDevice() === 'mobi';
                let _outerWidth = isMobi ? 375 : Math.min(window.innerWidth, window.outerWidth);
                if (isNewExchanger) {
                    return Math.round(this[key] * _outerWidth);
                } else {
                    const relativeVal = isMobi
                        ? Math.min(375, getRelativeVal.call(vm, vm))
                        : getRelativeVal.call(vm, vm);
                    return Math.round(this[key] * relativeVal);
                }
            },
            function (key, newVal, vm) {
                //set
                const { isSysRow, rowNewResponsiveConversion, rowNewMobiResponsiveConversion } = vm;
                const isMobi = Comm.getDevice() === 'mobi';
                const isNewExchanger = getIsNewExchanger(
                    responseDataKey,
                    isSysRow,
                    rowNewResponsiveConversion,
                    rowNewMobiResponsiveConversion
                );
                let _outerWidth = isMobi ? 375 : Math.min(window.innerWidth, window.outerWidth);
                if (typeof this[key] === 'undefined') return;
                if (isNewExchanger) {
                    this[key] = newVal / _outerWidth;
                } else {
                    const relativeVal = isMobi
                        ? Math.min(375, getRelativeVal.call(vm, vm))
                        : getRelativeVal.call(vm, vm);
                    this[key] = newVal / relativeVal;
                }
            }
        );
    };

    Comm.getElemId = function (elem, replaceStr = '', isNum = true) {
        return isNum
            ? parseInt($(elem).attr('id').replace(replaceStr, ''))
            : $(elem).attr('id').replace(replaceStr, '');
    };
    Comm.matchInArray = function (arr, key, match) {
        let matchIndex = -1,
            matchItem = undefined;
        matchItem = arr.find(function (e, i) {
            if (e[key] === match) {
                matchIndex = i;
                return e;
            }
        });
        return { match: matchItem, index: matchIndex };
    };

    Comm.extend = function (to, _from) {
        for (var key in _from) {
            to[key] = _from[key];
        }
        return to;
    };
    /**
     * 过去真正表现数据 根据mobi端的是默认时取pc端的数据，如果手机端不是默认数据时，就取手机端数据
     *
     */

    Comm.getDisplayData = function (device, pc, mobi, defkey = 'y', defVal = 0) {
        //对象的话就要对比默认属性
        if ($.isPlainObject(pc)) {
            return device == 'mobi' && mobi[defkey] != defVal ? mobi : pc;
        } else {
            //基本类型的话就直接对比
            return device == 'mobi' && mobi != defVal ? mobi : pc;
        }
    };

    /**
     * 获取节点的自由高度
     * @param {Node} node
     * @returns {String} 高度值
     */

    Comm.getNodeAutoHeight = (node) => {
        if (!(node instanceof Node)) {
            return '0';
        }
        const oldHeight = node.style.height;
        node.style.height = 'auto';
        const autoHeight = window.getComputedStyle(node).height;
        node.style.height = oldHeight;
        return autoHeight;
    };

    Comm.setThemeStyle = function (value, secondaryColor) {
        // FIXME: 没必要加上jz_hover
        $('#themeControl').html('').html(`
        :root {
			--theme-color:${value};
		}
		.jz_theme_font_color{color:${value};}
		.jz_theme_bg_color{background-color:${value};}
		.jz_theme_border_color{border-color:${value};}
		.jz_theme_fill_color{fill:${value};}
		.jz_theme_border_bottom_color{border-bottom-color:${value};}
		.jz_theme_border_top_color{border-top-color:${value};}
		.jz_hover.jz_theme_font_hover_color:hover{color:${value};}
		.jz_hover.jz_theme_bg_hover_color:hover{background-color:${value};}
		.jz_hover.jz_theme_border_hover_color:hover{border-color:${value};}
		.jz_focus.jz_theme_focus_border_color:focus{border-color:${value};}
		.jz_theme_focus_border_color:focus{border-color:${value};}
		.jz_theme_hover_font_color:hover{color:${value};}
		.jz_secondary_bg_color{background-color:${secondaryColor || '#f28830'};}
		.pagination_style2 .pagination_item.active::after{border-color:${value}!important;}
		.pagination_style3 .pagination_item.active,
		.pagination_style2 .pagination_item.active{color:${value}!important;}
		.pagination_style3 .pagination_item.active::after,
		.pagination_style2 .pagination_item.active::before,
		.full_screen_row .pagination_style1 .pagination_item.active::after{
			background-color:${value}!important;
		}
        .jz_screen_pc .submit_btn_style_1:hover .form_submit, .jz_screen_pc .submit_btn_style_3:hover .form_submit{
			color: ${value};
			border-color: ${value};
		}
		.submit_btn_style_4 .form_submit, .submit_btn_style_5 .form_submit{ 
			color: ${value};
			border-color: ${value};
		}
		.submit_btn_style_0 .form_submit,.submit_btn_style_1 .form_submit,.submit_btn_style_2 .form_submit {
			background-color: ${value};
		}
		.jz_screen_pc .submit_btn_style_4:hover .form_submit {
			background-color: ${value};
		}
		.jz_screen_pc .submit_btn_style_2:hover .form_submit {
			background-color: ${secondaryColor};
		}
	`);
    };

    Comm.isSectionId = function (id) {
        return id >= 150 && id < 200;
    };
    Comm.isTemporaryModuleId = function (id) {
        return id >= 200 && id < 300;
    };
    Comm.isTemporaryRowId = function (id) {
        return id >= 50 && id < 100;
    };

    /**
     * 删除数组中的某个项
     * @param {Array} array
     * @param {any} item
     * @returns {Array} 包含被删除的项的数组
     */

    Comm.remove = function (array, item) {
        var index = array.indexOf(item);
        return index > -1 ? array.splice(index, 1) : [];
    };

    Comm.getDevice = function () {
        return window._store.state.device;
    };
    Comm.getState = function () {
        return window._store.state;
    };
    Comm.getElemOffsetRect = function (el) {
        var offset = $(el).offset(),
            width = $(el).outerWidth(),
            height = $(el).outerHeight();
        return {
            ...offset,
            width,
            height,
            right: offset.left + width,
            bottom: offset.top + height,
        };
    };

    Comm.getPageTop = function () {
        if (window._store.state.manageMode) {
            if (Comm.getDevice() == 'pc') {
                return $('#jzToolbar')[0].getBoundingClientRect().bottom;
            } else {
                return $('#jzPreviewContent').offset().top;
            }
        } else {
            if ($('#sitetips').length && $('#sitetips').css('position') != 'fixed') {
                return $('#sitetips').height();
            } else {
                return 0;
            }
        }
    };

    Comm.transformSize = function (size) {
        if (!size) {
            return '-';
        }
        let result = size / 1024 / 1024;
        let unit = Number.parseInt(result) > 0 ? 'MB' : 'KB';
        if (unit == 'MB') {
            if (result % 1 > 0) {
                result =
                    Number.parseInt((result % 1) * 10) > 0 ? result.toFixed(1) + unit : Number.parseInt(result) + unit;
            } else {
                Number.parseInt(result) + unit;
            }
        } else {
            result *= 1024;
            if (result % 1 > 0) {
                result =
                    Number.parseInt((result % 1) * 10) > 0 ? result.toFixed(1) + unit : Number.parseInt(result) + unit;
            } else {
                Number.parseInt(result) + unit;
            }
        }
        return result;
    };

    Comm.openAutoPush = function () {
        var html =
            '(function(){' +
            "    var bp = document.createElement('script');" +
            "    var curProtocol = window.location.protocol.split(':')[0];" +
            "    if (curProtocol === 'https') {" +
            // eslint-disable-next-line @jz/no-use-http-prefix
            "        bp.src = 'https://zz.bdstatic.com/linksubmit/push.js';" +
            '    }' +
            '    else {' +
            // eslint-disable-next-line @jz/no-use-http-prefix
            "        bp.src = 'http://push.zhanzhang.baidu.com/push.js';" +
            '    }' +
            '    var s = document.getElementsByTagName("script")[0];' +
            '    s.parentNode.insertBefore(bp, s);' +
            '})();';
        return html;
    };
})(Comm);
