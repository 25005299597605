import _Monitor from './Monitor';
import _MonitorDef from './MonitorDef';

export const Monitor = _Monitor;
export const MonitorDef = _MonitorDef;

export function injectMonitorInWindow() {
    // 在 Function 原型上挂载 bindMonitor 方法方便调用
    Function.prototype.bindMonitor = function (monitorId) {
        return Monitor.monitorDecorator(this)(monitorId);
    };
}
