var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.multiLanguageVisible
    ? _c(
        "div",
        { staticClass: "s_multi_language_wrap" },
        [
          _c("multiLanguage", {
            staticClass: "J_project_sort_handle",
            class: _vm.multiLanguageClass,
            attrs: {
              id: "s_multi_language",
              "is-core": true,
              list: _vm.renderTextList,
              "current-lan": _vm.activeLanguage,
              "render-type": _vm.renderType,
              "render-style": _vm.renderStyle,
              "render-area": _vm.renderArea,
              "font-setting": _vm.fontSetting,
            },
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "multi_Language_mobi_panel" } }),
          _vm._v(" "),
          _vm.manageMode
            ? _c("features", {
                attrs: { inner: "", features: _vm.innerFeatures },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }