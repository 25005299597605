import { getProductVideoRqueset } from '@api/flv/index.js';
//获取产品视频
const getProductVideo = function (moduleId, productId) {
    getProductVideoRqueset({
        moduleId: moduleId,
        productId: productId,
    }).then((result) => {
        var html = "<div class='videoBg'></div>";
        $('.detail_inner_wrap').prepend(html);
        $('.videoBg').append(result); // mp4切换重新初始化 销毁之前的，重新实例化

        // mp4切换重新初始化 销毁之前的，重新实例化
        var videojsDom = Fai.top.videojs('#new_video_' + moduleId);

        if (!Fai.top.$('#new_video_' + moduleId + '_html5_api').length) {
            videojsDom.dispose();
        }

        Fai.top.videojs('#new_video_' + moduleId).ready(function () {
            var myPlayer = this;
            myPlayer.controlBar.volumeMenuButton.hide();
            myPlayer.on('play', function () {
                $('.vjs-big-play-button').fadeOut();
            }); //暂停--播放完毕后也会暂停

            //暂停--播放完毕后也会暂停
            myPlayer.on('pause', function () {
                $('.vjs-big-play-button').fadeIn();
            });
        });
    });
}; //退出产品视频

export { getProductVideo }; //video 模块懒加载
