import { Message } from '@shared/manage/componMessage/index.js';
/**
 * @description 交换数组内部的元素位置
 * @function name - swapArray
 * @kind Root
 * @param arr {Array} 交换的数组
 * @param x {Number} 交换的位置
 * @param y {Number} 交换的位置
 */
const swapArray = function (arr, x, y) {
    arr.splice(x, 1, ...arr.splice(y, 1, arr[x]));
    return arr;
};

/**
 * @description 关掉所有面板 退出所有hover编辑
 * @function name - exitAllEdit
 * @kind Root
 */
const exitAllEdit = function () {
    window._store.dispatch('manage/pauseDataWatch');
    window._store.commit('manage/funcPanel/funcPanelChange', {
        active: false,
    });

    window._store.commit('manage/editPanel/editPanelChange', {});

    Root.vm.hoverToolbar.splice(0, Root.vm.hoverToolbar.length);

    window._store.commit('row/rowEditingChange', null);

    $('.jz_editing_layout').remove();
};
const URIParam = {
    //从URI中获取参数
    get _param() {
        return this.hash.parse(location.hash);
    },

    //把参数设置到URI中
    set _param(param) {
        history.replaceState(history.state, null, this.hash.stringify(param));
    },

    hash: {
        stringify(param) {
            let uriParam = '';

            for (const [key, val] of Object.entries(param)) {
                uriParam += `&${key}`;
                if (val && val !== 'null') uriParam += `=${val}`;
            } // location.hash = uriParam.slice(1);

            // location.hash = uriParam.slice(1);
            uriParam = uriParam.slice(1);
            if (!uriParam) return '';
            return '#' + uriParam;
        },

        parse(hash) {
            const uriParams = decodeURI(hash).slice(1).split('&');
            const params = {};
            uriParams.forEach((param) => {
                const [key, value = 'null'] = param.split('=');
                if (!key) return;
                params[key] = value;
            });
            return params;
        },
    },

    //遍历
    each(paramProcess) {
        for (const [key, val] of Object.entries(this._param)) {
            paramProcess(key, val);
        }
    },

    //获取全部
    getAll() {
        return this._param;
    },

    //获取参数
    get(key) {
        return this._param[key];
    },

    //设置参数
    set(key, val) {
        this._param = Object.assign(this._param, {
            [key]: val,
        });
    },
};

/**
 * @function name - saveRefreshTips
 * @description 保存刷新提示
 * @kind Root
 */
const saveRefreshTips = function () {
    Message.warning(
        "该设置项需要 <a style='color:#ff1d1d;cursor:pointer;text-decoration:underline;' onclick='Root.saveAll({callback: function(){location.reload();}})'>保存并刷新</a> 才能生效",
        true
    );
};

//刷新编辑模块遮罩层，只有在已有遮罩层的时候有效

const fixRegSpecialCharacter = (cls) => {
    var specialCharacter = ['\\', '.', '?', '$', '*', '^', '[', ']', '{', '}', '|', '(', ')', '/'];
    let resultCls = '';
    Array.from(cls).forEach((item) => {
        resultCls += specialCharacter.indexOf(item) != -1 ? '\\' + item : item;
    });
    return resultCls;
};

/*
 * 处理 style 标签上的数据
 * 数据格式
 * options: {
 *  id: '', // style 标签id
 *  css: [],// 下面格式
 * }
 * [{
 *  cls: '',    类名
 *  key: '',    属性名
 *  value: ''   属性值
 * }]
 */
const updateCssStyleById = (options) => {
    let { id, css = [] } = options;
    let doc = window.document;
    let style = $(`#${id}`);
    let list = new Array();

    if (style.length == 1) {
        // ie6下会把没换行的也自动加了换行，例如#id .xxx{\n\tDISPLAY: none\n}
        let html = style.html();
        html = html.replace(/{\r\n/g, '{').replace(/\t/g, '').replace(/\r\n}/g, ';}');
        list = html.split('\n');
        style.remove();
    }

    for (let i = list.length - 1; i >= 0; --i) {
        let line = list[i];

        for (let n = 0; n < css.length; ++n) {
            let cls = css[n].cls;
            let key = css[n].key;
            let reg = new RegExp(fixRegSpecialCharacter(cls) + ' *{ *' + key + '[^;]*;', 'gi');

            if (line.length == 0 || /^\s$/.test(line) || reg.test(line)) {
                list.splice(i, 1);
                break;
            }
        }
    }

    for (let n = 0; n < css.length; ++n) {
        if (css[n].value != '') {
            list.push(css[n].cls + '{' + css[n].key + ':' + css[n].value + ';}');
        }
    }

    $(doc.querySelector('head')).append('<style type="text/css" id="' + id + '">' + list.join('\n') + '</style>');
};

const getComputedTextColor = function (color) {
    const textColorStyle = {};
    if (color) {
        if (color.indexOf('linear-gradient') > -1) {
            textColorStyle.backgroundImage = color;
            textColorStyle.color = 'transparent';
            textColorStyle['-webkit-text-fill-color'] = 'transparent';
            textColorStyle['-webkit-background-clip'] = 'text';
        } else {
            textColorStyle.backgroundImage = '';
            textColorStyle.color = color;
            textColorStyle['-webkit-text-fill-color'] = '';
            textColorStyle['-webkit-background-clip'] = '';
        }
    }
    return textColorStyle;
};

const getComputedBg = function (bg) {
    let bgStyle = {},
        repeatDict = ['repeat', 'no-repeat', 'repeat-x', 'repeat-y'],
        positionDict = [
            'top left',
            'top center',
            'top right',
            'center left',
            'center center',
            'center right',
            'bottom left',
            'bottom center',
            'bottom right',
        ],
        sizeDict = ['auto', '100% 100%', '100%', 'cover', 'contain'];

    if (bg.y === 0) {
        bgStyle.background = '';
    } else if (bg.y == 1) {
        bgStyle.background = 'none';
    } else if (bg.y == 2) {
        if (bg.d == 0) {
            //纯色
            bgStyle.backgroundColor = bg.c;
            bgStyle.backgroundImage = 'none';
        } else if (bg.d == 1) {
            //渐变色
            bgStyle.backgroundImage = '-webkit-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bgStyle.backgroundImage = '-o-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bgStyle.backgroundImage = '-moz-linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
            bgStyle.backgroundImage = 'linear-gradient(' + bg.a + 'deg, ' + bg.c + ', ' + bg.tc + ')';
        } else if (bg.d == 2) {
            if (bg.f) {
                bgStyle.backgroundSize = sizeDict[bg.s];
                bgStyle.backgroundImage = 'url(' + bg.path + ')';
                bgStyle.backgroundColor = bg.c;
                if (bg.s == 4) {
                    bgStyle.backgroundRepeat = 'no-repeat';
                } else if (bg.s == 3) {
                    bgStyle.backgroundRepeat = 'no-repeat';
                } else if (bg.s == 2) {
                    bgStyle.backgroundRepeat = 'no-repeat';
                } else {
                    bgStyle.backgroundRepeat = repeatDict[bg.r];
                }
                if (bg.s == 4) {
                    bgStyle.backgroundPosition = 'center';
                } else if (bg.s == 3) {
                    bgStyle.backgroundPosition = 'center';
                } else {
                    bgStyle.backgroundPosition = positionDict[bg.p];
                }
            }
        } else if (bg.d == 3) {
            // image
            if (bg.f) {
                bgStyle.backgroundImage = 'url(' + bg.path + ')';
            }

            if (bg.tc) {
                bgStyle.backgroundImage =
                    (bgStyle.backgroundImage ? bgStyle.backgroundImage + ',' : '') +
                    'linear-gradient(' +
                    bg.a +
                    'deg, ' +
                    bg.c +
                    ' ' +
                    bg.cp +
                    '%, ' +
                    bg.tc +
                    ' ' +
                    bg.tcp +
                    '%)';
            } // color

            if (!bg.tc) {
                bgStyle.backgroundColor = bg.c;
            } // size

            if (!bg.f && !bg.tc) {
                bgStyle.backgroundImage = 'none';
            }

            bgStyle.backgroundSize = sizeDict[bg.s]; // repeat

            if (bg.s == 4) {
                bgStyle.backgroundRepeat = repeatDict[1];
            } else if (bg.s == 3) {
                bgStyle.backgroundRepeat = repeatDict[1];
            } else if (bg.s == 2) {
                bgStyle.backgroundRepeat = repeatDict[1];
            } else {
                bgStyle.backgroundRepeat = repeatDict[bg.r];
            } // position

            if (bg.s == 4) {
                bgStyle.backgroundPosition = positionDict[4];
            } else if (bg.s == 3) {
                bgStyle.backgroundPosition = positionDict[4];
            } else if (bg.s == 1) {
                bgStyle.backgroundPosition = positionDict[3];
            } else {
                bgStyle.backgroundPosition = positionDict[bg.p];
            }
        }
    }

    return bgStyle;
};

export { getComputedTextColor, swapArray, exitAllEdit, URIParam, saveRefreshTips, updateCssStyleById, getComputedBg };
