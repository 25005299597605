import { slideshowView } from '@shared/visitor/slideshow/index.js';
import { Message } from '@shared/manage/componMessage/index.js';
import { jzJumpConverter } from '@/manage/shared/jumpLink/index.js';
import { logJZFdp } from '@/site/shared/log/index.js';
import { checkAnchor } from '@/api/col/index.js';
import { saveMemberMsgFile } from '@/api/member/index.js';
import { resetJumpToAnchor } from './initSectionObserver';
export { openSiteForm } from '@shared/visitor/form/index.js';
export * from '@/site/shared/cookie/index.js';
import { gotoFullScreenRowId } from '@/components/grid/row/pureFullScreen/index.js';
import { checkElement, observeAnimate, getPageScrollTarget } from '@/site/shared/index.js';
import { _voidVm } from '@/site/shared/vueEventBus/index.js';
import { successMessage, warningMessage, createDialog } from '@/site/shared/dialog/index.js';
import { context as globalContext } from '@jz/utils';
const LS = globalContext.LS || {};

const defaultFileTypes = [
    {
        id: 0,
        name: '图片类',
        list: [
            {
                type: 0,
                name: 'JPG',
            },
            {
                type: 1,
                name: 'JPEG',
            },
            {
                type: 2,
                name: 'PNG',
            },
            {
                type: 3,
                name: 'PDF',
            },
            {
                type: 4,
                name: 'GIF',
            },
        ],
    },
    {
        id: 1,
        name: '文档类',
        list: [
            {
                type: 5,
                name: 'DOCX',
            },
            {
                type: 6,
                name: 'TXT',
            },
            {
                type: 7,
                name: 'HTML',
            },
            {
                type: 8,
                name: 'PPTX',
            },
            {
                type: 9,
                name: 'RAR',
            },
        ],
    },
    {
        id: 2,
        name: '音频类',
        list: [
            {
                type: 10,
                name: 'MP3',
            },
            {
                type: 11,
                name: 'MP4',
            },
            {
                type: 12,
                name: 'WAV',
            },
            {
                type: 13,
                name: 'WMA',
            },
            {
                type: 14,
                name: 'FLAC',
            },
        ],
    },
];

const getPreviewTop = function () {
    if ($('#jzPreviewWrap').length < 1) {
        return 0;
    }

    return $('#jzPreviewWrap').offset().top;
};

const jumpToPage = function (moduleId, totalPage, jsp) {
    var toPage = parseInt(
            $('#module' + moduleId + '')
                .find('#jumpPageInput' + moduleId)
                .val()
        ),
        href = '';
    if (isNaN(toPage)) {
        toPage = 1;
    }
    if (toPage > totalPage) {
        toPage = parseInt(totalPage);
    }
    if (toPage == 0) {
        toPage = 1;
    }
    href = '' + jsp + '?m' + moduleId + 'pageno=' + toPage + '#module' + moduleId + '';
    if (window._store.state.manageMode) {
        jzJumpConverter(href);
        return;
    }

    window.location.href = '' + jsp + '?m' + moduleId + 'pageno=' + toPage + '#module' + moduleId + '';
};

/**
 * 跳转锚点
 */
const jumpToAnchor = (colId, position, colUrl, skipCheckAnchorExist) => {
    let posArr = position.split('_'),
        isRow,
        isModule,
        id,
        typeStr,
        tabTypeStr,
        $scrollDom,
        tabId,
        isTab,
        posObj = {};

    if (posArr.length <= 1) {
        return;
    }
    [typeStr, id, tabTypeStr, tabId] = posArr;
    isRow = typeStr.indexOf('row') !== -1;
    isModule = typeStr.indexOf('module') !== -1;
    isTab = tabTypeStr == 'tab';
    if (isRow) {
        posObj.row = id;
    }
    if (isModule) {
        posObj.module = id;
    }
    if (isTab) {
        posObj.tab = tabId;
    }

    return new Promise((resolve, reject) => {
        if (window._store.state.colId != colId) {
            // 跳转的是其他页面的锚点
            let lan = Fai.getLanCode(),
                hash = document.location.hash;

            checkAnchorIsExit(colId, posObj, function () {
                lan = lan == '' ? '' : '/' + lan;
                hash += (hash ? '&' : '#') + 'anchor=' + position;
                if (jumpToAnchor.fromColId) {
                    hash += '&fromColId=' + jumpToAnchor.fromColId;
                }
                document.location.href = lan + colUrl + hash;
            });
            return;
        }

        if (isRow) {
            $scrollDom = $('#row' + id);
        }
        if (isModule) {
            $scrollDom = $('#module' + id);
        }
        function thisPageCallback() {
            const fullScreen = window._store.state.responsiveInfo.pattern.rollingScreen;
            if (!fullScreen.type) {
                window._store.commit('scrollAnchor/pause', true);
            }

            if (
                $(
                    '.jz_nav_layout0.jz_nav_wrap_active, .jz_nav_layout1.jz_nav_wrap_active, .jz_nav_layout2.jz_nav_wrap_active'
                ).length
            ) {
                // 收起导航
                $('.jz_nav_menu').trigger('click');
            }

            if ($('.jz_full_nav_menu.full_nav_close').length) {
                $('.jz_full_nav_menu.full_nav_close').trigger('click');
            }
            if (window._store.state.manageMode) {
                // 退出导航自由排版编辑态
                _voidVm.$emit('exit_editFreeLayout');
            }
            Vue.nextTick(() => {
                setTimeout(() => {
                    const isMobi = Comm.getDevice() == 'mobi';
                    if (fullScreen.type && !(isMobi && !fullScreen.isMobiSync)) {
                        gotoFullScreenRowId(getRowColParentRow(id));
                        return;
                    }
                    let scrollTop = $scrollDom.offset().top;

                    if (window._store.state.manageMode && $('.jz_root_mobi').length) {
                        // 手机下管理态可以无视topbar
                        scrollTop -= $('.jz_manage_web_mobi').offset().top;
                    }

                    //取消定点跳转动画，因为会触发动画过程中的图片懒加载，导致定位错误
                    $('html, body, #jzPreviewContent').scrollTop(scrollTop - fixScrollTop());

                    resetJumpToAnchor('', { colId, position, colUrl });
                }, 200);
            });
        }

        // 跳过检查锚点是否存在
        if (skipCheckAnchorExist) {
            thisPageCallback();
            return;
        }

        if ($scrollDom.length) {
            // 当前页面
            // 需要检查锚点是否被删除

            if (window._store.state.manageMode) {
                if (checkManageAnchorIsExit(colId, posObj)) {
                    // 锚点存在
                    thisPageCallback();
                    return resolve();
                } else {
                    tipForNoExitAnchor();
                    return reject();
                }
            }

            checkAnchorIsExit(colId, posObj, thisPageCallback, {
                resolve,
                reject,
            });
        } else {
            // 跳转本页锚点，需要判断模块是否存在
            tipForNoExitAnchor();
            return reject();
        }
    });
};

/*
 ** 检查锚点是否存在
 ** retrun true: 存在
 */
function checkManageAnchorIsExit(colId, posObj) {
    var isThisPage = colId == window._store.state.colId,
        isExit = false;

    if (isThisPage && window._store.state.manageMode) {
        // 当前页，可以直接通过前端数据查看锚点模块/通栏是否被取消
        var rowData, moduleData;
        if (posObj.row) {
            rowData = window._store.state.rows['row' + posObj.row];
            !!rowData && (isExit = rowData.organ.at == 1);
        } else if (posObj.module && !posObj.tab) {
            moduleData = window._store.state.modules['module' + posObj.module];
            !!moduleData && (isExit = moduleData.commProp.an.t == 1);
        } else if (posObj.tab) {
            moduleData = window._store.state.modules['module' + posObj.module];
            isExit = moduleData?.blob0?.tabs.some((item) => item.id == posObj.tab);
        }
    }
    return isExit;
}

// 检查访客态本页or非本页的锚点
function checkAnchorIsExit(colId, posObj, callback, promiseStatusOption = {}) {
    var isThisPage = colId == window._store.state.colId,
        id;

    if (posObj.row) {
        id = posObj.row;
    } else if (posObj.module) {
        id = posObj.module;
    }

    if ((isThisPage && window._store.state.manageMode) || posObj.tab) {
        callback && callback();
        promiseStatusOption.resolve && promiseStatusOption.resolve();
    } else {
        // 访客态下的本页栏目，或者是其他页栏目的，就需要通过请求去验证
        checkAnchor({
            id,
            isRow: posObj.row ? true : false,
            colId,
            isThisPage,
        })
            .then((result) => {
                if (result.success && result.isAnchor) {
                    callback && callback();
                    promiseStatusOption.resolve && promiseStatusOption.resolve();
                } else {
                    promiseStatusOption.reject && promiseStatusOption.reject();
                    tipForNoExitAnchor();
                }
            })
            .catch(() => {
                promiseStatusOption.reject && promiseStatusOption.reject();
                tipForNoExitAnchor();
            });
    }
}

function tipForNoExitAnchor() {
    window._store.state.manageMode ? Message.warning('跳转链接失效，请重新设置。') : Fai.ing('跳转链接失效', true);
}

// 是否有topBar，是否有固定顶部
var fixScrollTop = function () {
    var $toolBar = $('#jzToolbar'),
        $sysHeader = $('#row3'),
        $gridHeader = $('#gridHeader'),
        $sitetips = $('#sitetips'),
        isOpenFixedTop = window._store.state.header.isOpenFixedTop,
        isOpenFixedHeight = window._store.state.header.isOpenFixedHeight,
        $mobiHeader = $('.jz_mobi_header.g_mobi_header__fixed'),
        toolBarHeight,
        fixSrcollTopValue = 0;

    toolBarHeight = $toolBar.length ? $toolBar.height() : 0;
    if (!!window._store.state.manageMode && $('.jz_root_mobi').length) {
        // 手机下管理态可以无视topbar
        toolBarHeight = 0;
    }

    if ($gridHeader.length) {
        fixSrcollTopValue = isOpenFixedTop ? $('.g_header_content').eq(0).outerHeight() : 0; // 没开启固定导航 不能赋值为空字符串‘’ 否则下面得会变成拼接 导致计算错误
        if (isOpenFixedTop && isOpenFixedHeight) {
            // 固定导航栏所在的通栏
            fixSrcollTopValue = $sysHeader.height();
        }
    }
    if ($sitetips.length) {
        fixSrcollTopValue += $sitetips.height();
    }
    fixSrcollTopValue += toolBarHeight;

    if ($mobiHeader.length) {
        fixSrcollTopValue += $mobiHeader.height();
    }
    return fixSrcollTopValue;
};

/*(($) => {
    if (!$('#jz_banner').length || window._store.state.manageMode) return;

    // 初始化横幅
    $(function () {
        banner.init();
    });
})(window.jQuery, window.JZ || (window.JZ = {}));*/

/**
 * 针对mobi与site对称链接功能的转换
 * 对于文件下载（因为自适应和site共用一个key，所以site的改动也要同步到自适应）
 * @param {*} funcName 函数名称
 * @param {*} funcArgs 函数对应的参数
 */
const transformForLinkFunc = function (funcName, funcArgs) {
    const fileDownload = 'fileDownload';
    let { jumpIde = '' } = funcArgs;
    try {
        switch (funcName) {
            case fileDownload: // 文件下载
                !!jumpIde && Site[fileDownload] && Site[fileDownload](jumpIde); // from site
                break;

            default:
                break;
        }
    } catch (error) {
        console.log(error);
    }
};

const getRowColParentRow = (id) => {
    let parentRowId = parseInt(id);
    if (window._store.state.rowCol.includes(parentRowId)) {
        const parentRowLine = $(`#row${id}`).parent(),
            parentWebRow = parentRowLine && parentRowLine.parents('.jz_web_row'),
            curId = /\d+/g.exec(parentWebRow.attr('id')) ? /\d+/g.exec(parentWebRow.attr('id'))[0] : 0;
        parentRowId = parentWebRow ? curId || parentRowId : parentRowId;
    }
    return parseInt(parentRowId);
};

function getLogFileSizeStr(size) {
    let _1M = 1024 * 1024,
        _50M = _1M * 50,
        _200M = _1M * 200;
    if (size <= _50M) {
        return '小于等于50m';
    } else if (size <= _200M) {
        return '大于50m小于等于200m';
    } else {
        return '大于200m';
    }
}

const memberFileUpload = ({ moduleId, fileSizeLimit, fileUpInfo, propId, previewLinkInfo }) => {
    initMemberFileDetail(previewLinkInfo, propId, moduleId);
    let $fileUploadBtn = $(`#module${moduleId} #member_filewrap-${propId} .member_addfile_btn`);
    let fileTypeList = '*.*';
    if (fileUpInfo.fupt) {
        let selectList = fileUpInfo.sfl || [];
        let customFileTypes = fileUpInfo.cft || [];
        let allFileTypes = defaultFileTypes.map((item) => item.list);
        allFileTypes.push(customFileTypes);
        allFileTypes = allFileTypes.reduce((cur, next) => {
            return cur.concat([...next]);
        }, []);
        let finalTypeList = allFileTypes.filter((item) => selectList.some((i) => i == item.type));
        //自定义类型
        fileTypeList = finalTypeList
            .map((item, index) => {
                if (index == finalTypeList.length - 1) {
                    return `*.${item.name}`;
                }
                return `*.${item.name};`;
            })
            .join('');
        fileTypeList = fileTypeList.toLocaleLowerCase();
    }

    var advance_setting = {
        siteFree: false, //是否为免费版
        updateUrlViewRes: '', //升级目标版本URL
        auto: true,
        fileTypeExts: fileTypeList,
        multi: false,
        fileSizeLimit: fileSizeLimit * 1024 * 1024,
        fileSizeLimitTips: LS.siteFormSubmitFileUploadTips,
        fileSplitSize: 20 * 1024 * 1024,
        breakPoints: true,
        saveInfoLocal: false,
        showUploadedPercent: false, //是否实时显示上传的百分比，如20%
        showUploadedSize: false,
        removeTimeout: 9999999,
        post_params: {
            app: 21,
            type: 0,
            fileUploadLimit: fileSizeLimit,
            isSiteForm: true,
            pieceUpload: true, // 开启断点续传
        },
        isBurst: true, //表单先不分片,因为断点续传后台还不支持。到时可以了再去掉    -- 开启分片
        isDefinedButton: true,
        buttonText: '',
        uploader: '/ajax/advanceUpload.jsp?cmd=wafNotCk_memberUpload', //调用genUploadUrl 可改变url
        onUploadSuccess: (file, text) => {
            var data = jQuery.parseJSON(text);
            if (data.success) {
                successMessage(Fai.format(LS.siteFormSubmitFileUploadSucess, file.name), true);
                onFileUploadEvent(
                    {
                        fileMd5: file.md5,
                        ...data,
                    },
                    moduleId,
                    propId
                );
                logJZFdp('jz_c_vipfiles_submit', {
                    jz_free_text_0: getLogFileSizeStr(file.size),
                    jz_content_terminal: '自适应',
                    jz_site_id: window._store.state.siteId,
                });
            } else {
                warningMessage(LS.files + ':' + file.name + '，' + data.msg, true);
            }
        },
        onUploadError(file) {
            $('#progressBody_ ' + file.id).remove();
            $('#progressWrap_' + file.id).remove();
            warningMessage(Fai.format(LS.mobiFormSubmitFileUploadSvrBusy, file.name), true);
        },
        onSelect() {
            if (window._store.state.manageMode) {
                Message.warning('当前为管理状态，文件上传无效。');
                return false;
            } else if (window._store.state.isDemo) {
                warningMessage('当前为“模板网站”，请先“复制网站”。', true);
                return false;
            }
            return true;
        },
        onUploadStart(file) {
            $('#progressBody_ ' + file.id).remove();
            $('#progressWrap_' + file.id).remove();
        },
        msgInfo(type, msg) {
            if (type === 'warning') {
                warningMessage(msg, true);
            }
        },
    };

    $fileUploadBtn.uploadify(advance_setting);
    $fileUploadBtn.on('click', function () {
        $fileUploadBtn.find('a')[0].click();
    });
};
function initMemberFileDetail(previewLinkInfo, propId, moduleId) {
    //转换文件大小单位
    if (window._store.state.colId == 24) {
        let moduleDom = $(`#module${moduleId} #member_filewrap-${propId}`);
        let sizeDom = moduleDom.find(`.member_file_size`);
        sizeDom.text(Fai.parseFileSize(parseInt(sizeDom.text())));
        if (previewLinkInfo.fileId && previewLinkInfo.filePath) {
            initPreviewImgHref(
                previewLinkInfo.filePath,
                previewLinkInfo.fileId,
                moduleDom.find('.member_file_preview_link'),
                previewLinkInfo.fileName,
                moduleId
            );
        }
    }
}

// 会员中心的c端上传
const MemberFileUtil = {
    fileObj: {}, //用户上传文件时，接口返回的数据
    saveMemberFile() {
        const data = {};
        let fileList = [];
        Object.keys(this.fileObj).forEach((key) => {
            fileList.push(this.fileObj[key]);
        });
        data.fileList = JSON.stringify(fileList);
        //保存用户上传的临时文件。
        saveMemberMsgFile(data);
    },
};

function onFileUploadEvent(args, moduleId, propId) {
    let file_name = args.name, //返回文件名
        file_size = Fai.parseFileSize(args.size); //返回文件大小
    let moduleDom = $(`#module${moduleId}  #member_filewrap-${propId}`),
        fileUpdom = moduleDom.find('.member_filedetail_wrap'),
        nameDom = moduleDom.find('.member_file_name'),
        sizeDom = moduleDom.find('.member_file_size'),
        uploadDom = moduleDom.find('.member_addfile_btn'),
        previewDom = moduleDom.find('.member_file_preview_link'),
        downloadDom = moduleDom.find('.member_file_download_link');
    fileUpdom.addClass('member_filedetail_wrap-active');
    nameDom && nameDom.text(file_name);
    sizeDom && sizeDom.text(file_size);
    uploadDom && uploadDom.find('span').text('修改文件');
    MemberFileUtil.fileObj[propId] = args;
    moduleDom.find(`input[name=${propId}]`).attr('value', args.fileId);
    // 后台反馈pm上传没保存的时候，是不可以下载和预览的，先关闭。
    // downloadDom.attr("href",args.du);
    // initPreviewImgHref(args.path,args.fileId,previewDom,file_name,moduleId);
    downloadDom.hide();
    previewDom.hide();
}
function initPreviewImgHref(path, fileId, previewDom, fileName, moduleId) {
    if (/(jpg|jpeg|png|gif)$|(jpg|jpeg|png|gif)\?v=|(jpg|jpeg|png|gif)\?_tm=/.test(fileName)) {
        initPreviewImgEvent(path, previewDom, moduleId);
    } else if (/\.(pdf|PDF)$/.test(fileName)) {
        initPdfPreview(previewDom, path);
    } else {
        initNormalPreviewEvent(previewDom, fileId);
    }
}
function initPreviewImgEvent(path, previewDom, moduleId) {
    previewDom.attr('href', 'javascript:void(0);');
    previewDom.off('click.previewImg').on('click.previewImg', function (e) {
        e.preventDefault();
        e.stopPropagation();
        slideshowView({
            id: `${moduleId}`,
            previewSrcList: [
                {
                    src: path,
                    title: '',
                },
            ],
            initialIndex: 0,
            zIndex: 9035,
            logEventConfig: {
                event: 'jz_hdpzj',
                properties: {
                    jz_content_terminal: '自适应-会员中心-我的资料',
                    jz_version: window._store.state.jzVersion,
                },
            },
        });
    });
}
function initPdfPreview(path, previewDom) {
    previewDom.off('click.previewImg');
    previewDom.attr('href', path);
}
function initNormalPreviewEvent(previewDom, fileId) {
    previewDom.off('click.previewImg');
    previewDom.attr('href', `${window._store.state.viewUrl}?fileID=${fileId}`);
}

export * from '@/site/shared/cookie/index.js';
export * from '@/site/shared/sourceLoad/index.js';
export * from '@/site/shared/popupWin/index.js';
export * from '@/components/modules/shared/ue/index.js';
export * from '@shared/visitor/form/index.js';
export * from '@shared/visitor/rich/index.js';
export * from '@/components/modules/common/shareComponent/index.js';
export {
    MemberFileUtil,
    getPreviewTop,
    jumpToPage,
    checkElement,
    createDialog,
    warningMessage,
    successMessage,
    getPageScrollTarget,
    jumpToAnchor,
    observeAnimate,
    transformForLinkFunc,
    getRowColParentRow,
    memberFileUpload,
};
